/* Copyright Levelise Ltd 2021-2023 */
import { subDays, addDays, getUnixTime, startOfDay, endOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';


const EFA = {
    '1': { 'type': '', 'hasContract': 0, 'start': false, 'end': false, 'isSet': false },
    '2': { 'type': '', 'hasContract': 0, 'start': false, 'end': false, 'isSet': false },
    '3': { 'type': '', 'hasContract': 0, 'start': false, 'end': false, 'isSet': false },
    '4': { 'type': '', 'hasContract': 0, 'start': false, 'end': false, 'isSet': false },
    '5': { 'type': '', 'hasContract': 0, 'start': false, 'end': false, 'isSet': false },
    '6': { 'type': '', 'hasContract': 0, 'start': false, 'end': false, 'isSet': false }
}

const extractContracts = (contracts, afContract, timezone, isSet) => {
    const { name, startMinuteInDay, endMinuteInDay, startTimestampSec, endTimestampSec } = afContract;
    let date = toZonedTime(getUnixTime(new Date()) * 1000, timezone);
    for (let index = 0; index < contracts.length; index++) {
        const startDate = startOfDay(date);
        const endDate = endOfDay(date);
        const startTimestampOfDay = getUnixTime(startDate);
        const endTimestampOfDay = getUnixTime(endDate);
        if (startTimestampSec <= endTimestampOfDay && startTimestampOfDay < endTimestampSec) {
            handleSetContract(contracts, name, index, startMinuteInDay, endMinuteInDay, startDate, startTimestampSec, endTimestampSec, isSet);
        }
        date = addDays(date, 1);
    }
}

const handleSetContract = (contracts, name, index, startMinuteInDay, endMinuteInDay, startDate, startTimestampSec, endTimestampSec, isSet) => {
    const timestamp = getUnixTime(startDate);
    const prevTimestamp = getUnixTime(subDays(startDate, 1));
    for (let minute = 0; minute <= 1440; minute++) {
        let currentTimestamp = timestamp + (minute * 60);
        if (minute >= 1380) {
            currentTimestamp = prevTimestamp + (minute * 60);
        }

        if (startTimestampSec <= currentTimestamp && currentTimestamp < endTimestampSec) {
            if (23 * 60 <= minute) {
                if (startTimestampSec >= timestamp - 3600) {
                    const diffSecs = startTimestampSec - (timestamp - 3600);
                    if (diffSecs > 60) break;
                }

                setContract(
                    contracts, name, minute, 23, index, startMinuteInDay, endMinuteInDay,
                    '1', timestamp, prevTimestamp, startTimestampSec, isSet
                );
            } else if (minute < 3 * 60) {
                setContract(
                    contracts, name, minute, 23, index, startMinuteInDay, endMinuteInDay,
                    '1', timestamp, prevTimestamp, startTimestampSec, isSet
                );
            } else if (3 * 60 <= minute && minute < 7 * 60) {
                setContract(
                    contracts, name, minute, 3, index, startMinuteInDay, endMinuteInDay,
                    '2', timestamp, prevTimestamp, startTimestampSec, isSet
                );
            } else if (7 * 60 <= minute && minute < 11 * 60) {
                setContract(
                    contracts, name, minute, 7, index, startMinuteInDay, endMinuteInDay,
                    '3', timestamp, prevTimestamp, startTimestampSec, isSet
                );
            } else if (11 * 60 <= minute && minute < 15 * 60) {
                setContract(
                    contracts, name, minute, 11, index, startMinuteInDay, endMinuteInDay,
                    '4', timestamp, prevTimestamp, startTimestampSec, isSet
                );
            } else if (15 * 60 <= minute && minute < 19 * 60) {
                setContract(
                    contracts, name, minute, 15, index, startMinuteInDay, endMinuteInDay,
                    '5', timestamp, prevTimestamp, startTimestampSec, isSet
                );
            } else if (19 * 60 <= minute && minute < 23 * 60) {
                setContract(
                    contracts, name, minute, 19, index, startMinuteInDay, endMinuteInDay,
                    '6', timestamp, prevTimestamp, startTimestampSec, isSet
                );
            }
        }
    }
}

const setContract = (contracts, name, minute, start, index, startMinuteInDay, endMinuteInDay, type, timestamp, prevTimestamp, startTimestampSec, isSet) => {
    if (contracts[index]) {
        if (startMinuteInDay <= minute && minute < endMinuteInDay) {
            let increment = 1;
            let count = contracts[index][type]['hasContract'];
            if ((count !== 0 && count < 0) || (count === 0 && startMinuteInDay > start * 60)) {
                increment = -1;
            }

            if (count < 240) {
                contracts[index][type]['type'] = name;
                contracts[index][type]['hasContract'] = (count || 0) + increment;
                contracts[index][type]['isSet'] = isSet;
            }
        }

        if (startTimestampSec >= timestamp - 3600) {
            let currentTimestamp = timestamp + (minute * 60);
            if (minute >= 1380) {
                currentTimestamp = prevTimestamp + (minute * 60);
            }

            if (currentTimestamp === startTimestampSec) {
                contracts[index][type]['start'] = true;
            }
        }
    }
}


export const getContacts = (afContracts, subContracts, timezone, isSet) => {
    const contracts = Array.from({ length: 5 }, () => JSON.parse(JSON.stringify(EFA)))
    for (let i = 0; i < afContracts.length; i++) {
        extractContracts(contracts, afContracts[i], timezone, isSet);
    }
    for (let i = 0; i < subContracts.length; i++) {
        extractContracts(contracts, subContracts[i], timezone, false);
    }
    return contracts;
}