/* Copyright Levelise Ltd 2025 */
function CustomerIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_559_5521)">
        <g clipPath="url(#clip1_559_5521)">
          <path
            d="M5.018 3.71C6.038.767 9.107-.736 11.854.357a5.34 5.34 0 012.514 2.088 6.04 6.04 0 01.142 6.266.285.285 0 01-.408.1.33.33 0 01-.094-.436 5.37 5.37 0 00-.127-5.57A4.746 4.746 0 0011.646.95c-2.443-.973-5.167.365-6.075 2.98a5.343 5.343 0 00.175 3.967c.533 1.18 1.46 2.082 2.606 2.54 1.561.62 3.273.331 4.582-.778a.283.283 0 01.415.052.333.333 0 01-.048.445c-1.47 1.245-3.397 1.572-5.155.874-1.291-.513-2.333-1.53-2.933-2.857a6.01 6.01 0 01-.198-4.465"
            fill="#64B664"
          />
          <path
            d="M14.834 13.058h-9.67C2.319 13.058 0 15.54 0 18.59V20h.545v-1.41c0-2.727 2.073-4.947 4.62-4.947h9.668c2.55 0 4.622 2.22 4.622 4.947V20h.547v-1.41c0-3.05-2.318-5.532-5.167-5.532h-.002z"
            fill="#000"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_559_5521">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
        <clipPath id="clip1_559_5521">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CustomerIcon
