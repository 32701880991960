/* Copyright Levelise Ltd 2021-2025 */
import { useState, useEffect, useRef, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import FacilityContext from '../../../contexts/FacilityContext';
import {
    CONTRACT_ANNOTATION,
    temperatureLimits,
    getValue,
    minBatteryVoltage,
    maxBatteryVoltage,
    soc,
    minBatteryTemperature,
    maxBatteryTemperature,
    getTimeFormat,
    dataset,
    options,
    xAxis,
    facilityChartTitles,
    tooltipTitleCallbacks,
} from '../../../utils/chart';
import {
    BATTERY,
    TIME_FRAMES,
    timestampSec,
    timestampDay,
    resolutions
} from '../../../utils/constants';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {},
    label: 'Voltage (V)',
    gridLines: { drawOnChartArea: true }
}
const y2 = {
    stacked: false,
    ticks: {},
    label: 'SoC (%)',
    gridLines: { drawOnChartArea: false }
}
const y1 = {
    stacked: false,
    ticks: { stepSize: 5 },
    label: 'Temperature (°C)',
    gridLines: { drawOnChartArea: false }
}

const legendLabels = {
    font: { size: 10 },
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    filter: function (item, chart) {
        const idx = item.datasetIndex;
        const dataset = chart.datasets[idx]
        return !dataset.hidden
    },
}

const BatteryChart = ({ height, fmData, handleFmData, data, timezone, selectedTimezone }) => {
    const facilityBatteryRef = useRef();
    const context = useContext(FacilityContext);
    const dataSetLabels = {
        stateOfCharge: soc.type,
        minTemperature: minBatteryTemperature.type,
        maxTemperature: maxBatteryTemperature.type,
        minVoltage: minBatteryVoltage.type,
        maxVoltage: maxBatteryVoltage.type
    }
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', maxBatteryVoltage.type, maxBatteryVoltage.backgroundColor, maxBatteryVoltage.borderColor, 'y'),
                dataset('line', minBatteryVoltage.type, minBatteryVoltage.backgroundColor, minBatteryVoltage.borderColor, 'y'),
                dataset('line', maxBatteryTemperature.type, maxBatteryTemperature.backgroundColor, maxBatteryTemperature.borderColor, 'y1'),
                dataset('line', minBatteryTemperature.type, minBatteryTemperature.backgroundColor, minBatteryTemperature.borderColor, 'y1'),
                dataset('line', soc.type, soc.backgroundColor, soc.borderColor, 'y2'),
            ]
        },
        options: options('', legendLabels, x, x1, y, y1, y2)
    });

    const resetState = () => {
            setChart({
                data: {
                    labels: [],
                    datasets: [
                        dataset('line', maxBatteryVoltage.type, maxBatteryVoltage.backgroundColor, maxBatteryVoltage.borderColor, 'y'),
                        dataset('line', minBatteryVoltage.type, minBatteryVoltage.backgroundColor, minBatteryVoltage.borderColor, 'y'),
                        dataset('line', maxBatteryTemperature.type, maxBatteryTemperature.backgroundColor, maxBatteryTemperature.borderColor, 'y1'),
                        dataset('line', minBatteryTemperature.type, minBatteryTemperature.backgroundColor, minBatteryTemperature.borderColor, 'y1'),
                        dataset('line', soc.type, soc.backgroundColor, soc.borderColor, 'y2'),
                    ]
                },
                options: options('', legendLabels, x, x1, y, y1, y2)
            })
        }

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.second;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.dailyReports, resolution);
                break;
            case resolutions.half_hour:
                populateChartByHalfHour(data.halfHourlyReports, resolution);
                break;
            case resolutions.minute:
                populateChartByMinute(data.minutelyReports, resolution, true, data.updated);
                break;
            case resolutions.second:
                populateChartByMinute(data.minutelyReports, resolutions.minute, false, data.updated);
                break;
            default:
                break;
        }
    }

    const populateChartByDay = (reports, resolution) => {
        if (!reports.length){
            resetState();
            return;
        }

        const { minVoltage, maxVoltage, minTemperature, maxTemperature, stateOfCharge } = dataSetLabels;
        const labels = [];
        const datasets = chart.data.datasets.map(d => { d.data = []; return d });
        const start = reports[0][timestampDay];
        const end = reports[reports.length - 1][timestampDay];
        const increment = resolution === resolutions.week ? 7 : 1
        let i = 0;
        for (let timestamp = start; timestamp <= end; timestamp += increment) {
            if (timestamp === reports[i][timestampDay]) {
                datasets[0].data.push(getValue(reports[i][BATTERY[maxVoltage]])); // max voltage
                datasets[1].data.push(getValue(reports[i][BATTERY[minVoltage]])); // min voltage
                datasets[2].data.push(getValue(reports[i][BATTERY[maxTemperature]])); // max temp
                datasets[3].data.push(getValue(reports[i][BATTERY[minTemperature]])); // min temp
                datasets[4].data.push(getValue(reports[i][BATTERY[stateOfCharge]])); // soc

                i++;
            } else {
                datasets[0].data.push(null);
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                datasets[3].data.push(null);
                datasets[4].data.push(null);
            }

            labels.push(timestamp * 86400 * 1000);
        }

        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, timeFormat, []);
    }

    const populateChartByHalfHour = (reports, resolution) => {
        if (!reports.length){
            resetState();
            return;
        }

        const { minVoltage, maxVoltage, minTemperature, maxTemperature, stateOfCharge } = dataSetLabels;
        const labels = [];
        const datasets = chart.data.datasets.map(d => { d.data = []; return d });
        const start = reports[0][timestampSec];
        const end = reports[reports.length - 1][timestampSec];
        let i = 0;
        for (let timestamp = start; timestamp <= end; timestamp += 1800) {
            if (timestamp === reports[i][timestampSec]) {
                datasets[0].data.push(getValue(reports[i][BATTERY[maxVoltage]])); // max voltage
                datasets[1].data.push(getValue(reports[i][BATTERY[minVoltage]])); // min voltage
                datasets[2].data.push(getValue(reports[i][BATTERY[maxTemperature]])); // max temp
                datasets[3].data.push(getValue(reports[i][BATTERY[minTemperature]])); // min temp
                datasets[4].data.push(getValue(reports[i][BATTERY[stateOfCharge]])); // soc

                i++;
            } else {
                datasets[0].data.push(null);
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                datasets[3].data.push(null);
                datasets[4].data.push(null);
            }

            labels.push(timestamp * 1000);
        }

        const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : [];
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, timeFormat, annotation);
    }

    const populateChartByMinute = (reports, resolution, isMinute, update = false) => {
        if (!reports.length){
            if (!update) {
                resetState();
            }
            return;
        }

        const { minVoltage, maxVoltage, minTemperature, maxTemperature, stateOfCharge } = dataSetLabels;
        const labels = update ? [...chart.data.labels] : [];
        const datasets = update ? [...chart.data.datasets] : chart.data.datasets.map(d => { d.data = []; return d });
        const start = reports[0][timestampSec];
        const end = reports[reports.length - 1][timestampSec];
        let i = 0;
        for (let timestamp = start; timestamp <= end; timestamp += 60) {
            if (update) {
                labels.shift();
                datasets[0].data.shift();
                datasets[1].data.shift();
                datasets[2].data.shift();
                datasets[3].data.shift();
                datasets[4].data.shift();
            };

            if (timestamp === parseInt(reports[i][timestampSec])) {
                datasets[0].data.push(getValue(reports[i][BATTERY[maxVoltage]])); // max voltage
                datasets[1].data.push(getValue(reports[i][BATTERY[minVoltage]])); // min voltage
                datasets[2].data.push(getValue(reports[i][BATTERY[maxTemperature]])); // max temp
                datasets[3].data.push(getValue(reports[i][BATTERY[minTemperature]])); // min temp
                datasets[4].data.push(getValue(reports[i][BATTERY[stateOfCharge]])); // soc

                i++;
            } else {
                datasets[0].data.push(null);
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                datasets[3].data.push(null);
                datasets[4].data.push(null);
            }

            labels.push(timestamp * 1000);
        }

        const annotation = CONTRACT_ANNOTATION(end, timezone);
        const _resolution = isMinute ? resolution : resolutions.second;
        const timeFormat = getTimeFormat(_resolution, timezone);
        handleSetChart(labels, datasets, timeFormat, annotation);
    }

    const getTimeFrame = () => {
        let timeFrame = data.timeFrame;
        if (timeFrame === TIME_FRAMES.select) {
            const { dailyReports, halfHourlyReports } = data;
            const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } = TIME_FRAMES;

            switch (data.resolution) {
                case resolutions.day: {
                    let dailyReportLen = dailyReports.length;
                    timeFrame = dailyReportLen <= 60 ? two_months : dailyReportLen <= 366 ? twelve_months : thirty_six_months;
                    break;
                }
                case resolutions.half_hour: {
                    let halfHourlyReportLen = halfHourlyReports.length;
                    timeFrame = halfHourlyReportLen <= 50 ? twenty_four_hours : halfHourlyReportLen <= 336
                        ? one_week : fifteen_days;
                    break;
                }
                case resolutions.minute:
                    timeFrame = twenty_four_hours;
                    break;
                default:
                    break;
            }
        }

        if (fmData.hasFmData) {
            return TIME_FRAMES.fifteen_minutes;
        }

        return timeFrame;
    }

    const handleSetChart = (labels, datasets, time, annotation) => {
        let minMaxTempAnnotation = {};
        let suggestedMinMax = {}
        if (context?.facility?.batterySystem) {
            const battery = context.facility.batterySystem;
            minMaxTempAnnotation = temperatureLimits(battery, "y1")
            suggestedMinMax = { suggestedMin: battery.minTemperatureC - 5, suggestedMax: battery.maxTemperatureC + 5 }
        }

        const timeFrame = getTimeFrame();
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { top: -8 } },
                plugins: {
                    ...chart.options.plugins,
                    annotation: { annotations: { ...annotation, ...minMaxTempAnnotation } },
                    tooltip: { ...chart.options.plugins.tooltip, callbacks: tooltipTitleCallbacks(selectedTimezone) }
                },
                scales: {
                    ...chart.options.scales,
                    y1: { ...chart.options.scales.y1, ...suggestedMinMax },
                    y2: { ...chart.options.scales.y2, min: 0, max: 100 },
                    x: xAxis(timeFrame, time, selectedTimezone)
                },
                onClick: (event, items, chart) => {
                    if (items?.length) handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
                }
            }
        };


        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.byMinuteReports.length) {
            populateChartByMinute(fmData.byMinuteReports, resolutions.minute, false)
        }
    }, [fmData.byMinuteReports, selectedTimezone])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.minutelyReports, data.halfHourlyReports, data.dailyReports])

    useEffect(() => {
        if (data.updated) {
            handleDataOnChange(data);
        }
    }, [data.minutelyReports])

    useEffect(() => {
        if (!fmData.hasFmData && !data.updated) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, fmData.hasFmData, data.minutelyReports, selectedTimezone])

    return (
        <div className="battery-chart" style={{ height }}>
            <Line
                id={facilityChartTitles.battery[1]}
                ref={facilityBatteryRef}
                data={chart.data}
                options={chart.options}
            />
        </div>
    )
}

export default BatteryChart;