import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCircleInfo, faClose } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import { colors } from '../../utils/chart';

function AlertContainer({ alerts, removeAlert }) {
	return (
		<div className="alert-container">
			{alerts.map(({ id, message, type, fading }) => (
				<div key={id} className={`alert ${fading ? 'fade-out' : ''}`}>
					<div>
						{type === 'success' ? (
							<FontAwesomeIcon icon={faCircleCheck} size="lg" color={colors.successGreen} />
						) : type === 'error' ? (
							<FontAwesomeIcon icon={faCircleExclamation} size="lg" color={colors.errorRed} />
						) : (
							<FontAwesomeIcon icon={faCircleInfo} size="lg"  />
						)}
					</div>
					<span>{message}</span>
					<button onClick={() => {
                        removeAlert(id)
                    }}>
						<FontAwesomeIcon icon={faClose} size="lg" color={colors.white} />
					</button>
				</div>
			))}
		</div>
	);
}

export default AlertContainer;
