/* Copyright Levelise Ltd 2024 - 2025 */
import React from 'react';
import './index.css';
import FilterIcon from '../../assets/icons/FilterIcon.svg';

function TabContainer({
	title,
	children,
	containerStyle,
	withSearchInput,
	searchTerm,
	handleOnChange,
	rightCornerComponent,
	titleStyle,
	filterIcon,
	className,
	...props
}) {
	return (
		<div className={`add-customer-form-with-header ${className || ''}`} style={containerStyle} {...props}>
			<div className="add-customer-form-header">
				<div style={titleStyle} className="top-bar-title-icon-container">
					{
						filterIcon ? (<img src={FilterIcon} alt='filter' className='filter-icon-style'/>): null
					}
					
					<span className="specification-form-title">{title}</span>
				</div>
				{withSearchInput ? (
					<input
						id="dru-search-field"
						name="term"
						type="text"
						value={searchTerm}
						onChange={(e) => handleOnChange(e)}
						autoComplete="off"
						placeholder="Search..."
					/>
				) : null}
				{rightCornerComponent ? (rightCornerComponent) : null}
			</div>
			{children}
		</div>
	);
}

export default TabContainer;
