/* Copyright Levelise Ltd 2019-2025 */
import React, { Component } from 'react';
import config from '../config';

const FacilityConext = React.createContext({
    facility: {},
    facilityName: '',
    selectedTimezone: '',
    status: {},
    preferences: {},
    selectedTimeFrame: '',
    startDate: null,
    endDate: null,
    records: [],
    forecasts: null,
    resetZoom: false,
    timeFrame: '',
    dates: {},
    timeFrameChanging: false,
    error: null,
    setFacility: () => { },
    setFacilityName: () => {},
    setSelectedTimezone: () => { },
    setStatus: () => { },
    setPreferences: () => { },
    setSelectedTimeFrame: () => { },
    setStartDate: () => { },
    setEndDate: () => { },
    setRecords: () => { },
    setForecasts: () => { },
    setResetZoom: () => { },
    updateRecords: () => { },
    setTimeFrameChanging: () => {},
    setError: () => { },
    clearError: () => { },
    clearState: () => { },
    resetState: () => { }
})

export default FacilityConext;

export class FacilityProvider extends Component {
    state = {
        facility: {},
        facilityName: '',
        selectedTimezone: '',
        status: {},
        preferences: { [config.energyData]: true, [config.logsPanel]: true, [config.facilityInfo]: true },
        selectedTimeFrame: '',
        startDate: null,
        endDate: null,
        records: [],
        forecasts: null,
        resetZoom: false,
        timeFrame: '',
        dates: { start: 'DD/MM/YY', end: 'DD/MM/YY' },
        timeFrameChanging: false,
        error: null
    }

    setFacility = facility => this.setState({ facility });
    setFacilityName = facilityName => this.setState({facilityName});
    setSelectedTimezone = selectedTimezone => this.setState({ selectedTimezone });
    setStatus = status => this.setState({ status });
    setSelectedTimeFrame = selectedTimeFrame => this.setState({ selectedTimeFrame });
    setStartDate = startDate => this.setState({ startDate });
    setEndDate = endDate => this.setState({ endDate });
    setRecords = (records, timeFrame, dates = { start: 'DD/MM/YY', end: 'DD/MM/YY' }) => this.setState({ records, timeFrame, dates });
    setForecasts = (forecasts) => this.setState({ forecasts });
    setPreferences = preferences => this.setState({ preferences });
    setResetZoom = () => this.setState({ resetZoom: !this.state.resetZoom });

    updateRecords = (records, timeFrame) => {
        if (timeFrame === this.state.timeFrame) {
            const tempRecords = [...this.state.records]
            for (let i = 0; i < records.length; i++) {
                tempRecords.push(records[i]);
                tempRecords.shift()
            }

            this.setState({ records: tempRecords })
        }
    }

    setTimeFrameChanging = (value) => {
        this.setState({ timeFrameChanging: value})
    }

    clearState = () => {
        this.setState({
            facility: {},
            facilityName: '',
            selectedTimezone: '',
            status: {},
            preferences: { [config.energyData]: true, [config.logsPanel]: true, [config.facilityInfo]: true },
            selectedTimeFrame: '',
            startDate: null,
            endDate: null,
            records: [],
            forecasts: null,
            resetZoom: false,
            timeFrame: '',
            dates: { start: 'DD/MM/YY', end: 'DD/MM/YY' },
            timeFrameChanging: false,
            error: null
        })
    }

    resetState = () => {
        this.setState({
            facility: {},
            facilityName: '',
            status: {},
            preferences: { [config.energyData]: true, [config.logsPanel]: true, [config.facilityInfo]: true },
            selectedTimeFrame: '',
            startDate: null,
            endDate: null,
            records: [],
            forecasts: null,
            resetZoom: false,
            timeFrame: '',
            dates: { start: 'DD/MM/YY', end: 'DD/MM/YY' },
            timeFrameChanging: false,
            error: null
        })
    }

    setError = error => {
        if (!this.state.error) this.setState({ error });
    }

    clearError = () => this.setState({ error: null });

    render() {
        const contextValue = {
            facility: this.state.facility,
            facilityName: this.state.facilityName,
            selectedTimezone: this.state.selectedTimezone,
            status: this.state.status,
            preferences: this.state.preferences,
            selectedTimeFrame: this.state.selectedTimeFrame,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            timeFrame: this.state.timeFrame,
            resetZoom: this.state.resetZoom,
            records: this.state.records,
            forecasts: this.state.forecasts,
            dates: this.state.dates,
            timeFrameChanging: this.state.timeFrameChanging,
            error: this.state.error,

            setFacility: this.setFacility,
            setFacilityName: this.setFacilityName,
            setSelectedTimezone: this.setSelectedTimezone,
            setStatus: this.setStatus,
            setPreferences: this.setPreferences,
            setSelectedTimeFrame: this.setSelectedTimeFrame,
            setStartDate: this.setStartDate,
            setEndDate: this.setEndDate,
            setRecords: this.setRecords,
            setForecasts: this.setForecasts,
            setResetZoom: this.setResetZoom,
            updateRecords: this.updateRecords,
            setTimeFrameChanging: this.setTimeFrameChanging,
            clearState: this.clearState,
            setError: this.setError,
            clearError: this.clearError,
            resetState: this.resetState
        }
        return (
            <FacilityConext.Provider value={contextValue}>
                {this.props.children}
            </FacilityConext.Provider>
        )
    }
}