/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import FleetService from '../../services/fleet-service';
import FleetContext from '../../contexts/FleetContext';
import FacilityContext from '../../contexts/FacilityContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle, faQuestionCircle, faTimesCircle,
    faCircleExclamation, faTriangleExclamation, faCircleInfo, faHeartPulse,
} from '@fortawesome/free-solid-svg-icons';
import EnergyBalance from '../EnergyBalance/index';
import { SEVERITY_COLOR } from '../../utils/constants';
import './index.css';

const LiveStatus = ({facilityName}) => {
    const bySecondInterval = useRef();
    const byMinuteInterval = useRef();

    const fleetContext = useContext(FleetContext);
    const facilityContext = useContext(FacilityContext);

    const [record, setRecord] = useState(null);
    const [recordByMinute, setRecordByMinute] = useState(null);
    const [facility, setFacility] = useState({});

    const stateDescription = {
        "Value": "State of value",
        "Load": "State of load",
        "PV": "State of PV meter",
        "HW": "State of hot water meter",
        "HWT": "State of hot water tank",
        "Inverter Comms": "State of inverter comms",
        "Inverter": "State of inverter",
        "Battery": "State of battery",
        "Force Charging": "Battery force charging",
        "Power Comms": "State of power meter comms",
        "Power": "State of power meter",
        "Frequency": "State of frequency meter"
    }

    const faultIcon = {
        "OK": { icon: faCheckCircle, backgroundIcon: SEVERITY_COLOR.OK.backgroundSeverity, backgroundFault: SEVERITY_COLOR.OK.backgroundFault },
        "Noted": { icon: faCircleInfo, backgroundIcon: SEVERITY_COLOR.Noted.backgroundSeverity, backgroundFault: SEVERITY_COLOR.Noted.backgroundFault },
        "Glitchy": { icon: faCircleExclamation, backgroundIcon: SEVERITY_COLOR.Glitchy.backgroundSeverity, backgroundFault: SEVERITY_COLOR.Glitchy.backgroundFault },
        "Faulty": { icon: faCircleExclamation, backgroundIcon: SEVERITY_COLOR.Faulty.backgroundSeverity, backgroundFault: SEVERITY_COLOR.Faulty.backgroundFault },
        "Diminished": { icon: faTriangleExclamation, backgroundIcon: SEVERITY_COLOR.Diminished.backgroundSeverity, backgroundFault: SEVERITY_COLOR.Diminished.backgroundFault },
        "Unoptimisable": { icon: faTriangleExclamation, backgroundIcon: SEVERITY_COLOR.Unoptimisable.backgroundSeverity, backgroundFault: SEVERITY_COLOR.Unoptimisable.backgroundFault },
        "Unsafe": { icon: faHeartPulse, backgroundIcon: SEVERITY_COLOR.Unsafe.backgroundSeverity, backgroundFault: SEVERITY_COLOR.Unsafe.backgroundFault },
        "Inactive": { icon: faTriangleExclamation, backgroundIcon: SEVERITY_COLOR.Inactive.backgroundSeverity, backgroundFault: SEVERITY_COLOR.Inactive.backgroundFault },
    }

    const [severestFaults, setSeverestFaults] = useState({});
    const [panels, setPanels] = useState({
        //"Value": null,
        "Load": null,
        "PV": null,
        "HW": null,
        "HWT": null,
        "Inverter Comms": null,
        "Inverter": null,
        "Battery": null,
         // "Force Charging": null,
        "Power Comms": null,
        "Power": null,
        "Frequency": null
    });

    const updateState = () => {
        if (record && Object.keys(record).length && Object.keys(facility).length) {
            const type = facility?.batterySystem ? facility.batterySystem.id : null;
            const loadOk = loadState(record.loadW);
            const pvOk = pvState(record.pvW, type);
            const valuesOk = (record.druState & 1) === 0;
            const hotWaterMeterOk = (record.druState & (1 << 2)) === 0;
            const hotWaterTankOk = (record.druState & (1 << 3)) === 0;
            const batteryOk = (record.druState & (1 << 4)) === 0;
            const inverterOk = (record.druState & (1 << 5)) === 0;
            const frequencyMeterOk = (record.druState & (1 << 6)) === 0;
            const powerMeterOk = (record.druState & (1 << 7)) === 0;

            const inverterCommsOk = (record.druState & (1 << 9)) === 0;
            const powerMeterCommsOk = (record.druState & (1 << 10)) === 0;

            const updatedPanels = {
                "Load": loadOk === null ? "unknown" : loadOk ? "good" : "reversed",
                "PV": pvOk === null ? "unknown" : pvOk ? "good" : "reversed",
                "HW": hotWaterMeterOk ? "good" : "bad",
                "HWT": hotWaterTankOk ? "good" : "bad",
                "Inverter Comms": inverterCommsOk ? "good" : "bad",
                "Inverter": inverterOk ? "good" : "bad",
                "Battery": batteryOk ? "good" : "bad",
                "Power Comms": powerMeterCommsOk ? "good" : "bad",
                "Power": powerMeterOk ? "good" : "bad",
                "Frequency": frequencyMeterOk ? "good" : "bad",
            };

            if ((record.druState & (1 << 1)) !== 0) {
                updatedPanels["PV"] = "bad"
            }

            if (!valuesOk) {
                updatedPanels["Value"] = "bad"
            }

            if ((record.druState & (1 << 8)) !== 0) {
                updatedPanels["Force Charging"] = "bad"
            }

            if (!powerMeterCommsOk) {
                updatedPanels.Power = "unknown";
            }

            if (!inverterCommsOk) {
                updatedPanels.Inverter = "unknown";
                updatedPanels.Battery = "unknown";
            }

            setPanels({ ...updatedPanels });
        } else {
            // setIsDisconnected(true)
            setPanels({
                "Value": "unknown",
                "Load": "unknown",
                "PV": "unknown",
                "HW": "unknown",
                "HWT": "unknown",
                "Inverter Comms": "unknown",
                "Inverter": "unknown",
                "Battery": "unknown",
                "Force Charging": "unknown",
                "Power Comms": "unknown",
                "Power": "unknown",
                "Frequency": "unknown",
            })
        }
    }

    const pvState = (pvW, type) => {
        if (type === null) {
            return null;
        } else if (type < 20 || (30 <= type && type < 100)) {
            return pvW < -35 ? false : pvW > 35 ? true : null;
        } else {
            return pvW < -150 ? false : pvW > 150 ? true : null;
        }
    }

    const loadState = (loadW) => {
        return loadW < -50 ? false : loadW > 50 ? true : null;
    }

    const icon = panel => {
        return panels[panel] === 'good'
            ? faCheckCircle : panels[panel] === 'unknown'
                ? faQuestionCircle : faTimesCircle;
    }

    const fetchLiveRecords = () => {
        const { facility } = facilityContext;
        if (facility?.druId) {
            setFacility(facility);
            FleetService.getFacilityRecords(facilityName, 'second', null, null, 1)
                .then(record => {
                    if (record && record.length > 0) {
                        setRecord(record[0]);
                    } else {
                        setRecord(null);
                    }
                })
                .catch((err) => {
                    setRecord(null)
                    facilityContext.setError(err);
        });
        }
    }

    const fetchByMinuteRecords = () => {
        const { facility } = facilityContext;
        if (facility?.druId) {
            FleetService.getFacilityRecords(facilityName, 'minute', null, null, 1)
                .then(record => {
                    if (record && record.length > 0) {
                        setRecordByMinute(record[0]);
                    } else {
                        setRecordByMinute(null);
                    }
                })
                .catch(facilityContext.setError);
        }
    }

    const handleSetFaults = (druId) => {
        if (!!Object.keys(fleetContext.status).length && !!Object.keys(fleetContext.faultCodes).length) {
            const faultCodeDrus = fleetContext.status.combined.faultCode;
            const faultCodes = fleetContext.faultCodes;
            const faults = {};
            Object.keys(faultCodeDrus).forEach(code => {
                if (!!faultCodes.hasOwnProperty(code) && faultCodeDrus[code].indexOf(Number(druId)) >= 0) {
                    const level = faultCodes[code].level;
                    const description = faultCodes[code].description;
                    faults[level] = faults.hasOwnProperty(level) ? [...faults[level], description] : [description];
                }
            })

            setSeverestFaults(faults)
        }
    }

    const severestFault = () => {
        if (!!Object.keys(fleetContext.status).length && !!Object.keys(fleetContext.faultCodes).length && !!facilityContext.facility.druId) {
            const druId = facilityContext.facility.druId;
            const faultCodeDrus = fleetContext.status.combined.faultCode;
            const faultCodes = fleetContext.faultCodes;

            let severity = -1, level = '', description = '';
            Object.keys(faultCodeDrus).forEach(code => {
                if (!!faultCodes.hasOwnProperty(code) && faultCodeDrus[code].indexOf(Number(druId)) >= 0) {
                    if (severity < faultCodes[code].severity) {
                        severity = faultCodes[code].severity;
                        level = faultCodes[code].level;
                        description = faultCodes[code].description;
                    }
                }
            })


            if (severity === -1) {
                if (fleetContext.status.combined.retired.includes(druId)) {
                    severity = 0;
                    level = 'Inactive';
                    description = 'Removed from the facility';
                } else if (fleetContext.status.combined.noFacility.includes(druId)) {
                    severity = 0;
                    level = 'Inactive';
                    description = 'Haven\'t had an associated facility';
                } else if (fleetContext.status.combined.unregistered.includes(druId)) {
                    severity = 0;
                    level = 'Inactive';
                    description = 'Not registered';
                }
            }

            if (severity >= 0)
                return (
                    <div id='faults'>
                        <div className='fault' style={{ border: `1px solid ${faultIcon[level].backgroundFault}` }}>
                            <div className='fault-icon-wrapper' style={{ background: faultIcon[level].backgroundIcon }}>
                                <FontAwesomeIcon icon={faultIcon[level].icon} color='white' size='lg' />
                            </div>
                            <ul style={{ background: faultIcon[level].backgroundFault }}>
                                <li className='severity-level'>{level === 'Noted' ? 'OK (Noted)' : level.toLocaleUpperCase()}</li>
                                <li >{description}</li>
                            </ul>
                        </div>
                    </div>
                );
            // else ...
            return (
                <div id='faults'>
                    <div className='fault' style={{ border: `1px solid ${faultIcon['OK'].backgroundFault}` }}>
                        <div className='fault-icon-wrapper' style={{ background: faultIcon['OK'].backgroundIcon }}>
                            <FontAwesomeIcon icon={faultIcon['OK'].icon} color='white' size='lg' />
                        </div>
                        <ul style={{ background: faultIcon['OK'].backgroundFault }}>
                            <li style={{ borderBottom: 'none', margin: 0 }} className='severity-level'>OK</li>
                            <li ></li>
                        </ul>
                    </div>
                </div>
            )

        }
    }

    useEffect(() => {
        if (facilityContext?.facility?.druId) {
            handleSetFaults(facilityContext.facility.druId);
        }

        clearInterval(bySecondInterval.current)
        if (!fleetContext.tokenUpdating) {
            fetchLiveRecords();
            bySecondInterval.current = setInterval(() => {
                fetchLiveRecords()
            }, 6000)
        }

        return () => {
            setRecord(null);
            clearInterval(bySecondInterval.current);
        };
    }, [facilityContext.facility, fleetContext.tokenUpdating, facilityName])

    useEffect(() => {
        clearInterval(byMinuteInterval.current);
        if (facilityContext.facility.hasOwnProperty('hotWaterTank') && !fleetContext.tokenUpdating) {
            fetchByMinuteRecords()
            byMinuteInterval.current = setInterval(() => {
                fetchByMinuteRecords()
            }, 60000)
        }
        
        return () => {
            setRecordByMinute(null);
            clearInterval(byMinuteInterval.current);
        };
    }, [facilityContext.facility, fleetContext.tokenUpdating, facilityName])

    useEffect(() => {
        updateState();
    }, [record, facility, facilityName])

    return (
        <div className="live-status">
            <h2>Live</h2>
            <div className="live-status-body">
                <ul className="status-list">
                    {Object.keys(panels)
                        .map((panel, idx) => {
                            if ((!facility.hotWaterTank && (panel === "HWT" || panel === "HW")))
                                return;
                            if (!facility.batterySystem && (panel === "Battery" || panel === "Inverter"))
                                return;
                            return (
                                <li key={idx} className={`${panels[panel]} status-tooltip`}>
                                    <FontAwesomeIcon icon={icon(panel)} />
                                    &nbsp;
                                    {panel}
                                    <span className="tooltiptext">{stateDescription[panel]}</span>
                                </li>
                            )
                        })
                    }
                </ul>
                <EnergyBalance record={record} recordByMinute={recordByMinute} />
                {severestFault()}
            </div>
        </div>
    )
}

export default LiveStatus