/* Copyright Levelise Ltd 2021-2025 */
import { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
    minuteConnectivity,
    secondConnectivity,
    secondConnectivityGap,
    secondHWConnectivity,
    secondBSConnectivity,
    secondServerConnectivity,
    getTimeFormat,
    dataset,
    options,
    xAxis,
    facilityChartTitles,
    tooltipTitleCallbacks
} from '../../../utils/chart';
import {
    TIME_FRAMES,
    timestampDay,
    resolutions
} from '../../../utils/constants';


const x = { type: 'time' }
const x1 = null
const y = {
    stacked: false,
    ticks: {},
    label: 'Connected (%)',
    gridLines: { drawOnChartArea: true }
}
const y1 = {
    stacked: false,
    ticks: {},
    label: 'Disconnected seconds',
    gridLines: { drawOnChartArea: true }
}
const y2 = null
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    filter: function (item, chart) {
        const idx = item.datasetIndex;
        const dataset = chart.datasets[idx]
        return !dataset.hidden;
    },
}

const ConnectivityChart = ({ height, data, showHotWater, showBattery, selectedTimezone }) => {
    const facilityConnectivityRef = useRef();
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('line', secondConnectivity.type, secondConnectivity.backgroundColor, secondConnectivity.borderColor, 'y'),
                dataset('line', minuteConnectivity.type, minuteConnectivity.backgroundColor, minuteConnectivity.borderColor, 'y'),
                dataset('line', secondServerConnectivity.type, secondServerConnectivity.backgroundColor, secondServerConnectivity.borderColor, 'y'),
                dataset('line', secondBSConnectivity.type, secondBSConnectivity.backgroundColor, secondBSConnectivity.borderColor, 'y'),
                dataset('line', secondHWConnectivity.type, secondHWConnectivity.backgroundColor, secondHWConnectivity.borderColor, 'y'),
                dataset('line', secondConnectivityGap.type, secondConnectivityGap.backgroundColor, secondConnectivityGap.borderColor, 'y1'),
            ]
        },
        options: options('', legendLabels, x, x1, y, y1, y2)
    });

    const resetState = () => {
        setChart({
            data: {
                labels: [],
                datasets: [
                    dataset('line', secondConnectivity.type, secondConnectivity.backgroundColor, secondConnectivity.borderColor, 'y'),
                    dataset('line', minuteConnectivity.type, minuteConnectivity.backgroundColor, minuteConnectivity.borderColor, 'y'),
                    dataset('line', secondServerConnectivity.type, secondServerConnectivity.backgroundColor, secondServerConnectivity.borderColor, 'y'),
                    dataset('line', secondBSConnectivity.type, secondBSConnectivity.backgroundColor, secondBSConnectivity.borderColor, 'y'),
                    dataset('line', secondHWConnectivity.type, secondHWConnectivity.backgroundColor, secondHWConnectivity.borderColor, 'y'),
                    dataset('line', secondConnectivityGap.type, secondConnectivityGap.backgroundColor, secondConnectivityGap.borderColor, 'y1'),
                ]
            },
            options: options('', legendLabels, x, x1, y, y1, y2)
        });
    }

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.week;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.week:
            case resolutions.day:
                populateChartByDay(data.connectivity, resolution);
                break;
            default:
                break;
        }
    }

    const populateChartByDay = (reports, resolution) => {
        if (!reports.length) {
            resetState();
            return;
        }

        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d });
        let hasServer = false;
        const start = reports[0][timestampDay];
        const end = reports[reports.length - 1][timestampDay];
        const maxSecondConnection = 86400;
        const maxMinuteConnection = 1440;
        const increment = resolution === resolutions.week ? 7 : 1
        let i = 0;

        for (let timestamp = start; timestamp <= end; timestamp+=increment) {
            if (timestamp === reports[i][timestampDay]) {
                const disconnectedSeconds = reports[i]['secondRecordsMaxGap'];
                const secondRecords = reports[i]['secondRecords'];
                const minuteRecords = reports[i]['minuteRecords'];
                const minuteServer = reports[i]['minutesConnected'];
                const secondConnection = secondRecords <= maxSecondConnection ? secondRecords : maxSecondConnection;
                const minuteConnection = minuteRecords <= maxMinuteConnection ? minuteRecords : maxMinuteConnection;
                let secondConnectionPercentage = (secondConnection * 100) / maxSecondConnection;
                let minuteConnectionPercentage = (minuteConnection * 100) / maxMinuteConnection;
                let serverConnectionPercentage = (minuteServer * 100) / maxMinuteConnection;

                if (secondConnectionPercentage < 90) secondConnectionPercentage = 88;
                if (minuteConnectionPercentage < 90) minuteConnectionPercentage = 88;
                if (serverConnectionPercentage < 90) serverConnectionPercentage = 88;
                if (showBattery) {
                    const minuteBs = reports[i]['minuteBatterySystemRecords'];
                    let bsConnectionPercentage = (minuteBs * 100) / maxMinuteConnection;
                    if (bsConnectionPercentage < 90) bsConnectionPercentage = 88;
                    datasets[3].data.push(Math.round(bsConnectionPercentage * 100) / 100);
                }
                if (showHotWater) {
                    const minuteHw = reports[i]['minuteHwRecords'];
                    let hwConnectionPercentage = (minuteHw * 100) / maxMinuteConnection;
                    if (hwConnectionPercentage < 90) hwConnectionPercentage = 88;
                    datasets[4].data.push(Math.round(hwConnectionPercentage * 100) / 100);
                }

                if (serverConnectionPercentage) {
                    datasets[2].data.push(Math.round(serverConnectionPercentage * 100) / 100);
                    hasServer = true;
                } else {
                    datasets[2].data.push(null);
                }

                datasets[0].data.push(Math.round(secondConnectionPercentage * 100) / 100);
                datasets[1].data.push(Math.round(minuteConnectionPercentage * 100) / 100);
                datasets[5].data.push(Math.round(disconnectedSeconds * 100) / 100);
                i++;
            } else {
                datasets[0].data.push(null);
                datasets[1].data.push(null);
                datasets[2].data.push(null);
                if (showBattery) datasets[3].data.push(null);
                if (showHotWater) datasets[4].data.push(null);
                datasets[5].data.push(null);
            }

            labels.push(timestamp * 86400 * 1000);
        }

        datasets[2].hidden = !hasServer;
        datasets[3].hidden = !showBattery;
        datasets[4].hidden = !showHotWater;
        const timeFormat = getTimeFormat(resolution);
        handleSetChart(labels, datasets, timeFormat);
    }

    const handleSetChart = (labels, datasets, time) => {
        const timeFrame = data.timeFrame;
        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                layout: { padding: { top: -8 } },
                plugins: {
                    ...chart.options.plugins,
                    tooltip: { ...chart.options.plugins.tooltip, callbacks: tooltipTitleCallbacks(selectedTimezone) }
                },
                scales: {
                    ...chart.options.scales,
                    y: {
                        ...chart.options.scales.y,
                        min: 88,
                        max: 100,
                        ticks: {
                            ...chart.options.scales.y1.ticks,
                            stepSize: 2,
                            maxTicksLimit: 7,
                            callback: function (value, index, values) {
                                if (value === 88) {
                                    return '<90';
                                }
                                return value;
                            }
                        }
                    },
                    x: xAxis(timeFrame, time, selectedTimezone)
                },
            }
        };

        update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes
        setChart(update);
    }

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.connectivity])

    useEffect(() => {
        handleDataOnChange(data);
    }, [data.timeFrame, selectedTimezone])

    return (
        <div className="connectivity-chart" style={{ height: height }}>
            <Line
                id={facilityChartTitles.connectivity[1]}
                ref={facilityConnectivityRef}
                data={chart.data}
                options={chart.options} />
        </div>
    )
}

export default ConnectivityChart;