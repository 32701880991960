/* Copyright Levelise Ltd 2025 */
const COMMAND = 'COMMAND';

export const saveDisableEndTime = (facilityName, time) => {
    localStorage.setItem(`${facilityName}${COMMAND}`, `${time}`);
}

export const getDisableEndTime = (facilityName) => {
    const time = localStorage.getItem(`${facilityName}${COMMAND}`);
    if (time && !isNaN(Number(time))) {
        return Number(time);
    }

    return 0;
}

export const clearDisableEndTime = (facilityName) => {
    localStorage.removeItem(`${facilityName}${COMMAND}`);
}