/* Copyright Levelise Ltd 2025 */
import { createContext, useState } from 'react';

const defaultValue = {
	selectedGSAVersion: 'all',
	selectedConsentProgress: '',
	selectedDirectionTab: 'import',
	selectedCaptureMethod: '',
	selectedProvider: '',
	filteredCustomerIdsByConsent: new Set(),
	filteredCustomerIds: [],

	setSelectedGSAVersion: () => {},
	setSelectedConsentProgress: () => {},
	setSelectedDirectionTab: () => {},
	setSelectedCaptureMethod: () => {},
	setSelectedProvider: () => {},
	setFilteredCustomerIdsByConsent: () => {},
	setFilteredCustomerIds: () => {},
};

const FleetCustomersContext = createContext(defaultValue);

export default FleetCustomersContext;

export function FleetCustomersProvider({ children }) {
	const [selectedGSAVersion, setSelectedGSAVersion] = useState('all');
	const [selectedConsentProgress, setSelectedConsentProgress] = useState('');
	const [selectedDirectionTab, setSelectedDirectionTab] = useState('import');
	const [selectedCaptureMethod, setSelectedCaptureMethod] = useState('');
	const [selectedProvider, setSelectedProvider] = useState('');
	const [filteredCustomerIds, setFilteredCustomerIds] = useState([]);
	const [filteredCustomerIdsByConsent, setFilteredCustomerIdsByConsent] = useState(new Set());
	

	const contextValues = {
		selectedGSAVersion,
		selectedConsentProgress,
		selectedDirectionTab,
		selectedCaptureMethod,
		selectedProvider,
		filteredCustomerIdsByConsent,
		filteredCustomerIds,

		setSelectedGSAVersion,
		setSelectedConsentProgress,
		setSelectedDirectionTab,
		setSelectedCaptureMethod,
		setSelectedProvider,
		setFilteredCustomerIdsByConsent,
		setFilteredCustomerIds
	};

	return <FleetCustomersContext.Provider value={contextValues}>{children}</FleetCustomersContext.Provider>;
}
