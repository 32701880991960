/* Copyright Levelise Ltd 2025 */
import { useContext, useEffect, useState } from 'react';
import TabContainer from '../TabContainer';
import './index.css';
import FleetCustomersContext from '../../contexts/FleetCustomersContext';
import DownloadBtn from '../DownloadBtn';
import { download, loading } from '../../utils/constants';

function fixOrdering(arr) {
	if (arr) {
		const order = ['accountAccess', 'facilityAccess', 'dsrAllowed', 'consented', 'notVerifiedWithEvidence', 'verified', 'verifiedWithEvidence', 'accepted', 'lodged', 'faulty'];
		arr.sort((a, b) => {
			const aIdx = order.indexOf(a.label);
			const bIdx = order.indexOf(b.label);
			if (aIdx !== -1 && bIdx !== -1) {
				return aIdx - bIdx;
			}

			return a.label.localeCompare(b.label);
		});
	}
}

const labels = {
	accountAccess: 'Account access',
	facilityAccess: 'Facility access',
	consented: 'GSA',
	dsrAllowed: 'DSR allowed',
	verifiedWithEvidence: 'Verified + has evidence',
	notVerifiedWithEvidence: 'Not verified + has evidence',
}

function CustomersConsentStatus({ customersConsentObject, onConsentsDownloadClick, downloadAction, downloading }) {
	const fleetCustomersContext = useContext(FleetCustomersContext);

	const [progresses, setProgresses] = useState([]);

	const resetTariffFilter = () => {
		fleetCustomersContext.setSelectedDirectionTab('import');
		fleetCustomersContext.setSelectedCaptureMethod('');
		fleetCustomersContext.setSelectedProvider('');
	};

	useEffect(() => {
		if (customersConsentObject) {
			let filteredCustomers = new Set([]);
			if (Object.hasOwn(customersConsentObject, 'gsaVersion')) {
				filteredCustomers = new Set(
					customersConsentObject.gsaVersion[fleetCustomersContext.selectedGSAVersion].customers
				);
			}

			if (Object.hasOwn(customersConsentObject, 'progress')) {
				const arr = [];
				const keys = Object.keys(customersConsentObject.progress);
				for (let i = 0; i < keys.length; i++) {
					let customers = customersConsentObject.progress[keys[i]].customers;
					customers = customers.filter((customer) => filteredCustomers.has(customer));
					arr.push({
						label: keys[i],
						customers,
						count: customers.length,
					});
				}

				fixOrdering(arr);

				setProgresses(arr);
			}

			fleetCustomersContext.setFilteredCustomerIdsByConsent(filteredCustomers);
		}
	}, [customersConsentObject, fleetCustomersContext.selectedGSAVersion]);

	useEffect(() => {
		if (customersConsentObject && Object.hasOwn(customersConsentObject, 'gsaVersion')) {
			if (!fleetCustomersContext.selectedConsentProgress) {
				fleetCustomersContext.setFilteredCustomerIdsByConsent(
					new Set(customersConsentObject.gsaVersion[fleetCustomersContext.selectedGSAVersion].customers)
				);
			}

			if (fleetCustomersContext.selectedConsentProgress) {
				for (let i = 0; i < progresses.length; i++) {
					if (fleetCustomersContext.selectedConsentProgress === progresses[i].label) {
						fleetCustomersContext.setFilteredCustomerIdsByConsent(new Set(progresses[i].customers));
						break;
					}
				}
			}
		}
	}, [progresses, fleetCustomersContext.selectedConsentProgress]);

	return (
		<TabContainer
			filterIcon
			title="Consents"
			containerStyle={{
				borderWidth: 1,
				borderColor: 'rgba(187, 187, 187, 0.33)',
				borderStyle: 'solid',
				borderRadius: 3,
			}}
			rightCornerComponent={
				<DownloadBtn
					downloadState={downloading && downloadAction === 'customers-consent-status' ? loading : download}
					clickDownload={onConsentsDownloadClick}
					style={{
						position: 'unset',
						marginLeft: 5,
						minWidth: 20,
					}}
				/>
			}
		>
			<div className="customers-consent-filter-list">
				<div className="tariff-directions-container sticky-header">
					{customersConsentObject && Object.hasOwn(customersConsentObject, 'gsaVersion') ? (
						<>
							<div
								key={'all'}
								className={`directions-tab ${
									fleetCustomersContext.selectedGSAVersion === 'all' ? 'selected' : ''
								}`}
								onClick={() => {
									fleetCustomersContext.setSelectedGSAVersion('all');
									fleetCustomersContext.setSelectedConsentProgress('');
									resetTariffFilter();
								}}
							>
								<span style={{ fontFamily: 'Inter-Bold' }}>Any</span>
							</div>
							{Object.keys(customersConsentObject.gsaVersion).map((key) => {
								if (key === 'all') {
									return null;
								}

								return (
									<div
										key={key}
										className={`directions-tab ${
											fleetCustomersContext.selectedGSAVersion === key ? 'selected' : ''
										} no-text-transform`}
										onClick={() => {
											fleetCustomersContext.setSelectedGSAVersion(key);
											fleetCustomersContext.setSelectedConsentProgress('');
											resetTariffFilter();
										}}
									>
										<span>v{key}</span>
									</div>
								);
							})}
						</>
					) : null}
				</div>

				<div className="consent-progress-list">
					<ul>
						{progresses ? (
							<>
								{progresses.map((progress, idx) => {
									return (
										<li
											key={idx}
											className={`${
												fleetCustomersContext.selectedConsentProgress === progress.label
													? 'selected'
													: ''
											}`}
											onClick={() => {
												fleetCustomersContext.setSelectedConsentProgress(progress.label);
												resetTariffFilter();
											}}
										>
											<span className="consent-progress-name">
												{labels[progress.label] || progress.label}
											</span>
											<span className="customers-consent-count">{progress.count}</span>
										</li>
									);
								})}
								<li
									key={'all-progress'}
									className={`${!fleetCustomersContext.selectedConsentProgress ? 'selected' : ''}`}
									onClick={() => {
										fleetCustomersContext.setSelectedConsentProgress('');
										resetTariffFilter();
									}}
								>
									<span className="capture-type-name">All</span>
									<span className="customers-capture-type-count">
										{customersConsentObject?.gsaVersion[fleetCustomersContext.selectedGSAVersion]
											.count || 0}
									</span>
								</li>
							</>
						) : null}
					</ul>
				</div>
			</div>
		</TabContainer>
	);
}

export default CustomersConsentStatus;
