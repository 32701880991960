/* Copyright Levelise Ltd 2019-2025 */
import { useEffect } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const RangePicker = ({ checked, handleDateRange, setStartDate, setEndDate, startDate, endDate, padLeft, padRight }) => {
    useEffect(() => {
        if (startDate && endDate && !checked) {
            handleDateRange(startDate, endDate);
        }
    }, [startDate, endDate, checked])

    const RightPaddedContainer = ({ className, children }) => {        
        return (
          <div style={{paddingRight: '65px'}}>
            <CalendarContainer className={className}>
              <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
          </div>
        );
      };

    const LeftPaddedContainer = ({ className, children }) => {
        return (
          <div style={{ paddingLeft: '100px'}}>
            <CalendarContainer className={className}>
              <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
          </div>
        );
      };

    return (
        <div className="range-picker">
            <DatePicker
                className="date-picker-input"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="From date"
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                calendarContainer={padLeft ? LeftPaddedContainer: null}
            />
            <DatePicker
                className="date-picker-input"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="To date"
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                calendarContainer={padRight ? RightPaddedContainer: null}
            />
        </div>
    );
};

export default RangePicker;