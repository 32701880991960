import { createContext, useContext, useState, useCallback } from "react";
import AlertContainer from "./AlertContainer";

const AlertContext = createContext();

export const AlertProvider = ({children}) => {
    const [alerts, setAlerts] = useState([]);

    const show = useCallback((message, type = "info") => {
        const id = Date.now();
        setAlerts((prev) => [...prev, {id, message, type, fading: false}]);

        setTimeout(() => {
            setAlerts((prev) => prev.map(alert => (alert.id === id ? {...alert, fading: true} : alert)))
        }, 4600)

        setTimeout(() => {
            setAlerts((prev) => prev.filter(alert => alert.id !== id ))
        }, 5000)
    }, []);

    const removeAlert = (id) => {
        setAlerts((prev) => prev.filter(alert => alert.id !== id ))
    }

    return (
        <AlertContext.Provider value={{ show }}>
            {children}
            <AlertContainer alerts={alerts} removeAlert={removeAlert} />
        </AlertContext.Provider>
    )
}

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error("useAlert must be used within an AlertProvider");
    }

    return context;
}