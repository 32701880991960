/* Copyright Levelise Ltd 2024 - 2025 */
import { useContext, useRef, useState } from 'react';
import './index.css';
import FacilityIcon from '../../assets/icons/FacilityIcon';
import FleetService from '../../services/fleet-service';
import FacilityConext from '../../contexts/FacilityContext';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../Alert';

function CustomerListItem({ functionArg, clickHandler, className, children }) {
	const timerRef = useRef(null);
    const facilityContext = useContext(FacilityConext);
    const navigate = useNavigate();
    const Alert = useAlert();

	const [isLongPress, setIsLongPress] = useState(false);
	const [loading, setLoading] = useState(false);

	const startPress = (e) => {
		if (e.button !== 0) {
			return;
		}

		setIsLongPress(false);

		timerRef.current = setTimeout(() => {
			setIsLongPress(true);
		}, 1000);
	};

	const endPress = (e) => {
		if (e.button !== 0) {
			return;
		}
		clearTimeout(timerRef.current);
		if (!isLongPress) {
			clickHandler(functionArg);
		} else {
			clickHandler(functionArg, true);
		}
	};

    const handleGoToFacility = async () => {
        try {
            setLoading(true);
            const res = await FleetService.getCustomerFacilities(functionArg);
            if (res && Object.hasOwn(res, 'facilities') && res.facilities.length > 0) {
                const { name } = res.facilities[0];
                const spec = await FleetService.getFacility(name);
                if (spec && Object.keys(spec).length > 1) {
                    facilityContext.setFacility(spec);
                    facilityContext.setFacilityName(name);
                    
                    navigate(`/facility/${name}`);
                } else {
                    // NOTE: Response body only contains DRU ID if specification is not set
                    Alert.show('Facility not found');
                }
            }
            setLoading(false);
        } catch {
            Alert.show('Something went wrong, please try again.');
            setLoading(false);
        }
    }

	return (
		<li className={`${className} customers-list-item`}>
			<div
				className="customer-list-item-div"
				onMouseDown={(e) => startPress(e)}
				onTouchStart={(e) => startPress(e)}
				onMouseUp={(e) => endPress(e)}
				onTouchEnd={(e) => endPress(e)}
			>
				{children}
			</div>
			<button
				onClick={handleGoToFacility}
                className='go-to-facility-button'
                disabled={loading}
			>
				<FacilityIcon width={28} />
			</button>
		</li>
	);
}

export default CustomerListItem;
