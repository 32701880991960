/* Copyright Levelise Ltd 2019-2025 */
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import cx from 'classnames';
import config from '../../config';
import UserService from '../../services/user-service';
import FacilityContext from '../../contexts/FacilityContext';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import LiveStatus from '../../components/LiveStatus/index';
import LineCharts from '../../components/LineChartsFacility/index';
import LogMessages from '../../components/LogMessages/index';
import FacilitySpecification from '../../components/FacilitySpecification/index';
import Summary from '../../components/Summary/index';
import { PERMISSIONS, show } from '../../utils/constants';
import { hasPermission, combineStatus } from '../../utils/utils';
import { handleError } from '../../services/api-error-service';

import './index.css';
import './preferenceStyles/facilityOne.css';
import './preferenceStyles/facilityTwo.css';
import './preferenceStyles/facilityThree.css';
import './preferenceStyles/facilityFour.css';
import './preferenceStyles/facilityFive.css';
import './preferenceStyles/facilitySix.css';
import './preferenceStyles/facilitySeven.css';

const FacilityRoute = () => {
	const facilityContext = useContext(FacilityContext);
	const fleetContext = useContext(FleetContext);
	const navigate = useNavigate();
	const { facilityName } = useParams();
	const [druId, setDruId] = useState('');
	const [error, setError] = useState(null);

	const checkRefresh = () => {
		const facility = facilityContext.facility;
		if (facilityName) {
			if (!!Object.keys(facility).length && facilityContext.facilityName === facilityName) {
				setDruId(Object.hasOwn(facility, 'druId') ? facility.druId : '');
				handleFetchStatus(facilityName);
			} else {
				handleFetchSpecification(facilityName);
				handleFetchStatus(facilityName);
			}
		}
	};

	const handleFetchSpecification = (facility) => {
		if (!!facility && facility !== 'undefined') {
			FleetService.getFacility(facility)
				.then((res) => {
					setDruId(res.druId);
					facilityContext.setFacility(res);
					facilityContext.setFacilityName(facility);
				})
				.catch(facilityContext.setError);
		}
	};

	const handleFetchStatus = (facilityName) => {
		if (!!facilityName && facilityName !== 'undefined') {
			FleetService.getFacilityStatus(facilityName)
				.then((res) => {
					facilityContext.setStatus(res);
				})
				.catch(facilityContext.setError);
		}
	};

	const handleFetchDruStatusAndFaultCodes = () => {
		if (!Object.keys(fleetContext.status).length)
			FleetService.getDruStatuses().then(handleStatus).catch(fleetContext.setError);
		if (!Object.keys(fleetContext.faultCodes).length) {
			FleetService.getFaultCode().then(fleetContext.setFaultCodes).catch(fleetContext.setError);
		}
	};

	const handleStatus = (druStatus) => {
		const [drus, status] = combineStatus(druStatus);
		fleetContext.setStatus(status);
		fleetContext.setCurrentDrus(drus);
	};

	const renderSection = () => {
		const _showLog = hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY);
		let preferenceClass = '';
		let _info = true;
		let _energy = true;
		let _logs = true;
		if (UserService.hasPreferences()) {
			const preference = UserService.getPreferences();
			_info = preference[config.facilityInfo] === show;
			_energy = preference[config.energyData] === show;
			_logs = preference[config.logsPanel] === show;
			if (!_info && _energy && _logs) preferenceClass = 'preferenceOne';
			else if (!_info && !_energy && _logs) preferenceClass = 'preferenceTwo';
			else if (_info && !_energy && _logs) preferenceClass = 'preferenceThree';
			else if (!_info && _energy && !_logs) preferenceClass = 'preferenceFour';
			else if (_info && !_energy && !_logs) preferenceClass = 'preferenceFive';
			else if (_info && _energy && !_logs) preferenceClass = 'preferenceSix';
			else if (!_info && !_energy && !_logs) preferenceClass = 'preferenceSeven';
		}

		return (
			<section className="facility-route">
				<div className={cx('specs-wrapper', preferenceClass)}>
					<div id="top-section">
						<LiveStatus facilityName={facilityName} />
						{_energy && <Summary facilityName={facilityName} />}
					</div>
					<div id="bottom-section">
						{_info && (
							<FacilitySpecification
								druId={druId}
								facilityName={facilityName}
								refetchFacilityStatus={checkRefresh}
							/>
						)}
						{_logs && _showLog && <LogMessages facilityName={facilityName} />}
					</div>
				</div>
				<div className="charts-wrapper">
					<LineCharts facilityName={facilityName} />
				</div>
			</section>
		);
	};

	useEffect(() => {
		const err = facilityContext.error;
		if (!!err && handleError(err)) {
			setError(err.status);
		}
	}, [facilityContext.error]);

	useEffect(() => {
		if (!fleetContext.tokenUpdating) {
			handleFetchDruStatusAndFaultCodes();
			checkRefresh();
		}
	}, [fleetContext.tokenUpdating, facilityName]);

	if (error) {
		navigate(`/error-page`);
	}

	return <>{renderSection()}</>;
};

export default FacilityRoute;
