/* Copyright Levelise Ltd 2019-2025 */
import { Chart } from 'chart.js';
import { subDays, addDays, getUnixTime, getTime } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { resolutions, TIME_FRAMES } from './constants';
import { formatTimestamp, isMonday } from './utils';

export const dataset = (type, label, backgroundColor, borderColor, yAxisID = 'y', fill = false, tension = 0,
    borderWidth = 0.8, borderDashOffset = 0.0, pointBorderWidth = 1, pointHoverRadius = 3, pointHoverBorderWidth = 1,
    pointRadius = 0, pointHitRadius = 0) => {
    return {
        type,
        label,
        fill,
        tension: false,
        stepped: 0,
        backgroundColor,
        borderColor,
        borderWidth,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset,
        borderJoinStyle: 'miter',
        pointBorderColor: borderColor,
        pointBackgroundColor: backgroundColor,
        pointBorderWidth,
        pointHoverRadius,
        pointHoverBackgroundColor: backgroundColor,
        pointHoverBorderColor: borderColor,
        pointHoverBorderWidth,
        pointRadius,
        pointHitRadius,
        data: [],
        yAxisID: yAxisID,
    }
}

export const options = (title, labels, x = null, x1 = null, y = null, y1 = null, y2 = null, lineTracker = true, showLegends = true) => {
    const ops = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: { mode: 'index', intersect: false },
        gridLines: { color: 'transparent', display: true, drawBorder: false, zeroLineColor: '#ccc', zeroLineWidth: 1 },
        animation: false,
        plugins: {
            title: { display: true, text: title, position: 'top' },
            legend: { display: showLegends, position: 'bottom', labels: labels },
            filler: { propagate: false },
            tooltip: { mode: 'index', intersect: false },
            hover: { mode: 'index', intersect: false },
            lineTracker
        },
        scales: {},
    }

    if (lineTracker) {
        ops.plugins['zoom'] = {
            zoom: {
                animation: { duration: 0 },
                drag: { backgroundColor: 'rgba(0,0,0,0.3)', enabled: true },
                pinch: { enabled: true },
                mode: 'x',
                scaleMode: 'x',
                onZoomComplete: chart => {
                    const maxX = chart.chart.scales.x.max
                    const minX = chart.chart.scales.x.min
                    const activeId = chart.chart.canvas.id;
                    const group = chartGroupList.find(g => g.indexOf(activeId) >= 0);
                    const instances = Object.values(Chart.instances).filter(instance => group.indexOf(instance.canvas.id) >= 0);
                    instances.forEach(instance => {
                        if (instance.canvas.id === fleetChartTitles.ppm[1]) {
                            instance.zoomScale('x', { min: minX, max: maxX }, 'zoom');
                            instance.zoomScale('x1', { min: minX, max: maxX }, 'zoom');
                        } else {
                            instance.zoomScale('x', { min: minX, max: maxX }, 'zoom');
                        }
                    })
                }
            }
        }
    }

    if (!!x) {
        if (x.type === 'time')
            ops.scales['x'] = {
                type: 'time',
                parsing: false,
                time: { unit: 'minute', displayFormats: { minute: 'HH:mm:ss' } },
                position: 'bottom',
                display: true,
                distribution: 'linear',
                scaleOverride: false,
                ticks: { font: { size: 10 }, color: 'black' },
                title: { display: true, font: { size: 12 }, text: x.label, color: 'black' },
            }
        else
            ops.scales['x'] = {
                type: x.type,
                position: 'bottom',
                display: true,
                title: { display: true, font: { size: 12 }, text: x.label, color: 'black' },
                ticks: { font: { size: 10 }, color: 'black' },
            }
    }

    if (!!x1) {
        if (x1.type === 'time')
            ops.scales['x1'] = {
                type: 'time',
                parsing: false,
                time: { unit: 'minute', displayFormats: { minute: 'HH:mm:ss' } },
                position: 'top',
                display: true,
                distribution: 'linear',
                scaleOverride: false,
                ticks: { font: { size: 10 }, color: 'black' },
                title: { display: true, font: { size: 12 }, text: x1.label, color: 'black' },
            }
        else
            ops.scales['x1'] = {
                type: x1.type,
                position: 'bottom',
                display: true,
                title: { display: true, font: { size: 12 }, text: x1.label, color: 'black' },
                ticks: { font: { size: 10 }, color: 'black' },
            }
    }

    if (!!y) {
        ops.scales['y'] = {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: { ...y.ticks, font: { size: 10 }, color: 'black' },
            stacked: y.stacked,
            title: { display: true, font: { size: 12 }, text: y.label, color: 'black' },
            grid: y.gridLines
        }
    }

    if (!!y1) {
        ops.scales['y1'] = {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: { ...y1.ticks, font: { size: 10 }, color: 'black' },
            stacked: y1.stacked,
            title: { display: true, font: { size: 12 }, text: y1.label, color: 'black' },
            grid: y1.gridLines
        }
    }

    if (!!y2) {
        ops.scales['y2'] = {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: { ...y2.ticks, font: { size: 10 }, color: 'black' },
            stacked: y2.stacked,
            title: { display: true, font: { size: 12 }, text: y2.label, color: 'black' },
            grid: y2.gridLines
        }
    }

    return ops;
}

export const tooltipTitleCallbacks = (timezone) => {
    return {
        title: (context) => {
            const context0 = context[0];
            const ts = context0.parsed.x;
            let title = context0.label;
            if (!!title && !!ts) {
                const unit = context0.chart.scales.x._unit || 'minute';
                switch (unit) {
                    case 'second':
                        title = formatTimestamp(ts / 1000, timezone, 'dd/MM/yy HH:mm:ss');
                        break
                    case 'minute':
                        title = formatTimestamp(ts / 1000, timezone, 'dd/MM/yy HH:mm');
                        break
                    case 'day':
                        title = formatTimestamp(ts / 1000, timezone, 'dd/MM/yy');
                        break
                    case 'week':
                        title = formatTimestamp(ts / 1000, timezone, 'dd/MM/yy');
                        break
                    default:
                }
            }

            return title;
        }
    }
}


export const getValue = (value, negValue = false) => {
    if (value === undefined || value === null) return null;
    if (value === 0) return value;
    return negValue ? -value : value;
}

export const getDiffValues = (value1, value2) => {
    if ((value1 === undefined && value2 === undefined) || (value1 === null && value2 === null)) return null;
    return value1 - value2;
}

// Fleet chart titles 
export const fleetChartTitles = {
    dr: ['Demand Response', 'fleetDr'],
    ppm: ['Percentage Performance Measure (PPM)', 'fleetPpm'],
    dispatch: ['Power Dispatch', 'fleetDispatch'],
    energy: ['Energy Flow', 'fleetEnergy'],
    load: ['Stacked Load', 'fleetLoad'],
    generation: ['Stacked Generation', 'fleetGeneration'],
    connected: ['All Connected DRUs', 'fleetAllConnected'],
    connectedBs: ['Connected DRUs', 'fleetConnected'],
    soc: ['All Systems State of Charge', 'fleetAllSoc'],
    socBs: ['State of Charge', 'fleetSoc'],
    temperature: ['All Systems Temperature', 'fleetAllTemp'],
    temperatureBs: ['Temperature', 'fleetTemp'],
    hwFlow: ['All HW Flow', 'fleetAllFlow'],
    hwFlowTank: ['HW Flow', 'fleetFlow'],
    hwMedianTemperature: ['All HW Median Temperature', 'fleetAllHwMedianTemp'],
    hwMedianTemperatureTank: ['HW Median Temperature', 'fleetHwMedianTemp'],
    hwSensorTemperature: ['All HW Temperature by Sensor', 'fleetAllHwTempBySensor'],
    hwSensorTemperatureTank: ['HW Temperature by Sensor', 'fleetHwTempBySensor']
}

export const facilityChartTitles = {
    energy: ['Energy Flow', 'facilityEnergy'],
    dr: ['Demand Response', 'facilityDr'],
    druState: ['DRU State', 'facilityDruState'],
    battery: ['Battery', 'facilityBattery'],
    hotWater: ['Hot Water', 'facilityHotWater'],
    house: ['House', 'facilityHouse'],
    connectivity: ['Connectivity', 'facilityConnectivity']
}

export const customerChartTitles = {
    tariff: ['Tariff']
}

export const fleetChartIds = [
    fleetChartTitles.dr[1],
    fleetChartTitles.ppm[1],
    fleetChartTitles.energy[1],
    fleetChartTitles.load[1],
    fleetChartTitles.generation[1],
    fleetChartTitles.connected[1],
    fleetChartTitles.connectedBs[1],
    fleetChartTitles.soc[1],
    fleetChartTitles.socBs[1],
    fleetChartTitles.temperature[1],
    fleetChartTitles.temperatureBs[1],
    fleetChartTitles.hwFlow[1],
    fleetChartTitles.hwFlowTank[1],
    fleetChartTitles.hwMedianTemperature[1],
    fleetChartTitles.hwMedianTemperatureTank[1],
    fleetChartTitles.hwSensorTemperature[1],
    fleetChartTitles.hwSensorTemperatureTank[1]
]

export const facilityChartIds = [
    facilityChartTitles.energy[1],
    facilityChartTitles.dr[1],
    facilityChartTitles.druState[1],
    facilityChartTitles.battery[1],
    facilityChartTitles.hotWater[1],
    facilityChartTitles.house[1],
    facilityChartTitles.connectivity[1],
]

const chartGroupList = [
    [
        fleetChartTitles.dr[1],
        fleetChartTitles.ppm[1]
    ],
    [
        fleetChartTitles.energy[1],
        fleetChartTitles.load[1],
        fleetChartTitles.generation[1]
    ],
    [
        fleetChartTitles.connected[1],
        fleetChartTitles.connectedBs[1],
        fleetChartTitles.soc[1],
        fleetChartTitles.socBs[1],
        fleetChartTitles.temperature[1],
        fleetChartTitles.temperatureBs[1]
    ],
    [
        fleetChartTitles.hwFlow[1],
        fleetChartTitles.hwFlowTank[1],
        fleetChartTitles.hwMedianTemperature[1],
        fleetChartTitles.hwMedianTemperatureTank[1],
        fleetChartTitles.hwSensorTemperature[1],
        fleetChartTitles.hwSensorTemperatureTank[1]
    ],
    [
        facilityChartTitles.energy[1],
        facilityChartTitles.dr[1],
        facilityChartTitles.druState[1],
        facilityChartTitles.battery[1],
        facilityChartTitles.hotWater[1],
        facilityChartTitles.house[1],
        facilityChartTitles.connectivity[1],
    ],
    [
        customerChartTitles.tariff[0]
    ]
]

export const verticalLinePlugin = {
    id: 'lineTracker',
    afterDraw: chart => {
        if (chart.tooltip?._active?.length) {
            const active = chart.tooltip.dataPoints[0];
            const activeId = chart.canvas.id;
            const x = active.element.x;
            const yAxis = chart.scales.y;
            const ctx = chart.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 0.7;
            ctx.strokeStyle = 'black';
            ctx.setLineDash([8])
            ctx.stroke();
            ctx.restore();

            const group = chartGroupList.find(g => g.indexOf(activeId) >= 0);
            const instances = Object.values(Chart.instances).filter(instance => group.indexOf(instance.canvas.id) >= 0);
            instances.forEach(instance => {
                if (chart.id !== instance.id) {
                    let index = 0;
                    const datasets = instance.data.datasets;
                    for (let i = 0; i < datasets.length; i++) {
                        if (datasets[i].hidden === false) {
                            index = i;
                            break;
                        }
                    }

                    const meta = instance.getDatasetMeta(index)
                    const activeParsedX = parseInt(active.parsed.x / 1000);
                    const idx = meta._parsed.findIndex(m => parseInt(m.x / 1000) === activeParsedX);
                    const d = meta.data[idx];
                    if (d) {
                        const point = d.getCenterPoint();
                        const yAxis1 = instance.scales.y;
                        const ctx1 = instance.ctx;

                        instance.clear();
                        instance.draw();
                        ctx1.save();
                        ctx1.beginPath();
                        ctx1.moveTo(point.x, yAxis1.top);
                        ctx1.lineTo(point.x, yAxis1.bottom);
                        ctx1.lineWidth = 0.7;
                        ctx1.strokeStyle = 'black';
                        ctx1.setLineDash([8])
                        ctx1.stroke();
                        ctx1.restore();
                    }
                }
            });
        }
    }
}


export const xAxis = (timeFrame, time, timezone = 'Europe/London', position = 'bottom', ppmStart = null, ppmEnd = null) => {
    return {
        type: 'time',
        display: true,
        parsing: false,
        position,
        time,
        ticks: {
            source: 'labels',
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
            font: { size: 10 },
            color: 'black',
            callback: function (value, i, values) {
                if (!!timeFrame) {
                    const tick = handleTick(timeFrame, value, i, values, timezone, ppmStart, ppmEnd);
                    return tick;
                }
            },
        },
        distribution: 'linear',
        scaleOverride: false,
    }
}

export const handleTick = (timeFrame, tick, index, ticks, timezone, ppmStart, ppmEnd) => {
    const diff_timestamp = (ticks[index].value - ticks[0].value) / 1000;
    let mod, months, weeks, days, hours, minutes, seconds;
    switch (timeFrame) {
        case TIME_FRAMES.fifteen_minutes:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy HH:mm:ss');
            hours = parseInt(tick.slice(-8, -6))
            minutes = parseInt(tick.slice(-5, -3));
            seconds = parseInt(tick.slice(-2));
            if (hours === 0 && minutes === 0 && seconds === 0) {
                if (ppmEnd) {
                    let min = minutes < 30 ? minutes + 30 : minutes - 30;
                    let hour = minutes < 30 ? hours : hours >= 23 ? 0 : hours + 1;
                    let second = seconds < 10 ? '0' + seconds : seconds;
                    min = min < 10 ? '0' + min : min;
                    hour = hour < 10 ? '0' + hour : hour;
                    return [`${hour}:${min}:${second}`];
                }
                return [tick.slice(-8), tick.slice(0, -9)];
            } else if (seconds === 0 && minutes % 3 === 0) {
                if (ppmEnd) {
                    let min = minutes < 30 ? minutes + 30 : minutes - 30;
                    let hour = minutes < 30 ? hours : hours >= 23 ? 0 : hours + 1;
                    let second = seconds < 10 ? '0' + seconds : seconds;
                    min = min < 10 ? '0' + min : min;
                    hour = hour < 10 ? '0' + hour : hour;
                    return [`${hour}:${min}:${second}`];
                }
                return [tick.slice(-8)];
            } else if (seconds === 0 && minutes % 3 !== 0) {
                return '';
            } else {
                return;
            }
        case TIME_FRAMES.one_hour:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy HH:mm');
            hours = parseInt(tick.slice(-5, -3));
            minutes = parseInt(tick.slice(-2));
            if (index === 0 || (diff_timestamp > 4 * 60 && hours === 0 && minutes === 10)) {
                if (ppmEnd) {
                    let min = minutes < 30 ? minutes + 30 : minutes - 30;
                    let hour = minutes < 30 ? hours : hours >= 23 ? 0 : hours + 1;
                    min = min < 10 ? '0' + min : min;
                    hour = hour < 10 ? '0' + hour : hour;
                    return [`${hour}:${min}`];
                } else if (ppmStart) {
                    return [tick.slice(0, -6), tick.slice(-5)];
                } else {
                    return [tick.slice(-5), tick.slice(0, -6)];
                }
            } else if (diff_timestamp > 4 * 60 && minutes % 10 === 0) {
                if (ppmEnd) {
                    let min = minutes < 30 ? minutes + 30 : minutes - 30;
                    let hour = minutes < 30 ? hours : hours >= 23 ? 0 : hours + 1;
                    min = min < 10 ? '0' + min : min;
                    hour = hour < 10 ? '0' + hour : hour;
                    return [`${hour}:${min}`];
                }
                return [tick.slice(-5)];
            } else if (minutes % 5 === 0) {
                return [''];
            } else {
                return;
            }
        case TIME_FRAMES.six_hours:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy HH:mm');
            hours = parseInt(tick.slice(-5, -3));
            minutes = parseInt(tick.slice(-2));
            if (index === 0 || (diff_timestamp > 29 * 60 && hours === 0 && minutes === 0)) {
                if (ppmEnd) {
                    let min = minutes < 30 ? minutes + 30 : minutes - 30;
                    let hour = minutes < 30 ? hours : hours >= 23 ? 0 : hours + 1;
                    min = min < 10 ? '0' + min : min;
                    hour = hour < 10 ? '0' + hour : hour;
                    return [`${hour}:${min}`];
                } else if (ppmStart) {
                    return [tick.slice(0, -6), tick.slice(-5)];
                } else {
                    return [tick.slice(-5), tick.slice(0, -6)];
                }
            } else if (diff_timestamp > 29 * 60 && minutes === 0) {
                if (ppmEnd) {
                    return [tick.slice(-5, -2) + '30'];
                }
                return [tick.slice(-5)];
            } else if (minutes % 15 === 0) {
                return [''];
            } else {
                return;
            }
        case TIME_FRAMES.twenty_four_hours:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy HH:mm');
            hours = parseInt(tick.slice(-5, -3));
            minutes = parseInt(tick.slice(-2));
            if (index === 0 || (diff_timestamp > 90 * 59 && hours === 0 && minutes === 0)) {
                if (ppmEnd) {
                    let min = minutes < 30 ? minutes + 30 : minutes - 30;
                    let hour = minutes < 30 ? hours : hours >= 23 ? 0 : hours + 1;
                    min = min < 10 ? '0' + min : min;
                    hour = hour < 10 ? '0' + hour : hour;
                    return [`${hour}:${min}`];
                } else if (ppmStart) {
                    return [tick.slice(0, -6), tick.slice(-5)];
                } else {
                    return [tick.slice(-5), tick.slice(0, -6)];
                }
            } else if (diff_timestamp > 90 * 59 && hours % 4 === 0 && minutes === 0) {
                if (ppmEnd) {
                    return [tick.slice(-5, -2) + '30'];
                }
                return [tick.slice(-5)];
            } else if (minutes === 0) {
                return [''];
            } else {
                return;
            }
        case TIME_FRAMES.one_week:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy HH:mm');
            hours = parseInt(tick.slice(-5, -3));
            minutes = parseInt(tick.slice(-2));
            if (hours === 0 && minutes === 0) {
                if (ppmEnd) {
                    return [tick.slice(-5, -2) + '30'];
                }
                return [tick.slice(-5), tick.slice(0, -6)];
            } else if (hours % 6 === 0 && minutes === 0) {
                return [''];
            } else {
                return;
            }
        case TIME_FRAMES.twenty_one_days:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy HH:mm');
            days = parseInt(tick.slice(0, 3))
            hours = parseInt(tick.slice(-5, -3));
            minutes = parseInt(tick.slice(-2));
            if (days % 3 === 0 && hours === 0 && minutes === 0) {
                if (ppmEnd) {
                    return [tick.slice(-5, -2) + '30'];
                }
                return [tick.slice(-5), tick.slice(0, -6)];
            } else if (hours === 0 && minutes === 0) {
                return '';
            } else {
                return;
            }
        case TIME_FRAMES.three_months:
            if (isMonday(ticks[index].value / 1000, timezone)) {
                weeks = (((ticks[index].value / (86400 * 1000)) - 4) / 7) * 7 + 4;
                if (weeks % 2 === 0) {
                    tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy');
                    return [tick];
                } else {
                    return '';
                }
            }
            return;
        case TIME_FRAMES.twelve_months:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy');
            months = parseInt(tick.slice(3, 5))
            days = parseInt(tick.slice(0, 2))
            if (months % 3 === 0 && days === 1) {
                return [tick];
            } else if (months % 3 !== 0 && days === 1) {
                return '';
            } else {
                return;
            }
        case TIME_FRAMES.thirty_six_months:
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy');
            mod = ticks.length > 730 ? 6 : 4;
            months = parseInt(tick.slice(3, 5));
            days = parseInt(tick.slice(0, 2));
            if (months % mod === 0 && days === 1) {
                return [tick];
            } else if (months % mod !== 0 && days === 1) {
                return '';
            } else {
                return;
            }
        case TIME_FRAMES.all:
            mod = 1;
            if (ticks.length > 8) {
                mod = ticks.length < 16 ? 2 : parseInt(parseInt(ticks.length / 8) / 2) * 2;
            }
            if (index % mod === 0) {
                tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy');
                return [tick];
            }

            if (mod > 8) {
                if (index % (mod / 2) === 0) {
                    return '';
                }
            } else {
                return '';
            }
            return;
        case TIME_FRAMES.select:
            const len = ticks.length;
            tick = formatTimestamp(ticks[index].value / 1000, timezone, 'dd/MM/yy');
            mod = ticks.length > 730 ? 6 : 4;
            months = parseInt(tick.slice(3, 5))
            days = parseInt(tick.slice(0, 2))
            if (len <= 30) {
                if (days % 5 === 0) return [tick];
                else if (days % 1 === 0) return '';
            } else if (len <= 180) {
                if (days === 1) return [tick];
                else if (days === 11 || days === 21) return '';
            } else if (len <= 366) {
                if (months % 3 === 0 && days === 1) return [tick];
                else if (months % 3 !== 0 && days === 1) return '';
            } else {
                if (months % mod === 0 && days === 1) return [tick];
                else if (months % mod !== 0 && days === 1) return '';
            }

            return;
        default:
            return;
    }
}

export const getTimeFormat = (resolution) => {
    switch (resolution) {
        case resolutions.week:
            return { unit: 'week', parser: false };
        case resolutions.day:
            return { unit: 'day', parser: false };
        case resolutions.half_hour:
            return { unit: 'minute', parser: false };
        case resolutions.minute:
            return { unit: 'minute', parser: false };
        case resolutions.second:
            return { unit: 'second', parser: false };
        default:
            return;
    }
}

export const maxTemperature = max => {
    if (max !== null) {
        return [
            {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: max,
                borderColor: 'black',
                borderWidth: 1,
                label: {
                    enabled: true,
                    fontColor: 'black',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    content: 'Maximum allowed',
                    position: 'right',
                    fontSize: 8,
                    yAdjust: -5,
                    xAdjust: 1
                }
            }
        ];
    }

    return [];
}

export const temperatureLimits = (bs, scaleId = 'y') => {
    const result = {}
    if (typeof bs === "object" && bs !== null) {
        const minTemperatureC = bs.hasOwnProperty('minTemperatureC') ? bs['minTemperatureC'] : null;
        const maxTemperatureC = bs.hasOwnProperty('maxTemperatureC') ? bs['maxTemperatureC'] : null;
        const minIdealTemperatureC = bs.hasOwnProperty('minIdealTemperatureC') ? bs['minIdealTemperatureC'] : null;
        const maxIdealTemperatureC = bs.hasOwnProperty('maxIdealTemperatureC') ? bs['maxIdealTemperatureC'] : null;
        if (minTemperatureC !== null) {
            result["min"] = {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: scaleId,
                value: minTemperatureC,
                borderColor: colors.blackNew,
                borderWidth: 0.8,
                borderDash: [5, 5],
                borderDashOffset: 10,
            };
        }
        if (maxTemperatureC !== null) {
            result["max"] = {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: scaleId,
                value: maxTemperatureC,
                borderColor: colors.blackNew,
                borderWidth: 0.8,
                borderDash: [5, 5],
                borderDashOffset: 10,
            };
        }
        if (minIdealTemperatureC !== null && minTemperatureC !== minIdealTemperatureC) {
            result["minIdeal"] = {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: scaleId,
                value: minIdealTemperatureC,
                borderColor: colors.purple,
                borderWidth: 0.8,
                borderDash: [5, 5],
                borderDashOffset: 10,
            };
        }
        if (maxIdealTemperatureC !== null&& maxTemperatureC !== maxIdealTemperatureC) {
            result["maxIdeal"] = {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: scaleId,
                value: maxIdealTemperatureC,
                borderColor: colors.purple,
                borderWidth: 0.8,
                borderDash: [5, 5],
                borderDashOffset: 10,
            };
        }
    }

    return result;
}

export const PPM_LOSS = {
    ppm100: {
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y',
        value: 100,
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1.5,
        label: { enabled: false, content: '' }
    },
    ppm95: {
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y',
        value: 95,
        borderColor: 'rgba(100, 182, 100, 1)',
        borderWidth: 1,
        label: {
            enabled: true,
            content: '25% loss',
            position: 'end',
            font: { size: 7.5 },
            xPadding: 4,
            yPadding: 5,
            yAdjust: 9,
            xAdjust: 1.5
        }
    },
    ppm60: {
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y',
        value: 60,
        borderColor: 'rgba(232, 96, 53, 1)',
        borderWidth: 1,
        label: {
            enabled: true,
            content: '50% loss',
            position: 'end',
            font: { size: 7.5 },
            xPadding: 4,
            yPadding: 5,
            yAdjust: 9,
            xAdjust: 1.5
        }
    },
    ppm10: {
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y',
        value: 10,
        borderColor: 'rgba(168, 25, 22, 1   )',
        borderWidth: 1,
        label: {
            enabled: true,
            content: '100% loss',
            position: 'end',
            font: { size: 7.5 },
            xPadding: 4,
            yPadding: 5,
            yAdjust: 9,
            xAdjust: 1.5
        }
    }
};

export const CONTRACT_ANNOTATION = (timestamp, timezone, reliable = false) => {
    const date = fromZonedTime(new Date(timestamp * 1000), timezone);
    const by = formatTimestamp(getUnixTime(subDays(date, 2)), timezone, 'dd/MM/yyyy').split('/');
    const y = formatTimestamp(getUnixTime(subDays(date, 1)), timezone, 'dd/MM/yyyy').split('/');
    const t = formatTimestamp(getUnixTime(date), timezone, 'dd/MM/yyyy').split('/');
    const af = formatTimestamp(getUnixTime(addDays(date, 1)), timezone, 'dd/MM/yyyy').split('/');
    const annotation = {
        efaY12: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(by[2]), parseInt(by[1]) - 1, parseInt(by[0]), 23), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 7), timezone)),
            backgroundColor: 'rgba(0,255,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaY34: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 7), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 15), timezone)),
            backgroundColor: 'rgba(255,255,140,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaY5: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 15), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 19), timezone)),
            backgroundColor: 'rgba(255,0,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaY6: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 19), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 23), timezone)),
            backgroundColor: 'rgba(255,140,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaT12: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(y[2]), parseInt(y[1]) - 1, parseInt(y[0]), 23), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 7), timezone)),
            backgroundColor: 'rgba(0,255,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaT34: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 7), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 15), timezone)),
            backgroundColor: 'rgba(255,255,140,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaT5: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 15), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 19), timezone)),
            backgroundColor: 'rgba(255,0,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaT6: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 19), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 23), timezone)),
            backgroundColor: 'rgba(255,140,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaT14: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(t[2]), parseInt(t[1]) - 1, parseInt(t[0]), 23), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(af[2]), parseInt(af[1]) - 1, parseInt(af[0]), 7), timezone)),
            backgroundColor: 'rgba(0,255,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaT35: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(af[2]), parseInt(af[1]) - 1, parseInt(af[0]), 7), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(af[2]), parseInt(af[1]) - 1, parseInt(af[0]), 15), timezone)),
            backgroundColor: 'rgba(255,255,140,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        },
        efaT36: {
            drawTime: 'afterDatasetsDraw',
            type: 'box',
            xScaleID: 'x',
            adjustScaleRange: false,
            xMin: getTime(fromZonedTime(new Date(parseInt(af[2]), parseInt(af[1]) - 1, parseInt(af[0]), 15), timezone)),
            xMax: getTime(fromZonedTime(new Date(parseInt(af[2]), parseInt(af[1]) - 1, parseInt(af[0]), 19), timezone)),
            backgroundColor: 'rgba(255,0,0,0.07)',
            borderColor: 'rgba(0,0,0,0.3)',
            borderWidth: 0.2,
        }
    };

    if (reliable) {
        if (Date.now() / 1000 - timestamp < 6 * 3600) {
            const reliableLine = getTime(fromZonedTime(new Date((timestamp - 6 * 3600) * 1000), timezone));
            annotation['reliableLine'] = {
                type: 'line',
                xScaleID: 'x',
                adjustScaleRange: false,
                xMin: reliableLine,
                xMax: reliableLine,
                borderColor: colors.darkgray.border,
                borderWidth: 1,
                borderDash: [5, 5],
                borderDashOffset: 10,
                label: {
                    enabled: true,
                    font: { size: 9 },
                    color: 'black',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    content: 'Reliable',
                    position: 'start',
                    yAdjust: -22,
                    xAdjust: 0
                }
            }
        }
    }

    return annotation;
}

export const colors = {
    violet: { background: 'rgba(199, 125, 255,0.6)', border: 'rgba(199, 125, 255,0.6)' },
    liver_brown: { background: 'rgba(135, 79, 0,0.6)', border: 'rgba(135, 79, 0,0.6)' },
    bronze: { background: 'rgba(196, 127, 67,0.6)', border: 'rgba(196, 127, 67,0.6)' },
    light_brown: { background: 'rgba(248, 181, 130,0.6)', border: 'rgba(248, 181, 130,0.6)' },
    yellow_orange: { background: 'rgba(183, 154, 122,0.6)', border: 'rgba(183, 154, 122, 0.6)' },
    india_green: { background: 'rgba(1,151,110,0.6)', border: 'rgba(1,151,110,0.6)' },
    erin_green: { background: 'rgba(55,255,0,0.6)', border: 'rgba(55,255,0,0.6)' },
    light_green: { background: 'rgba(143,247,143,0.6)', border: 'rgba(143,247,143,0.6)' },
    mediumpurple: { background: 'rgba(147,112,219,0.85)', background2: 'rgba(147,112,219,0.6)', border: 'mediumpurple' },
    gold: { background: 'rgba(255,215,0,0.85)', background2: 'rgba(255,215,0,0.6)', border: 'gold' },
    royalblue: { background: 'rgba(65,105,225,0.85)', background2: 'rgba(65,105,225,0.6)', border: 'royalblue' },
    blue: { background: 'rgba(33,0,255,0.85)', background2: 'rgba(33,0,255,0.6)', border: 'rgb(33,0,255)' },
    islamic_green: { background: 'rgba(0,180,0,0.85)', background2: 'rgba(0,180,0,0.6)', border: 'rgb(0,180,0)' },
    indianred: { background: 'rgba(205,92,92,0.85)', background2: 'rgba(205,92,92,0.6)', border: 'indianred' },
    coral: { background: 'rgba(255,127,80,0.85)', background2: 'rgba(255,127,80,0.6)', border: 'coral' },
    crimson: { background: 'rgba(220,20,60,0.85)', background2: 'rgba(220,20,60,0.6)', border: 'crimson' },
    cyan: { background: 'rgba(0,255,255,0.85)', background2: 'rgba(0,255,255,0.6)', border: 'cyan' },
    orange: { background: 'rgba(255,118,0,0.85)', background2: 'rgba(255,118,0,0.6)', border: 'rgb(255,118,0)' },
    darkturquoise: { background: 'rgba(0,206,209,0.85)', background2: 'rgba(0,206,209,0.6)', border: 'darkturquoise' },
    dodgerblue: { background: 'rgba(30,144,255,0.85)', background2: 'rgba(30,144,255,0.6)', border: 'dodgerblue' },
    salmon: { background: 'rgba(250,128,114,0.85)', background2: 'rgba(250,128,114,0.6)', border: 'salmon' },
    tomato: { background: 'rgba(255,99,71,0.85)', background2: 'rgba(255,99,71,0.6)', border: 'tomato' },
    black: { background: 'rgba(0,0,0,0.85)', background2: 'rgba(0,0,0,0.6)', border: 'black' },
    darkgray: { background: 'rgba(51,51,51,0.85)', background2: 'rgba(51,51,51,0.6)', border: 'rgb(51,51,51)' },
    grey: { background: 'rgba(90,90,90,0.85)', background2: 'rgba(90,90,90,0.6)', border: 'rgb(90,90,90)' },
    greyX: { background: 'rgba(216,216,216,0.85)', background2: 'rgba(216,216,216,0.6)', border: 'rgb(189,189,189)' },
    lightslategray: { background: 'rgba(119,136,153,0.85)', background2: 'rgba(119,136,153,0.6)', border: 'lightslategray' },
    midnightblue: { background: 'rgba(25,25,112,0.85)', background2: 'rgba(25,25,112,0.6)', border: 'midnightblue' },
    mediumseagreen: { background: 'rgba(60,179,113,0.85)', background2: 'rgba(60,179,113,0.6)', border: 'mediumseagreen' },
    greencrayola: { background: 'rgba(13,171,118,0.85)', background2: 'rgba(13,171,118,0.6)', border: 'rgb(13,171,118)' },
    lightskyblue: { background: 'rgba(135,206,250,0.85)', background2: 'rgba(135,206,250,0.6)', border: 'lightskyblue' },
    brown: { background: 'rgba(182,42,42,0.85)', background2: 'rgba(182,42,42,0.6)', border: 'brown' },
    silver: { background: 'rgba(192,192,192,0.25)', border: 'rgba(192,192,192,0.25)' },
    raisin_black: { background: 'rgba(26,26,26,0.85)', background2: 'rgba(26,26,26,0.6)', border: 'rgb(26,26,26)' },
    orangeNewObj: { background: 'rgba(232, 96, 53,0.85)', background2: 'rgba(232, 96, 53,0.6)', border: 'rgb(232, 96, 53)' },
    usc_gold: 'rgb(255, 205, 7)',
    lavender: 'rgb(170, 135, 222)',
    trolley_grey: 'rgb(128, 128, 128)',
    maximum_blue: 'rgb(55, 171, 200)',
    green: 'rgb(0, 180, 128)',
    red: 'rgb(231, 94, 73)',
    light_blue: 'rgb(0,163,255)',

    levelise: '#028eaf',
    white: '#ffffff',
    blackNew: '#000000',
    gray: '#dddddd',
    lightGreen: '#64b664',
    yellow: '#ffd732',
    orangeNew: '#e86035',
    purple: '#6963aa',
    darkGreen: '#229683',
    darkBlue: '#6695c4',
    lightBlue: '#82cac6',
    successGreen: '#76b82a',
    errorRed: '#a81916',

    grey1: 'rgba(192,192,192,0.7)',
    grey2: 'rgb(192, 192, 192)',
    grey3: 'rgba(128,128,128,0.8)',
    grey4: 'rgb(128,128,128)',

    socColor: '#eeeeee',
}

// ==== STATE OF CHARGE ====
export const upperSoc = {
    type: 'Upper soc',
    backgroundColor: colors.black.background,
    borderColor: colors.black.border
}

export const lowerSoc = {
    type: 'Lower soc',
    backgroundColor: colors.darkgray.background,
    borderColor: colors.darkgray.border
}

export const medianSoc = {
    type: 'Median',
    backgroundColor: colors.purple,
    borderColor: colors.purple
}

export const meanSoc = {
    type: 'Mean',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const ufSoc = {
    type: 'Max',
    backgroundColor: colors.light_green.background,
    borderColor: colors.light_green.border
}

export const lfSoc = {
    type: 'Min',
    backgroundColor: colors.light_green.background,
    borderColor: colors.light_green.border
}

export const maxSoc = {
    type: 'Max',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const minSoc = {
    type: 'Min',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const lqSoc = {
    type: 'Lower Quartile',
    backgroundColor: colors.gray,
    borderColor: colors.gray
}

export const uqSoc = {
    type: 'Upper Quartile',
    backgroundColor: colors.gray,
    borderColor: colors.gray
}

export const ldSoc = {
    type: 'Lower Decile',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
}

export const udSoc = {
    type: 'Upper Decile',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
}

// ==== HW Flow ====
export const medianHwFlow = {
    type: 'Median',
    backgroundColor: colors.purple,
    borderColor: colors.purple
}

export const meanHwFlow = {
    type: 'Mean',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const maxHwFlow = {
    type: 'Max',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const minHwFlow = {
    type: 'Min',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const lqHwFlow = {
    type: 'Lower Quartile',
    backgroundColor: colors.gray,
    borderColor: colors.gray
}

export const uqHwFlow = {
    type: 'Upper Quartile',
    backgroundColor: colors.gray,
    borderColor: colors.gray
}

export const ldHwFlow = {
    type: 'Lower Decile',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
}

export const udHwFlow = {
    type: 'Upper Decile',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
}

// ==== Temperature ====
export const upperTemp = {
    type: 'Upper temp',
    backgroundColor: colors.black.background,
    borderColor: colors.black.border
}

export const lowerTemp = {
    type: 'Lower temp',
    backgroundColor: colors.darkgray.background,
    borderColor: colors.darkgray.border
}

export const medianTemp = {
    type: 'Median',
    backgroundColor: colors.purple,
    borderColor: colors.purple
}

export const meanTemp = {
    type: 'Mean',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const ufTemp = {
    type: 'Max',
    backgroundColor: colors.light_brown.background,
    borderColor: colors.light_brown.border
}

export const lfTemp = {
    type: 'Min',
    backgroundColor: colors.light_brown.background,
    borderColor: colors.light_brown.border
}

export const maxTemp = {
    type: 'Max',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const minTemp = {
    type: 'Min',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const lqTemp = {
    type: 'Lower Quartile',
    backgroundColor: colors.gray,
    borderColor: colors.gray
}

export const uqTemp = {
    type: 'Upper Quartile',
    backgroundColor: colors.gray,
    borderColor: colors.gray
}

export const ldTemp = {
    type: 'Lower Decile',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
}

export const udTemp = {
    type: 'Upper Decile',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
}



// ==== STATS ====
export const allConnected = {
    type: 'Connected DRUs',
    backgroundColor: colors.darkgray.background,
    borderColor: colors.darkgray.border
}

export const allConcentrator = {
    type: 'Concentrator DRUs',
    backgroundColor: colors.royalblue.background,
    borderColor: colors.royalblue.border
}

export const allConcentratorOaas = {
    type: 'Concentrator OaaS DRUs',
    backgroundColor: colors.orange.background,
    borderColor: colors.orange.border
}

export const minConcentratorConnected = {
    type: 'Concentrator Min',
    backgroundColor: colors.greyX.background,
    borderColor: colors.greyX.border
}

export const maxConcentratorConnected = {
    type: 'Concentrator Max',
    backgroundColor: colors.greyX.background,
    borderColor: colors.greyX.border
}

export const meanConcentratorConnected = {
    type: 'Concentrator Mean',
    backgroundColor: colors.lightBlue,
    borderColor: colors.lightBlue
}

export const minConcentratorOaasConnected = {
    type: 'Concentrator OaaS Min',
    backgroundColor: colors.greyX.background,
    borderColor: colors.greyX.border
}

export const maxConcentratorOaasConnected = {
    type: 'Concentrator OaaS Max',
    backgroundColor: colors.greyX.background,
    borderColor: colors.greyX.border
}

export const meanConcentratorOaasConnected = {
    type: 'Concentrator OaaS Mean',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
}

export const minConnected = {
    type: 'Min',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const maxConnected = {
    type: 'Max',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
}

export const meanConnected = {
    type: 'Mean',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const ppm = {
    type: 'PPM',
    backgroundColor: colors.gray,
    borderColor: colors.gray
}

export const ldPpm = {
    type: 'Lower Decile',
    backgroundColor: colors.bronze.background,
    borderColor: colors.bronze.border
}

export const lqPpm = {
    type: 'Lower Quartile',
    backgroundColor: colors.liver_brown.background,
    borderColor: colors.liver_brown.border
}

export const maxPpm = {
    type: 'Max',
    backgroundColor: colors.light_brown.background,
    borderColor: colors.light_brown.border
}

export const meanPpm = {
    type: 'Mean',
    backgroundColor: colors.grey.background2,
    borderColor: colors.grey.border
}

export const medianPpm = {
    type: 'Median',
    backgroundColor: colors.darkgray.background,
    borderColor: colors.darkgray.border
}

export const minPpm = {
    type: 'Min',
    backgroundColor: colors.light_brown.background,
    borderColor: colors.light_brown.border
}

export const uqPpm = {
    type: 'Upper Quartile',
    backgroundColor: colors.liver_brown.background,
    borderColor: colors.liver_brown.border
}

export const udPpm = {
    type: 'Upper Decile',
    backgroundColor: colors.bronze.background,
    borderColor: colors.bronze.border
}

export const concentratorPpm = {
    type: 'Concentrator PPM',
    backgroundColor: colors.purple,
    borderColor: colors.purple
}

export const medianConcentratorPpm = {
    type: 'Concentrator Median',
    backgroundColor: colors.blue.background,
    borderColor: colors.blue.border
}

export const meanConcentratorPpm = {
    type: 'Concentrator Mean',
    backgroundColor: colors.royalblue.background,
    borderColor: colors.royalblue.border
}

export const maxConcentratorPpm = {
    type: 'Concentrator Max',
    backgroundColor: colors.light_green.background,
    borderColor: colors.light_green.border
}

export const minConcentratorPpm = {
    type: 'Concentrator Min',
    backgroundColor: colors.light_green.background,
    borderColor: colors.light_green.border
}

export const lqConcentratorPpm = {
    type: 'Concentrator Lower Quartile',
    backgroundColor: colors.india_green.background,
    borderColor: colors.india_green.border
}

export const uqConcentratorPpm = {
    type: 'Concentrator Upper Quartile',
    backgroundColor: colors.india_green.background,
    borderColor: colors.india_green.border
}

export const ldConcentratorPpm = {
    type: 'Concentrator Lower Decile',
    backgroundColor: colors.erin_green.background,
    borderColor: colors.erin_green.border
}

export const udConcentratorPpm = {
    type: 'Concentrator Upper Decile',
    backgroundColor: colors.erin_green.background,
    borderColor: colors.erin_green.border
}

// ==== LOAD ====
export const toLoad = {
    type: 'To Load',
    backgroundColor: colors.purple,
    backgroundColor2: 'rgba(105,99,170,0.6)',
    borderColor: colors.purple,
    forecastType: 'To Load Forecast',
    forecastColor: 'rgba(105,99,170,0.6)',
};

// ==== PV ====
export const fromPv = {
    type: 'From PV',
    backgroundColor: colors.yellow,
    backgroundColor2: 'rgba(255,215,50,0.6)',
    borderColor: colors.yellow,
    forecastType: 'From PV Forecast',
    forecastColor: 'rgba(255,215,50,0.6)',
};

export const fromDcPvForSummary = {
    type: 'From DC PV',
    backgroundColor: 'rgba(232, 96, 53,0.85)',
    borderColor: '#e86035'
};

export const loss = {
    type: 'DC to AC Loss',
    backgroundColor: colors.black.background,
    backgroundColor2: colors.black.background2,
    borderColor: colors.black.border
};

export const dcPv = {
    type: 'DC From PV',
    backgroundColor: 'rgba(232, 96, 53,0.85)',
    borderColor: '#e86035',
    forecastType: 'From DC PV Forecast',
    forecastColor: 'rgba(232, 96, 53,0.6)',
}

// ==== HWT ====
export const heating = {
    type: 'To Hot Water',
    backgroundColor: colors.errorRed,
    backgroundColor2: 'rgba(168,25,22,0.6)',
    borderColor: colors.errorRed
}
export const consumption = {
    type: 'Consumption',
    backgroundColor: colors.royalblue.background,
    borderColor: colors.royalblue.border
};
export const ambient = {
    type: 'Ambient',
    backgroundColor: colors.gray,
    borderColor: colors.gray,
}
export const outlet = {
    type: 'Outlet',
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed
}
export const upTank7 = {
    type: '7/8 Up Tank',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
};
export const upTank5 = {
    type: '5/8 Up Tank',
    backgroundColor: colors.yellow,
    borderColor: colors.yellow
};
export const upTank3 = {
    type: '3/8 Up Tank',
    backgroundColor: colors.lightGreen,
    borderColor: colors.lightGreen
};
export const upTank1 = {
    type: '1/8 Up Tank',
    backgroundColor: colors.darkGreen,
    borderColor: colors.darkGreen
};
export const inlet = {
    type: 'Inlet',
    backgroundColor: colors.darkBlue,
    borderColor: colors.darkBlue
};

// ==== CONNECTIVITY ====
export const minuteConnectivity = {
    type: 'Minutes',
    backgroundColor: colors.midnightblue.background,
    borderColor: colors.midnightblue.border
}

export const secondConnectivity = {
    type: 'Seconds',
    backgroundColor: colors.dodgerblue.background,
    borderColor: colors.dodgerblue.border
}

export const minuteConnectivityGap = {
    type: 'Max Disconnected Hours',
    backgroundColor: colors.crimson.background,
    borderColor: colors.crimson.border
}

export const secondConnectivityGap = {
    type: 'Max Disconnected Seconds',
    backgroundColor: colors.indianred.background,
    borderColor: colors.indianred.border
}


export const secondHWConnectivity = {
    type: 'Hot Water Minutes',
    backgroundColor: colors.crimson.background,
    borderColor: colors.crimson.border
}

export const secondBSConnectivity = {
    type: 'Battery System Minutes',
    backgroundColor: colors.islamic_green.background,
    borderColor: colors.islamic_green.border
}

export const secondServerConnectivity = {
    type: 'Server Minutes',
    backgroundColor: colors.darkgray.background,
    borderColor: colors.darkgray.border
}

// ==== EV ====
export const ev = {
    type: 'To EV - From EV',
    backgroundColor: colors.blue.background,
    borderColor: colors.blue.border
}

export const toEv = {
    type: 'To EV',
    backgroundColor: colors.blue.background,
    borderColor: colors.blue.border
};

export const fromEv = {
    type: 'From EV',
    backgroundColor: colors.erin_green.background,
    borderColor: colors.erin_green.border
};

// ==== BATTERY ====
export const battery = {
    type: 'To Battery - From Battery',
    backgroundColor: colors.darkBlue,
    borderColor: colors.darkBlue,
    forecastType: 'To Battery - From Battery Forecast',
    forecastColor: 'rgba(102,149,196,0.6)'
}

export const toBattery = {
    type: 'To Battery',
    backgroundColor: colors.darkBlue,
    backgroundColor2: 'rgba(102,149,196,0.6)',
    borderColor: colors.darkBlue,
    forecastType: 'To Battery Forecast',
    forecastColor: 'rgba(102,149,196,0.6)'
};
export const fromBattery = {
    type: 'From Battery',
    backgroundColor: colors.lightBlue,
    backgroundColor2: 'rgba(130,202,198,0.6)',
    borderColor: colors.lightBlue,
    forecastType: 'From Battery Forecast',
    forecastColor: 'rgba(130,202,198,0.6)'
};
export const minBatteryVoltage = {
    type: 'Min Voltage',
    backgroundColor: colors.lightBlue,
    borderColor: colors.lightBlue
};
export const maxBatteryVoltage = {
    type: 'Max Voltage',
    backgroundColor: colors.darkBlue,
    borderColor: colors.darkBlue
};
export const soc = {
    type: 'State of Charge',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
};
export const socEnergy = {
    type: 'State of Charge',
    backgroundColor: colors.socColor,
    borderColor: colors.socColor,
    forecastType: 'SoC Forecast',
    forecastColor: 'rgba(221, 221, 221, 0.6)'
};
export const minBatteryTemperature = {
    type: 'Min Temperature',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
};
export const maxBatteryTemperature = {
    type: 'Max Temperature',
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed
};
export const availableCharge = {
    type: 'Available High',
    backgroundColor: colors.blackNew,
    borderColor: colors.blackNew,
    forecastType: 'Available High Forecast',
    forecastColor: 'rgba(0, 0, 0, 0.6)',
};
export const availableDischarge = {
    type: 'Available Low',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise,
    forecastType: 'Available Low Forecast',
    forecastColor: 'rgba(2, 142, 175, 0.6)',
};
export const maxCharge = {
    type: 'Max Charge',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
};
export const maxDischarge = {
    type: 'Max Discharge',
    backgroundColor: colors.grey2,
    borderColor: colors.grey2
};

export const inverter = {
    type: 'To Inverter - From Inverter',
    backgroundColor: colors.black.background,
    borderColor: colors.black.border
};
export const toInverter = {
    type: 'To Inverter',
    backgroundColor: colors.black.background,
    borderColor: colors.black.border
};
export const fromInverter = {
    type: 'From Inverter',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
};

// ==== DRU STATE ====

export const disconnected = {
    type: 'Disconnected',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const valueState = {
    type: 'Values',
    backgroundColor: colors.successGreen,
    borderColor: colors.successGreen
};

export const loadState = {
    type: 'Load',
    backgroundColor: colors.purple,
    borderColor: colors.purple
};

export const pvState = {
    type: 'PV',
    backgroundColor: colors.yellow,
    borderColor: colors.yellow
};

export const pvMeterState = {
    type: 'PV Meter',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
};

export const hotWaterMeterState = {
    type: 'Hot Water Meter',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
};

export const hotWaterTankState = {
    type: 'Hot Water Tank',
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed
};

export const batteryState = {
    type: 'Battery',
    backgroundColor: colors.darkGreen,
    borderColor: colors.darkGreen
};

export const batteryForceChargingState = {
    type: 'Force Charging',
    backgroundColor: colors.grey3,
    borderColor: colors.grey3
};

export const lowBatteryTemperature = {
    type: 'Too Cold',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
};

export const highBatteryTemperature = {
    type: 'Too Hot',
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed
};

export const inverterState = {
    type: 'Inverter',
    backgroundColor: colors.blackNew,
    borderColor: colors.blackNew
};

export const frequencyMeterState = {
    type: 'Frequency Meter',
    backgroundColor: colors.lightGreen,
    borderColor: colors.lightGreen
};

export const powerMeterState = {
    type: 'Power Meter',
    backgroundColor: colors.lightBlue,
    borderColor: colors.lightBlue
};

// ==== POWER DISPATCH ====
export const dispatchedImport = {
    type: 'Dispatched High',
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed
};
export const dispatchedExport = {
    type: 'Dispatched Low',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
};

export const dispatchedPower = {
    type: 'Dispatched High - Low',
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed
}

// ==== GRID ===

export const grid = {
    type: 'From Grid - To Grid',
    backgroundColor: colors.darkGreen,
    borderColor: colors.darkGreen,
    forecastType: 'From Grid - To Grid Forecast',
    forecastColor: 'rgba(34,150, 131, 0.6)'
}

export const toGrid = {
    type: 'To Grid',
    backgroundColor: colors.lightGreen,
    borderColor: colors.lightGreen,
    forecastType: 'To Grid Forecast',
    forecastColor: 'rgba(100,182, 100, 0.6)'
};
export const fromGrid = {
    type: 'From Grid',
    backgroundColor: colors.darkGreen,
    borderColor: colors.darkGreen,
    forecastType: 'From Grid Forecast',
    forecastColor: 'rgba(34,150, 131, 0.6)'
};
export const toGridStack = {
    type: 'To Grid',
    backgroundColor: colors.lightGreen,
    backgroundColor2: 'rgba(100,182,100,0.6)',
    borderColor: colors.darkgray.border
};
export const fromGridStack = {
    type: 'From Grid',
    backgroundColor: colors.darkGreen,
    backgroundColor2: 'rgba(34,150,131,0.6)',
    borderColor: colors.darkgray.border
};
export const gridFrequency = {
    type: 'Grid Frequency',
    backgroundColor: colors.lightGreen,
    borderColor: colors.lightGreen
};
export const medianGridFrequency = {
    type: 'Median Grid Frequency',
    backgroundColor: colors.lightGreen,
    borderColor: colors.lightGreen
};

export const acVoltage = {
    type: 'AC Voltage',
    backgroundColor: colors.darkBlue,
    borderColor: colors.darkBlue
}

// ==== FLEET ====
export const toFleet = {
    type: 'To Fleet',
    backgroundColor: colors.blackNew,
    backgroundColor2: 'rgba(0,0,0,0.7)',
    borderColor: colors.lightskyblue.border
};
export const fromFleet = {
    type: 'From Fleet',
    backgroundColor: colors.blackNew,
    backgroundColor2: 'rgba(0,0,0,0.7)',
    borderColor: colors.lightskyblue.border
};
export const availableHigh = {
    type: 'Available High',
    backgroundColor: colors.blackNew,
    borderColor: colors.blackNew
};
export const availableLow = {
    type: 'Available Low',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
};

// ==== CONTRACT ====
export const day = {
    start: 7,
    end: 15,
    type: 'Day',
    backgroundColor: colors.yellow,
    borderColor: colors.yellow
};

export const peak = {
    start: 15,
    end: 19,
    type: 'Peak',
    backgroundColor: colors.errorRed,
    borderColor: colors.errorRed
};

export const evening = {
    start: 19,
    end: 23,
    type: 'Evening',
    backgroundColor: colors.orangeNew,
    borderColor: colors.orangeNew
};

export const night = {
    start: 23,
    end: 7,
    type: 'Night',
    backgroundColor: colors.lightGreen,
    borderColor: colors.lightGreen
};


export const contractCurve1 = {
    type: 'Contract',
    backgroundColor: colors.blackNew,
    borderColor: colors.blackNew
};

export const contractCurve2 = {
    type: 'Contract 2',
    backgroundColor: colors.blackNew,
    borderColor: colors.blackNew
};

export const contractCurve1_105 = {
    type: 'Contract 1_105',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
};

export const contractCurve1_95 = {
    type: 'Contract Boundary (±5%)',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
};

export const contractCurve2_105 = {
    type: 'Contract 2_105',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
};

export const contractCurve2_95 = {
    type: 'Contract 2_95',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
};

export const line1 = {
    type: 'Line 1',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const line2 = {
    type: 'Line 2',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const line3 = {
    type: 'Line 3',
    backgroundColor: colors.levelise,
    borderColor: colors.levelise
}

export const tariffImport = {
    type: 'Import',
    backgroundColor: '#229683',
    borderColor: '#229683'
}

export const tariffExport = {
    type: 'Export',
    backgroundColor: '#e86035',
    borderColor: '#e86035'
}
