/* Copyright Levelise Ltd 2024 */
import { useRef, useState } from 'react';

function ClickableListItem({ functionArg, clickHandler, className, children }) {
	const timerRef = useRef(null);
	const [isLongPress, setIsLongPress] = useState(false);

	const startPress = (e) => {
		if (e.button !== 0) {
			return;
		}

		setIsLongPress(false);

		timerRef.current = setTimeout(() => {
			setIsLongPress(true);
		}, 1000);
	};

	const endPress = (e) => {
		if (e.button !== 0) {
			return;
		}
		clearTimeout(timerRef.current);
		if (!isLongPress) {
			clickHandler(functionArg);
		} else {
			clickHandler(functionArg, true);
		}
	};
	return (
		<li
			className={className || ''}
			onMouseDown={(e) => startPress(e)}
			onTouchStart={(e) => startPress(e)}
			onMouseUp={(e) => endPress(e)}
			onTouchEnd={(e) => endPress(e)}
		>
			{children}
		</li>
	);
}

export default ClickableListItem;
