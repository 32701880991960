/* Copyright Levelise Ltd 2020 - 2025 */
import { useState, useEffect, useContext } from 'react';
import { Chart } from 'chart.js';
import FleetContext from '../../contexts/FleetContext';
import FacilityContext from '../../contexts/FacilityContext';
import RangePicker from '../RangePicker/index';
import { TIME_FRAMES, routes } from '../../utils/constants';
import { fleetChartIds } from '../../utils/chart';
import './index.css';

const TimeFrames = ({ timeFrames, route }) => {
	const fleetContext = useContext(FleetContext);
	const facilityContext = useContext(FacilityContext);
	const [options, setOptins] = useState([]);
	const [selectedOption, setSelectedOption] = useState(TIME_FRAMES.twenty_four_hours);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const handleOnClick = (time) => {
		setTimeframe(time);
	};

	const handleOnChange = (e) => {
		setTimeframe(e.target.value);
	};

	const resetZoom = (ids) => {
		const instances = Object.values(Chart.instances).filter((instance) => ids.indexOf(instance.canvas.id) >= 0);
		instances.forEach((ci) => {
			if (ci.getZoomLevel() > 1.0) {
				ci.resetZoom();
			}
		});
	};

	const resetZoomForDifferentDataLength = () => {
		const chartKeys = Object.keys(Chart.instances);
		chartKeys.forEach((chartKey) => {
			for (let i = 0; i < 2; i++) {
				const scale = Chart.instances[chartKey].getInitialScaleBounds();
				const scaleKeys = Object.keys(scale);
				scaleKeys.forEach((key) => {
					Chart.instances[chartKey].zoomScale(key, scale[key]);
				});
			}
		});
	};
	
	const setTimeframe = (time) => {
		if (route === routes.fleet) {
			if (time !== fleetContext.selectedTimeFrame) {
				fleetContext.setSelectedTimeFrame(time);
				setSelectedOption(time);
			} else {
				fleetContext.setResetZoom();
				resetZoom(fleetChartIds);
			}
		}

		if (route.startsWith('facility/')) {
			if (time !== facilityContext.selectedTimeFrame) {
				facilityContext.setSelectedTimeFrame(time);
				setSelectedOption(time);
			} else {
				facilityContext.setResetZoom();
				resetZoomForDifferentDataLength();
			}
		}
	};

	const handleOnDateChange = (start, end) => {
		if (route === routes.fleet) {
			if (fleetContext.startDate !== start || fleetContext.endDate !== end) {
				fleetContext.setStartDate(start);
				fleetContext.setEndDate(end);
			}
		}

		if (route.startsWith('facility/')) {
			if (facilityContext.startDate !== start || facilityContext.endDate !== end) {
				facilityContext.setStartDate(start);
				facilityContext.setEndDate(end);
			}
		}
	};

	const renderTimeFrameList = () => {
		return options.map((time, idx) => (
			<li
				key={idx}
				className={time === selectedOption ? 'selected-timeframe' : ''}
				onClick={() => handleOnClick(time)}
			>
				{time}
			</li>
		));
	};

	const renderTimeFrameOptions = () => {
		return options.map((time, idx) => (
			<option key={idx} value={time}>
				{time}
			</option>
		));
	};

	useEffect(() => {
		if (timeFrames.length) {
			setOptins(timeFrames);
		}
	}, [timeFrames, route]);

	useEffect(() => {
		if (route === routes.fleet) {
			fleetContext.setSelectedTimeFrame(selectedOption);
		}

		if (route.startsWith('facility/')) {
			facilityContext.setSelectedTimeFrame(selectedOption);
		}

		return () => {
			if (route === routes.fleet) {
				fleetContext.setSelectedTimeFrame(TIME_FRAMES.twenty_four_hours);
			}

			if (route.startsWith('facility/')) {
				facilityContext.setSelectedTimeFrame(TIME_FRAMES.twenty_four_hours);
			}
		};
	}, []);

	return (
		<div className="timeframes">
			<ul className="timeframes-list">{renderTimeFrameList()}</ul>
			<div id="select-wrapper">
				<select className="timeframes-options" value={selectedOption} onChange={(e) => handleOnChange(e)}>
					{renderTimeFrameOptions()}
				</select>
			</div>
			{selectedOption === TIME_FRAMES.select && (
				<RangePicker
					handleDateRange={(start, end) => handleOnDateChange(start, end)}
					checked={selectedOption === TIME_FRAMES.select ? false : true}
					setStartDate={(startDate) => setStartDate(startDate)}
					setEndDate={(endDate) => setEndDate(endDate)}
					startDate={startDate}
					endDate={endDate}
				/>
			)}
		</div>
	);
};

export default TimeFrames;
