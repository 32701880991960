/* Copyright Levelise Ltd 2019 - 2025 */
import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { saveAs } from 'file-saver';
import FleetService from '../../services/fleet-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faCircleXmark, faSearch, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import FacilityContext from '../../contexts/FacilityContext';
import DownloadBtn from '../DownloadBtn/index';
import { formatTimestamp, hasPermission } from '../../utils/utils';
import { PERMISSIONS, download, loading, success } from '../../utils/constants';
import { GRID_CONNECTION_TYPE, SETTLEMENT_METHOD } from '../../utils/specification';
import './index.css';
import FleetContext from '../../contexts/FleetContext';

const message = 'Log',
	tests = 'Tests',
	history = 'History';

const passRatingFfr = 0.93;
const passRatingLfs = 0.868;
const passRatingSffr = 0.95;
const passRatingDr = 0.95;
const level0 = [0, 'grey', 'Not set'];
const level10 = [10, 'rgb(102, 149, 196)', 'Debug'];
const level20 = [20, 'rgb(100, 182, 100)', 'Info'];
const level30 = [30, 'rgb(255, 215, 50)', 'Warning'];
const level40 = [40, 'rgb(232, 96, 53)', 'Error'];
const level50 = [50, 'rgb(168, 25, 22)', 'Critical'];

const LogMessages = ({ facilityName }) => {
	const tbodyRef = useRef();

	const fleetContext = useContext(FleetContext);
	const facilityContext = useContext(FacilityContext);

	const [view, setView] = useState(message);
	const [downloadState, setDownloadState] = useState(download);
	const [logs, setLogs] = useState([]);
	const [extend, setExtend] = useState(false);
	const [update, setUpdate] = useState(false);
	const [newLogs, setNewLogs] = useState([]);
	const [qualifications, setQualifications] = useState({});
	const [canAccessQualifications, setCanAccessQualifications] = useState(false);
	const [searchTerm, setSearchTerm] = useState(null);
	const [filterTerm, setFilterTerm] = useState(null);
	const [type, setType] = useState('');
	const [change, setChange] = useState('');
	// const [changes, setChanges] = useState([]);
	const [level, setLevel] = useState(null);
	const [refreshing, setRefreshing] = useState(false);

	const handleClickDownload = () => {
		if (downloadState === download && !!logs.length) {
			setDownloadState(loading);
			try {
				const timezone = facilityContext.facility.timezone;
				let csvContent = 'Date,Level,Type,Message\r\n';
				for (let i = 0; i < logs.length; i++) {
					const date = formatTimestamp(logs[i].timestamp, timezone, 'dd/MM/yyyy HH:mm:ss ');
					csvContent += `${date},${logs[i].level},${logs[i].msgType},'${logs[i].msg}'\r\n`;
				}

				const start = formatTimestamp(logs[logs.length - 1].timestamp, timezone, "dd/MM/yy'T'HH:mm:ss");
				const end = formatTimestamp(logs[0].timestamp, timezone, "dd/MM/yy'T'HH:mm:ss");
				const filename = `${facilityName}_logs_from_${start}_to_${end}.csv`;
				const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' });

				saveAs(blob, filename);
			} catch {
				setDownloadState(download);
			} finally {
				setDownloadState(success);
				setTimeout(() => setDownloadState(download), 2000);
			}
		}
	};

	const onScroll = () => {
		if (tbodyRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = tbodyRef.current;
			if (scrollTop + clientHeight >= scrollHeight) {
				if (logs.length) {
					setExtend(true);
					setUpdate(false);
					fetchLogMessages(true);
				}
			}
		}
	};

	const fetchLogMessages = (extend = false) => {
		const params = {
			type: type ? type : null,
			'min-level': level ? level : null,
		};

		if (extend && !!logs.length) {
			params['before'] = logs[logs.length - 1]['timestamp'] + 1;
		}

		FleetService.getLogMessagesForFacility(facilityName, params)
			.then((res) => setNewLogs(res))
			.catch(facilityContext.setError);
	};

	const updateState = (newLogs, extend, update) => {
		if ((update || extend) && !!logs.length) {
			if (update) {
				const fLogTimestamp = logs[0]['timestamp'];
				const fLogs = logs.filter((log) => log['timestamp'] === fLogTimestamp);
				const logsToAdd = newLogs.filter((log) =>
					log['timestamp'] === fLogTimestamp
						? !fLogs.some((ll) => deepEqual(ll, log))
						: log['timestamp'] > fLogTimestamp
				);
				setLogs([...logsToAdd, ...logs]);
			}

			if (extend) {
				const lLogTimestamp = logs[logs.length - 1]['timestamp'];
				const lLogs = logs.filter((log) => log['timestamp'] === lLogTimestamp);
				const logsToAdd = newLogs.filter((log) =>
					log['timestamp'] === lLogTimestamp
						? !lLogs.some((ll) => deepEqual(ll, log))
						: log['timestamp'] < lLogTimestamp
				);
				setLogs([...logs, ...logsToAdd]);
			}
		} else {
			setLogs(newLogs);
		}

		if (refreshing) {
			setRefreshing(false);
		}
	};

	const deepEqual = (object1, object2) => {
		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			const val1 = object1[key];
			const val2 = object2[key];
			const areObjects = isObject(val1) && isObject(val2);
			if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
				return false;
			}
		}

		return true;
	};

	const isObject = (object) => {
		return object != null && typeof object === 'object';
	};

	const handleLogOnClick = () => {
		if (type.length) {
			setSearchTerm(type);
		} else {
			setSearchTerm(null);
		}
	};

	const handleLogOnChange = (value) => {
		if (!value.length) {
			setType(value);
			return;
		}

		const typesArr = value.split(',').filter((t) => !!t);
		if (typesArr.some((t) => isNaN(t) || !Number.isInteger(Number(t)))) return;

		setType(value);
	};

	const handleChangeOnClick = () => {
		if (change.length) {
			setFilterTerm(change);
		} else {
			setFilterTerm(null);
		}
	};

	const handleChangeOnChange = (value) => {
		setChange(value);
		if (!value.length) {
			setFilterTerm(value);
		}
	};
	const handleOnClickLevel = (value) => {
		if (value === level) {
			setLevel(null);
		} else {
			setLevel(value);
		}
	};

	const renderLogMessages = useCallback(() => {
		const list = facilityContext.selectedTimezone.split(' | ');
		const selectedTimezone = list[list.length - 1].trim();
		
		if (logs.length) {
			return logs.map((log, idx) => {
				const date = formatTimestamp(log.timestamp, selectedTimezone || 'Europe/London', 'dd/MM/yy');
				const time = formatTimestamp(log.timestamp, selectedTimezone || 'Europe/London', 'HH:mm:ss');
				return (
					<tr key={idx} width="100%">
						<td className="col-1">
							<FontAwesomeIcon icon={faCircle} color={generateColorCode(log.level)} size="sm" />
							<br />
							{log.msgType}
						</td>
						<td className="col-2">
							{date}
							<br />
							{time}
						</td>
						<td className="col-3 left-data">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span
									style={{
										fontSize: '0.75rem',
									}}
								>
									{log?.msgDescription || ''}
								</span>
								<span
									style={{
										fontSize: '0.6rem',
									}}
								>
									{log.msg}
								</span>
							</div>
						</td>
					</tr>
				);
			});
		}
	}, [logs, facilityContext.selectedTimezone]);

	const getNextType = (type, curIndex, previously) => {
		if (curIndex === 0) {
			return facilityContext.facility;
		}
		// else...
		for (let i = curIndex - 1; i >= 0; i--) {
			if (previously[i] && Object.hasOwn(previously[i], type)) return previously[i];
		}
		// else...
		return facilityContext.facility;
	};

	const isValid = (value) => {
		if (value === null || value === undefined) return false;
		return true;
	};

	const getSettlementMethodDescription = (settlementMethod) => {
		if (typeof settlementMethod === 'string' || settlementMethod instanceof String) {
			const id = Object.keys(SETTLEMENT_METHOD).find((key) => SETTLEMENT_METHOD[key].includes(settlementMethod));
			return id ? `${settlementMethod} (${id})` : settlementMethod;
		} else {
			return Object.hasOwn(SETTLEMENT_METHOD, settlementMethod)
				? SETTLEMENT_METHOD[settlementMethod]
				: settlementMethod;
		}
	};
	const getSettlementMethodChanges = (type, curIndex, previously) => {
		const value = previously[curIndex][type];
		const next = getNextType(type, curIndex, previously);
		if (!isValid(value) && (!isValid(next) || !isValid(next[type]))) return [`Settlement Method is not set.`];
		if (!isValid(value)) return [`Settlement Method set to ${getSettlementMethodDescription(next[type])}.`];
		if (!isValid(next) || !isValid(next[type]))
			return [`Removed Settlement Method ${getSettlementMethodDescription(value)}.`];
		return [
			`Settlement Method changed from ${getSettlementMethodDescription(
				value
			)} to ${getSettlementMethodDescription(next[type])}.`,
		];
	};

	const getGridConnectionChanges = (type, curIndex, previously) => {
		const value = previously[curIndex][type];
		const next = getNextType(type, curIndex, previously);
		const list = [];
		if (!isValid(value) && (!isValid(next) || !isValid(next[type]))) {
			list.push(`TNO is not set.`);
			list.push(`DNO is not set.`);
			list.push(`Connection Type is not set..`);
			return list;
		}
		// else...
		if (!isValid(value)) {
			list.push(`TNO set to ${next[type].connectionTo}.`);
			list.push(
				`DNO set to ${
					next[type].id === 1 || next[type].id === 2 ? 'Unknown' : GRID_CONNECTION_TYPE[next[type].id]
				}.`
			);
			list.push(`Connection Type set to ${next[type].connectionType}.`);
			return list;
		}
		// else...
		if (!isValid(next) || !isValid(next[type])) {
			list.push(`Removed from TNO ${value.connectionTo}.`);
			list.push(
				`Removed from DNO ${value.id === 1 || value.id === 2 ? 'Unknown' : GRID_CONNECTION_TYPE[value.id]}.`
			);
			list.push(`Removed from Connection Type ${value.connectionType}.`);
			return list;
		}
		// else...
		if (next[type].connectionTo !== value.connectionTo)
			list.push(`TNO changed from ${value.connectionTo} to ${next[type].connectionTo}.`);
		if (next[type].dno !== value.dno)
			list.push(
				`DNO changed from ${value.id === 1 || value.id === 2 ? 'Unknown' : GRID_CONNECTION_TYPE[value.id]} to ${
					next[type].id === 1 || next[type].id === 2 ? 'Unknown' : GRID_CONNECTION_TYPE[next[type].id]
				}.`
			);
		if (next[type].connectionType !== value.connectionType)
			list.push(`Connection Type changed from ${value.connectionType} to ${next[type].connectionType}.`);
		return list;
	};

	const getTariffChanges = (type, curIndex, previously) => {
		const value = previously[curIndex][type];
		const next = getNextType(type, curIndex, previously);
		const list = [];
		if (!isValid(value) && (!isValid(next) || !isValid(next[type]))) {
			list.push(`Tariff is not set.`);
			return list;
		}
		// else...
		if (!isValid(value)) {
			list.push(`Tariff set to ${next[type].name}.`);
			list.push(`Tariff details set to ${next[type].details}.`);
			list.push(`Tariff type set to ${next[type].type.description}.`);
			list.push(`Tariff supplier set to ${next[type].supplier.name}.`);
			list.push(`Tariff export rate set to ${next[type].exportRatePerKwh}p/kWh.`);
			list.push(`Tariff day rate set to ${next[type].dayRatePerKwh}p/kWh.`);
			list.push(`Tariff night rate set to ${next[type].nightRatePerKwh}p/kWh.`);
			list.push(`Tariff night start minute set to ${next[type].nightStartMinute}.`);
			list.push(`Tariff night end minute set to ${next[type].nightEndMinute}.`);
			list.push(`Tariff daily standing charge set to ${next[type].dailyStandingCharge}p.`);
			return list;
		}
		// else...
		if (!isValid(next) || !isValid(next[type])) {
			list.push(`Removed Tariff ${value.name}.`);
			return list;
		}
		// else...
		if (next[type].name !== value.name) list.push(`Tariff changed from ${value.name} to ${next[type].name}.`);
		if (next[type].details !== value.details)
			list.push(`Tariff details changed from ${value.details} to ${next[type].details}.`);
		if (next[type].type.id !== value.type.id)
			list.push(`Tariff type changed from ${value.type.description} to ${next[type].type.description}.`);
		if (next[type].supplier.id !== value.supplier.id)
			list.push(`Tariff supplier changed from ${value.supplier.name} to ${next[type].supplier.name}.`);
		if (next[type].exportRatePerKwh !== value.exportRatePerKwh)
			list.push(
				`Tariff export rate changed from ${value.exportRatePerKwh}p/kWh to ${next[type].exportRatePerKwh}p/kWh.`
			);
		if (next[type].dayRatePerKwh !== value.dayRatePerKwh)
			list.push(`Tariff day rate changed from ${value.dayRatePerKwh}p/kWh to ${next[type].dayRatePerKwh}p/kWh.`);
		if (next[type].nightRatePerKwh !== value.nightRatePerKwh)
			list.push(
				`Tariff night rate changed from ${value.nightRatePerKwh}p/kWh to ${next[type].nightRatePerKwh}p/kWh.`
			);
		if (next[type].nightStartMinute !== value.nightStartMinute)
			list.push(
				`Tariff night start minute changed from ${value.nightStartMinute} to ${next[type].nightStartMinute}.`
			);
		if (next[type].nightEndMinute !== value.nightEndMinute)
			list.push(`Tariff night end minute changed from ${value.nightEndMinute} to ${next[type].nightEndMinute}.`);
		if (next[type].dailyStandingCharge !== value.dailyStandingCharge)
			list.push(
				`Tariff daily standing charge changed from ${value.dailyStandingCharge}p to ${next[type].dailyStandingCharge}p.`
			);
		return list;
	};

	const getAggregatedFacilityChanges = (type, curIndex, previously) => {
		const value = previously[curIndex][type];
		const next = getNextType(type, curIndex, previously);
		if (!isValid(value) && (!isValid(next) || !isValid(next[type]))) return [`Not added.`];
		if (!isValid(value)) return [`Added to ${next[type].name}.`];
		if (!isValid(next) || !isValid(next[type])) return [`Removed from ${value.name}.`];
		return [`Changed from ${value.name} to ${next[type].name}.`];
	};

	const getChanges = (changeName, type, curIndex, previously, isBool = false, useName = false, unit = '') => {
		const value = previously[curIndex][type];
		const next = getNextType(type, curIndex, previously);
		if (useName) {
			if (!isValid(value) && (!isValid(next) || !isValid(next[type]))) return [`${changeName} is not set.`];
			if (!isValid(value)) return [`${changeName} set to ${next[type].name}.`];
			if (!isValid(next) || !isValid(next[type])) return [`Removed ${changeName} ${value.name}.`];
			return [`${changeName} changed from ${value.name} to ${next[type].name}.`];
		}
		// else...
		if (isBool) {
			if (!isValid(value) && (!isValid(next) || !isValid(next[type]))) return [`${changeName} is not set.`];
			if (!isValid(value)) return [`${changeName} set to ${next[type] ? 'Yes' : 'No'}.`];
			if (!isValid(next) || !isValid(next[type])) return [`Removed ${changeName} ${value ? 'Yes' : 'No'}.`];
			return [`${changeName} changed from ${value ? 'Yes' : 'No'} to ${next[type] ? 'Yes' : 'No'}.`];
		}
		// else...
		if (!isValid(value) && (!isValid(next) || !isValid(next[type]))) return [`${changeName} is not set.`];
		if (!isValid(value)) return [`${changeName} set to ${next[type]}${unit}.`];
		if (!isValid(next) || !isValid(next[type])) return [`Removed ${changeName} ${value}${unit}.`];
		return [`${changeName} changed from ${value}${unit} to ${next[type]}${unit}.`];
	};

	const getFacilityChanges = (type, curIndex, previously) => {
		switch (
			type // 79000040
		) {
			case 'druId':
				return getChanges('DRU ID', type, curIndex, previously);
			case 'batterySystem':
				return getChanges('Battery System', type, curIndex, previously, false, true);
			case 'hotWaterTank':
				return getChanges('HW Tank', type, curIndex, previously, false, true);
			case 'druNetwork': // DRU Network --
				return getChanges('DRU Network', type, curIndex, previously, false, true);
			case 'batteryLocation': // Battery Location --
				return getChanges('Battery Location', type, curIndex, previously, false, true);
			case 'pvNetCapacityW':
				return getChanges('PV Net Capacity', type, curIndex, previously, false, false, 'W');
			case 'postcode': // Postcode --
				return getChanges('Postcode', type, curIndex, previously);
			case 'gridConnection':
				return getGridConnectionChanges(type, curIndex, previously);
			case 'timezone':
				return getChanges('Timezone', type, curIndex, previously);
			case 'importLimitW': // Import Limit ---
				return getChanges('Import Limit', type, curIndex, previously, false, false, 'W');
			case 'exportLimitW':
				return getChanges('Export Limit', type, curIndex, previously, false, false, 'W');
			case 'lineLossFactor':
				return getChanges('Line Loss Factor', type, curIndex, previously);
			case 'aggregatedFacility':
				return getAggregatedFacilityChanges(type, curIndex, previously, false, true);
			// case 'settlementMethodId':
			case 'settlementMethod':
				return getSettlementMethodChanges(type, curIndex, previously);
			case 'rs485': // RS-485 ---
				return getChanges('RS-485 Ports', type, curIndex, previously);
			case 'has3PhaseMetering': // Supply Phases (Has 3-Phase Metering)
				return getChanges('Has 3-Phase Metering', type, curIndex, previously, true);
			case 'supplyPhases': //
				return getChanges('Supply Phases', type, curIndex, previously);
			case 'drAllowed': // Data Collection Allowed (DR Allowed)
				return getChanges('DR Allowed', type, curIndex, previously, true);
			case 'dataCollectionAllowed': //
				return getChanges('Data Collection Allowed', type, curIndex, previously, true);
			case 'hasGridMeter': // Has Grid Metering
				return getChanges('Has Grid Metering', type, curIndex, previously, true);
			case 'invertPv': // Invert Pv--
				return getChanges('Invert Pv', type, curIndex, previously, true);
			case 'invertGrid': // Invert Grid--
				return getChanges('Invert Grid', type, curIndex, previously, true);
			case 'invertDruInverter': // Invert Dru Inverter--
				return getChanges('Invert Dru Inverter', type, curIndex, previously, true);
			case 'invertDruGrid': // Invert Dru Grid--
				return getChanges('Invert Dru Grid', type, curIndex, previously, true);
			case 'invertDruHotWater': // Invert Dru Hot Water--
				return getChanges('Invert Dru Hot Water', type, curIndex, previously, true);
			case 'tariff': // Tariff---
				return getTariffChanges(type, curIndex, previously);
			case 'evChargePointId':
				return getChanges('EV Charge Point ID', type, curIndex, previously);
			default:
				return [];
		}
	};

	const renderHistory = () => {
		let changes = [];
		if (Object.keys(facilityContext.facility).length) {
			const list = facilityContext.selectedTimezone.split(' | ');
			const selectedTimezone = list[list.length - 1].trim();
			if (facilityContext.facility && Object.hasOwn(facilityContext.facility, 'previously')) {
				const previously = facilityContext.facility.previously;
				for (let i = 0; i < previously.length; i++) {
					const afChanges = [],
						tariffChanges = [],
						specChanges = [],
						evChanges = [];
					for (const key in previously[i]) {
						if (key === 'aggregatedFacility') afChanges.push(...getFacilityChanges(key, i, previously));
						else if (key === 'tariff') tariffChanges.push(...getFacilityChanges(key, i, previously));
						else if (key === 'evChargePointId') evChanges.push(...getFacilityChanges(key, i, previously));
						else specChanges.push(...getFacilityChanges(key, i, previously));
					}
					if (afChanges.length) changes.push([previously[i].timestampSec, 'AF', afChanges]);
					if (tariffChanges.length) changes.push([previously[i].timestampSec, 'Tariff', tariffChanges]);
					if (evChanges.length) changes.push([previously[i].timestampSec, 'EV', evChanges]);
					if (specChanges.length) changes.push([previously[i].timestampSec, 'Spec', specChanges]);
				}
			}

			changes.push([facilityContext.facility.createdTimestampSec, 'Facility', ['Created.']]);
			if (filterTerm) {
				changes = changes
					.map((v) => {
						v[2] = v[2].filter((e) => e.toLowerCase().includes(filterTerm.toLowerCase()));
						return v;
					})
					.filter((v) => !!v[2].length);
			}

			return changes.map((v, i) => {
				const date = formatTimestamp(v[0], selectedTimezone, 'dd/MM/yy');
				const time = formatTimestamp(v[0], selectedTimezone, 'HH:mm:ss');
				return (
					<tr key={i} width="100%">
						<td className="col-1">
							<FontAwesomeIcon icon={faCircle} color={level0[1]} size="sm" />
							<br />
							{v[1]}
						</td>
						<td className="col-2">
							{date}
							<br />
							{time}
						</td>
						<td className="col-3 left-data">
							{v[2].map((v, j) => (
								<>
									{v}
									<br key={j} />
								</>
							))}
						</td>
					</tr>
				);
			});
		}
	};

	const generateColorCode = (msgLevel) => {
		if (msgLevel < level20[0]) return level10[1];
		else if (level20[0] <= msgLevel && msgLevel < level30[0]) return level20[1];
		else if (level30[0] <= msgLevel && msgLevel < level40[0]) return level30[1];
		else if (level40[0] <= msgLevel && msgLevel < level50[0]) return level40[1];
		else if (level50[0] <= msgLevel) return level50[1];
		else return level0[1];
	};

	const qualIcon = (success) => {
		if (success) {
			return <FontAwesomeIcon icon={faCircleCheck} color={level20[1]} size="sm" />;
		}
		return <FontAwesomeIcon icon={faCircleXmark} color={level50[1]} size="sm" />;
	};

	const renderTests = () => {
		let bmPassed = false,
			bmAccepted = false,
			ffrPassed = false,
			ffrAccepted = false,
			sffrPassed = false,
			sffrAccepted = false,
			drPassed = false,
			drAccepted = false;
		let date = '',
			time = '';
		const list = facilityContext.selectedTimezone.split(' | ');
		const selectedTimezone = list[list.length - 1].trim();
		const content = [];
		if (Object.keys(qualifications).length) {
			bmPassed = qualifications['bm']['passed'];
			bmAccepted = qualifications['bm']['accepted'];
			ffrPassed = qualifications['ffr']['passed'];
			ffrAccepted = qualifications['ffr']['accepted'];
			sffrPassed = qualifications['sffr']['passed'];
			sffrAccepted = qualifications['sffr']['accepted'];
			drPassed = qualifications['dr']['passed'];
			drAccepted = qualifications['dr']['accepted'];
			const timestampSec = qualifications['changedTimestampSec'];
			date = formatTimestamp(timestampSec, selectedTimezone, 'dd/MM/yy');
			time = formatTimestamp(timestampSec, selectedTimezone, 'HH:mm:ss');
		}

		content.push(
			<tr key={0} width="100%">
				<td key={0} className="col-1">
					<FontAwesomeIcon icon={faCircle} color={level0[1]} size="sm" />
					<br />
					Qual
				</td>
				<td key={1} className="col-2">
					{date}
					<br />
					{time}
				</td>
				<td key={2} className="col-3 left-data">
					<div className="qual-wrapper">
						<div key={0} className="qual-row">
							<span className="qual-col1"></span>
							<span className="qual-col2">Passed</span>
							<span className="qual-col3">Accepted</span>
						</div>
						<div key={1} className="qual-row">
							<span className="qual-col1">BM</span>
							<span className="qual-col2">{qualIcon(bmPassed)}</span>
							<span className="qual-col3">{qualIcon(bmAccepted)}</span>
						</div>
						<div key={2} className="qual-row">
							<span className="qual-col1">FFR</span>
							<span className="qual-col2">{qualIcon(ffrPassed)}</span>
							<span className="qual-col3">{qualIcon(ffrAccepted)}</span>
						</div>
						<div key={3} className="qual-row">
							<span className="qual-col1">SFFR</span>
							<span className="qual-col2">{qualIcon(sffrPassed)}</span>
							<span className="qual-col3">{qualIcon(sffrAccepted)}</span>
						</div>
						<div key={4} className="qual-row">
							<span className="qual-col1">DR</span>
							<span className="qual-col2">{qualIcon(drPassed)}</span>
							<span className="qual-col3">{qualIcon(drAccepted)}</span>
						</div>
					</div>
				</td>
			</tr>
		);

		if (qualifications && Object.hasOwn(qualifications, 'scores') && !!qualifications['scores'].length) {
			qualifications['scores']
				.sort((a, b) => b.testTimestampSec - a.testTimestampSec)
				.forEach((score, i) => {
					const testTimestampSec = score['testTimestampSec'];
					const modifiedTimestampSec = score['modifiedTimestampSec'];
					const modifiedDate = formatTimestamp(modifiedTimestampSec, selectedTimezone, 'dd/MM/yy');
					const modifiedTime = formatTimestamp(modifiedTimestampSec, selectedTimezone, 'HH:mm:ss');
					const testDate = formatTimestamp(testTimestampSec, selectedTimezone, 'dd/MM/yy');
					const testKeys = [
						'ffr11',
						'ffr12',
						'ffr21',
						'ffr22',
						'ffr',
						'lfs',
						'sffr',
						'dr11',
						'dr12',
						'dr21',
						'dr22',
						'drTotal',
						'dr',
					];
					const testNames = [
						'FFR 1.1',
						'FFR 1.2',
						'FFR 2.1',
						'FFR 2.2',
						'FFR',
						'LFS',
						'SFFR',
						'DR 1.1',
						'DR 1.2',
						'DR 2.1',
						'DR 2.2',
						'DR Total',
						'DR',
					];
					const results = [
						<>
							{`Started DRU ${score['druId']} test on ${testDate}`}
							<br key={0} />
						</>,
					];
					let passedCount = 0,
						failedCount = 0;
					testKeys.forEach((key, j) => {
						if (score[key] !== null && score[key] !== undefined) {
							if (['ffr', 'lfs', 'sffr', 'dr'].includes(key)) {
								let passingRate;
								if ('ffr' === key) passingRate = passRatingFfr;
								else if ('lfs' === key) passingRate = passRatingLfs;
								else if ('sffr' === key) passingRate = passRatingSffr;
								else passingRate = passRatingDr;
								if (score[key] >= passingRate) {
									results.push(
										<>
											{testNames[j]} test score:{' '}
											<span style={{ color: level20[1] }}>{score[key]}</span>
											<br />
										</>
									);
									passedCount++;
								} else {
									results.push(
										<>
											{testNames[j]} test score:{' '}
											<span style={{ color: level50[1] }}>{score[key]}</span>
											<br />
										</>
									);
									failedCount++;
								}
							} else {
								results.push(
									<>
										{testNames[j]} test score: {score[key]}
										<br />
									</>
								);
							}
						}
					});

					const scoreColor =
						passedCount > 0 && failedCount > 0
							? level40[1]
							: passedCount > 0
							? level20[1]
							: failedCount > 0
							? level50[1]
							: level0[1];
					content.push(
						<tr key={i + 1} width="100%">
							<td key={0} className="col-1">
								<FontAwesomeIcon icon={faCircle} color={scoreColor} size="sm" />
								<br />
								Score
							</td>
							<td key={1} className="col-2">
								<>{modifiedDate}</>
								<br />
								<>{modifiedTime}</>
							</td>
							<td key={2} className="col-3 left-data">
								{results}
							</td>
						</tr>
					);
				});
		}

		return content;
	};

	useEffect(() => {
		let interval;
		if (!!facilityName && !fleetContext.tokenUpdating) {
			setExtend(false);
			setUpdate(false);
			fetchLogMessages();
			interval = setInterval(() => {
				setExtend(false);
				setUpdate(true);
				fetchLogMessages(false);
			}, 300000);
		}

		return () => clearInterval(interval);
	}, [facilityName, fleetContext.tokenUpdating, searchTerm, level]);

	useEffect(() => {
		if (refreshing) {
			setExtend(false);
			setUpdate(true);
			fetchLogMessages(false);
		}
	}, [refreshing]);

	useEffect(() => {
		updateState(newLogs, extend, update);
	}, [newLogs]);

	useEffect(() => {
		if (!type && type === '' && searchTerm !== null) {
			setSearchTerm(null);
		}
	}, [type]);

	useEffect(() => {
		setSearchTerm(null);
		setFilterTerm(null);
		setType('');
		setChange('');
	}, [view]);

	useEffect(() => {
		if (hasPermission(PERMISSIONS.CAN_ACCESS_QUALIFICATIONS) && !fleetContext.tokenUpdating) {
			setCanAccessQualifications(true);
			FleetService.getFacilityQualifications(facilityName)
				.then(setQualifications)
				.catch(facilityContext.setError);
		}
	}, [fleetContext.tokenUpdating, facilityName]);

	return (
		<div id="tableContainer" className="tableContainer">
			<div
				style={{
					display: 'block',
				}}
			>
				<h2 className="log-header">
					<span
						id="log-message"
						className={view === message ? 'log-view' : ''}
						onClick={() => setView(message)}
					>
						{message}
					</span>
					{canAccessQualifications && (
						<span
							id="log-tests"
							className={view === tests ? 'log-view' : ''}
							onClick={() => setView(tests)}
						>
							{tests}
						</span>
					)}
					<span
						id="log-history"
						className={view === history ? 'log-view' : ''}
						onClick={() => setView(history)}
					>
						{history}
					</span>
				</h2>
				<h2 className="log-header-selector">
					<select
						className=""
						name="views"
						onChange={(e) => {
							setView(e.target.value);
						}}
					>
						<option id="log-message" className={view === message ? 'log-view' : ''} value={message}>
							{message}
						</option>
						{canAccessQualifications && (
							<option id="log-tests" className={view === tests ? 'log-view' : ''} value={tests}>
								{tests}
							</option>
						)}
						<option id="log-history" className={view === history ? 'log-view' : ''} value={history}>
							{history}
						</option>
					</select>
				</h2>
			</div>
			<div
				className="searchContainer"
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<div className="search" style={{ right: 3 }}>
					{view === message && (
						<div className="levelOptions">
							<FontAwesomeIcon
								className="logHeaderIcon"
								onClick={() => setRefreshing(true)}
								spin={refreshing}
								icon={faSyncAlt}
								style={{
									color: refreshing ? '#dddddd' : '#000000',
									cursor: 'pointer',
									padding: '1px',
								}}
							/>
							{[level10, level20, level30, level40, level50].map((ll) => (
								<FontAwesomeIcon
									className="logHeaderIcon"
									key={ll[0]}
									onClick={() => handleOnClickLevel(ll[0])}
									style={{
										fontSize: !!level && ll[0] === level ? 15 : 11,
										cursor: 'pointer',
									}}
									icon={faCircle}
									color={ll[1]}
								/>
							))}
						</div>
					)}
					<div
						style={{
							width: view === history ? '140px' : '230px',
							display: 'flex',
						}}
					>
						{(view === message || view === history) && (
							<input
								type="text"
								className="searchType"
								placeholder={view === history ? 'Search...' : 'Log type(s)'}
								value={view === history ? change : type}
								onChange={(e) =>
									view === history
										? handleChangeOnChange(e.target.value)
										: handleLogOnChange(e.target.value)
								}
							/>
						)}
						{(view === message || view === history) && (
							<button
								type="button"
								className="searchTypeButton"
								onClick={() => (view === history ? handleChangeOnClick() : handleLogOnClick())}
							>
								<FontAwesomeIcon icon={faSearch} size="sm" />
							</button>
						)}
					</div>
					{view === message && (
						<DownloadBtn downloadState={downloadState} clickDownload={handleClickDownload} />
					)}
				</div>
			</div>
			<table border="0" cellPadding="0" cellSpacing="0" width="100%" className="scrollTable">
				<thead className="fixedHeader">
					<tr>
						<th className="col-1 col-name">Type</th>
						<th className="col-2 col-name">Time</th>
						<th className="col-3 col-name">
							{view === message ? 'Message' : view === tests ? 'Result' : 'Change'}
						</th>
					</tr>
				</thead>
				<tbody ref={tbodyRef} className="scrollContent" onScroll={() => onScroll()}>
					{view === message && renderLogMessages()}
					{view === tests && renderTests()}
					{view === history && renderHistory()}
				</tbody>
			</table>
		</div>
	);
};

export default LogMessages;
