/* Copyright Levelise Ltd 2025 */
import React, { useCallback, useContext } from 'react';
import TabContainer from '../TabContainer';
import './index.css';
import { useNavigate } from 'react-router-dom';
import ClickableListItem from '../ClickableListItem';
import FleetCustomersContext from '../../contexts/FleetCustomersContext';

const CustomersIdList = ({ customerIds }) => {
	const navigate = useNavigate();

	const onCustomerClicked = useCallback(
		(customerId, onNewTab = false) => {
			if (customerId) {
				if (onNewTab) {
					window.open(`/customer/${customerId}`, '_blank');
				} else {
					navigate(`/customer/${customerId}`);
				}
			}
		},
		[navigate]
	);

	return (
		<ul className="customers-id-list">
			{customerIds && customerIds.length ? (
				<>
					{customerIds?.map((ele, i) => {
						return (
							<ClickableListItem
								key={i}
								className="customers-customers-id"
								functionArg={ele}
								clickHandler={onCustomerClicked}
							>
								<span>{ele}</span>
							</ClickableListItem>
						);
					})}
				</>
			) : null}
			<li
				key={'last-customer-id'}
				className="customers-customers-id list-end-indicator"
			>
				<span></span>
			</li>
		</ul>
	);
};

function CustomerIdsList() {
	const fleetCustomersContext = useContext(FleetCustomersContext);

	return (
		<TabContainer
			filterIcon
			title="Customer ID"
			containerStyle={{
				borderWidth: 1,
				borderColor: 'rgba(187, 187, 187, 0.33)',
				borderStyle: 'solid',
				borderRadius: 3,
			}}
			titleStyle={{ textWrap: 'nowrap' }}
		>
			<div className="customers-id-list-container">
				<CustomersIdList customerIds={fleetCustomersContext.filteredCustomerIds} />
			</div>
		</TabContainer>
	);
}

export default CustomerIdsList;
