/* Copyright Levelise Ltd 2019-2025 */
import { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getTimezoneOffset } from 'date-fns-tz';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import FleetContext from '../../contexts/FleetContext';
import FacilityContext from '../../contexts/FacilityContext';
import useOutsideClick from './outsideClick';
import profileIcon from '../../assets/icons/ProfileIcon.png';
import TimeFrames from '../TimeFrameOptions';
import UserService from '../../services/user-service';
import { FACILITY_TIME_FRAMES, FLEET_TIME_FRAMES, routes, PERMISSIONS, fleet } from '../../utils/constants';
import { hasPermission } from '../../utils/utils';
import './index.css';
import UserContext from '../../contexts/UserContext';
import FleetStatusContext from '../../contexts/FleetStatusContext';
import IdleService from '../../services/idle-service';
import TokenService from '../../services/token-service';
import FleetIcon from '../../assets/icons/FleetIcon';
import OrdersIcon from '../../assets/icons/clipboardIcon.svg';
import FacilityIcon from '../../assets/icons/FacilityIcon';
import { useAlert } from '../Alert';
import FleetService from '../../services/fleet-service';
import CustomerIcon from '../../assets/icons/CustomerIcon';

const customStyles = {
	menu: (provided) => ({
		...provided,
		marginTop: 4,
		marginBottom: 0,
		padding: 0,
	}),
	container: (provided) => ({
		...provided,
		minHeight: 1,
		textAlign: 'left',
		border: 'none',
	}),
	control: (provided) => ({
		...provided,
		minHeight: 1,
		height: 22,
		borderRadius: 0,
		padding: 0,
		border: 'none',
		boxShadow: 'none',
		borderBottom: '1px solid rgba(187, 187, 187, 0.8)',
		cursor: 'pointer',
		'&:hover': { borderBottom: '1px solid rgb(187, 187, 187)' },
		'&:focus': { borderBottom: '1px solid rgb(187, 187, 187)' },
	}),
	input: (provided) => ({
		...provided,
		margin: 0,
		paddingBottom: 0,
		paddingTop: 0,
		minHeight: 20,
		height: 20,
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		height: 20,
		minHeight: 1,
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 0,
		paddingRight: 0,
		transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
	}),
	indicatorContainer: (provided) => ({
		...provided,
		height: 20,
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 2,
		paddingRight: 2,
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	valueContainer: (provided) => ({
		...provided,
		minHeight: 1,
		padding: 0,
	}),
	singleValue: (provided) => ({
		...provided,
		minHeight: 0,
		paddingBottom: 0,
		color: 'rgb(110, 110, 110)',
	}),
};

const SearchInput = ({ searchAndGoToFacility, placeholder }) => {
	const [searchTerm, setSearchTerm] = useState('');

	const handleSearchInputChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleSearchSubmit = async (e) => {
		e.preventDefault();

		searchAndGoToFacility(searchTerm);
	};

	return (
		<div className="hide-able-search-input">
			<form onSubmit={handleSearchSubmit}>
				<input
					id="dru-search-field"
					name="term"
					type="number"
					value={searchTerm}
					onChange={(e) => handleSearchInputChange(e)}
					autoComplete="off"
					placeholder={placeholder || "Go to DRU"}
					style={{ width: '100%', maxWidth: 'unset' }}
					className='nav-bar-go-to-id-input'
				/>
			</form>
		</div>
	);
};

const pageTimezone = { 1: 'Facility', 2: 'Fleet', 3: 'Local', 4: 'UTC' };
const routesWithoutTimezone = [routes.customer, routes.user, routes.fleet_customers, routes.orders];

const NavBar = ({ containerStyle }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const route = pathname.length > 0? pathname.slice(1) : ''
	const ref = useRef();
	const Alert = useAlert();

	const fleetContext = useContext(FleetContext);
	const facilityContext = useContext(FacilityContext);
	const userContext = useContext(UserContext);
	const fleetStatusContext = useContext(FleetStatusContext);

	const showTimeFrames = hasPermission(PERMISSIONS.CAN_ACCESS_AF);
	const [show, setShow] = useState(false);
	const [timeFramesVisible, setTimeFramesVisible] = useState('');
	const [timezones, setTimezones] = useState([]);
	const [timezone, setTimezone] = useState();

	const [username, setUsername] = useState('');
	const [nameInitials, setNameInitials] = useState('');

	const [showSearchInput, setShowSearchInput] = useState(false);
	const [showSearchInputForCustomer, setShowSearchInputForCustomer] = useState(false);

	const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const getUtcOffset = (tz) => {
		const offset = getTimezoneOffset(tz);
		const absOffset = Math.abs(offset);
		const hr = parseInt(absOffset / 3600000);
		const min = parseInt((absOffset % 3600000) / 60000);
		return `${offset < 0 ? '-' : '+'}${hr.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
	};

	const defaultTimezones = () => {
		const fleetTimezone = fleetContext.timezone || 'Europe/London';
		return [
			{
				value: `Facility | ${fleetTimezone}`,
				label: `Facility | ${fleetTimezone} (UTC${getUtcOffset(fleetTimezone)})`,
			},
			{
				value: `Fleet | ${fleetTimezone}`,
				label: `Fleet | ${fleetTimezone} (UTC${getUtcOffset(fleetTimezone)})`,
			},
			{ value: `Local | ${localTz}`, label: `Local | ${localTz} (UTC${getUtcOffset(localTz)})` },
			{ value: 'UTC', label: `Coordinated Universal Time (UTC)` },
		];
	};

	useOutsideClick(ref, () => {
		if (show) setShow(false);
	});

	const handleOnClickLogo = () => {
		fleetContext.setCurrentDataType(fleet);
		fleetContext.setDisplay('');
		fleetContext.setSearchTerm('');
		fleetContext.setSearchDrus(null);

		setShowSearchInputForCustomer(false);
		setShowSearchInput(false);
	};

	const toFleet = (e) => {
		handleOnClickLogo();

		if (UserService.hasUser() && UserService.getUser().isRoleUser) {
			e.preventDefault();
		}
	};

	const handleOnChangeTimezone = (e) => {
		setTimezone(e);
		if (route === routes.fleet) {
			fleetContext.setSelectedTimezone(e.value);
		}
		if (route.startsWith('facility/')) {
			facilityContext.setSelectedTimezone(e.value);
		}
	};

	const getAfTimezone = () => {
		for (let i = 0; i < fleetContext.aggFacilities.length; i++) {
			const af = fleetContext.aggFacilities[i];
			if (af.name === fleetContext.currentDataType) return af.facilitiesTimezone;
			if (Object.hasOwn(af, 'constituents'))
				for (let j = 0; j < af.constituents.length; j++)
					if (af.constituents[j].name === fleetContext.currentDataType)
						return af.constituents[j].facilitiesTimezone;
		}
		return fleetContext.timezone;
	};

	const onHandleLogout = () => {
		userContext.clearUser();
		fleetContext.clearState();
		facilityContext.clearState();
		fleetStatusContext.clearState();
		IdleService.unRegisterIdleResets();
		UserService.removePreferences();
		UserService.removeUser();
		UserService.removeUserDetail();
		TokenService.clearAuthToken();
		TokenService.clearCallbackBeforeExpiry();
		navigate('/auth'); // /login
	};

	const onUsernameClick = () => {
		navigate('/user');
		setShowSearchInputForCustomer(false);
		setShowSearchInput(false);
	};

	const onFleetCustomersClick = () => {
		fleetContext.setFleetCustomersList([]);
		setShowSearchInputForCustomer(false);
		setShowSearchInput(false);
	};

	const onOrderClicked = () => {
		setShowSearchInputForCustomer(false);
		setShowSearchInput(false);
	};

	const getUsername = () => {
		const userDetail = UserService.getUserDetail();
		if (!userDetail) {
			return '';
		}

		let name = `${userDetail?.forename?.slice(0, 1) || ''} ${userDetail?.surname || ''}`;
		if (name.length > 16) {
			name = name.slice(0, 14) + '...';
		}

		setNameInitials(`${userDetail?.forename?.slice(0, 1) || ''}${userDetail?.surname?.slice(0, 1) || ''}`);

		setUsername(name);
	};

	const searchAndGoToFacility = async (searchTerm) => {
		try {
			if (!searchTerm) {
				Alert.show('Please enter DRU ID to open the facility page.', 'info');
				return;
			}

			const dru = Number(searchTerm);

			if (searchTerm.length !== 8 || isNaN(dru)) {
				Alert.show('Please enter a valid DRU ID. Eg. 10000001', 'info');
				return;
			}

			const res = await FleetService.getFacilityByDruId(dru);
			if (res && res?.name) {
				const { name } = res;
				const spec = await FleetService.getFacility(name);
				if (spec && Object.keys(spec).length > 1) {
					facilityContext.setFacility(spec);
					facilityContext.setFacilityName(name);
					setShowSearchInput(false);
					setShowSearchInputForCustomer(false);
					navigate(`/facility/${res.name}`);
				} else {
					// NOTE: Response body only contains DRU ID if specification is not set
					Alert.show('Facility not found');
				}
			}
		} catch (error) {
			Alert.show(`No facility found for DRU ID ${searchTerm}`, 'error');
			console.log(error);
		}
	};

	const searchAndGoToCustomer = async (customerId) => {
		try {
			if (!customerId) {
				Alert.show("Please enter customer ID to open the customer's page.", 'error');
				return;
			}

			const id = Number(customerId);

			if (customerId.length > 6 || isNaN(id)) {
				Alert.show('Please enter a valid customer ID. Eg. 10');
				return;
			}

			const res = await FleetService.getUserCustomers(UserService.getUsername(), id);

			if (res && Object.hasOwn(res, 'customers') && res.customers?.length > 0) {
				setShowSearchInput(false);
				setShowSearchInputForCustomer(false);
				navigate(`/customer/${id}`);
			} else {
				Alert.show(`No customer found for ID ${customerId}`);
			}
		} catch (error) {
			Alert.show('Something went wrong, please try again.', 'error');
			console.log(error);
		}
	};

	const nameInitialsIcon = useMemo(() => {
		if (!nameInitials) {
			return null;
		}

		return (
			<div className="user-name-initials-container">
				<span>{nameInitials}</span>
			</div>
		);
	}, [nameInitials]);

	useEffect(() => {
		if (UserService.hasUser()) {
			getUsername();
		} else {
			setUsername('');
		}

		if (route !== routes.fleet && !route.startsWith('facility/')) {
			setTimeFramesVisible('hideTimeFrames');
			return;
		}

		setTimeFramesVisible('showFramesShow');
	}, [route]);

	useEffect(() => {
		const preference = UserService.getPreferences();
		const tzs = defaultTimezones();
		let currentTimezoneType = 'UTC';
		if (preference) {
			if (route.startsWith('facility/')) {
				const facilityTimezone = facilityContext.facility.timezone || 'Europe/London';
				tzs[0] = {
					value: `Facility | ${facilityTimezone}`,
					label: `Facility | ${facilityTimezone} (UTC${getUtcOffset(facilityTimezone)})`,
				};
				if (facilityContext.selectedTimezone.length) {
					currentTimezoneType = facilityContext.selectedTimezone.split(' | ')[0].trim();
				} else {
					currentTimezoneType = pageTimezone[preference[config.facilityPageTimezone]];
				}
			} else {
				if (fleetContext.haveSetTimezonePreference && !!fleetContext.selectedTimezone.length) {
					currentTimezoneType = fleetContext.selectedTimezone.split(' | ')[0].trim();
				} else {
					currentTimezoneType = pageTimezone[preference[config.fleetPageTimezone]];
					fleetContext.setHaveSetTimezonePreference(true);
				}
			}
		}

		setTimezones(tzs);
		switch (currentTimezoneType) {
			case 'Facility':
				handleOnChangeTimezone(tzs[0]);
				break;
			case 'Fleet':
				handleOnChangeTimezone(tzs[1]);
				break;
			case 'Local':
				handleOnChangeTimezone(tzs[2]);
				break;
			case 'UTC':
				handleOnChangeTimezone(tzs[3]);
				break;
			default:
				handleOnChangeTimezone('UTC');
		}
	}, [fleetContext.timezone, facilityContext.facility, route ]);

	useEffect(() => {
		if (route === routes.fleet && fleetContext.currentDataType !== '') {
			const fleetTimezone = fleetContext.timezone || 'Europe/London';
			const tzs = defaultTimezones();
			if (fleetContext.currentDataType !== fleet) {
				const afTz = getAfTimezone();
				tzs[0] = { value: `Facility | ${afTz}`, label: `Facility | ${afTz} (UTC${getUtcOffset(afTz)})` };
			} else {
				tzs[0] = {
					value: `Facility | ${fleetTimezone}`,
					label: `Facility | ${fleetTimezone} (UTC${getUtcOffset(fleetTimezone)})`,
				};
			}

			if (!!timezone && timezone.value.includes('Facility') && timezone.value !== tzs[0].value) {
				handleOnChangeTimezone(tzs[0]);
			}
			setTimezones(tzs);
		}
	}, [fleetContext.currentDataType]);

	return (
		<nav className={`nav-bar ${timeFramesVisible}`} style={containerStyle}>
			<div className="left left-flex">
				<div className="back-logo-customer-container" data-cy="fleet-button">
					<div>
						<Link to="/fleet" onClick={(e) => toFleet(e)}>
							<div className="group-icon-container">
								<FleetIcon />
							</div>
						</Link>

						{pathname === '/fleet' ? (
							<div className="tab-underline active" />
						) : pathname === '/configure-facility' ? (
							<div className="tab-underline inactive" />
						) : null}
					</div>
				</div>

				<div className="navbar-separator" />

				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: showSearchInput ? 82 : 50,
						}}
						onClick={() => {
							setShowSearchInput(!showSearchInput);
							setShowSearchInputForCustomer(false);
						}}
					>
						<div
							className="clipboard-icon-container"
							style={{
								height: showSearchInput ? 17 : 38,
							}}
						>
							<FacilityIcon
								className="resizable-icon"
								width={showSearchInput ? 18 : 35}
								height={showSearchInput ? 17 : 20}
							/>
						</div>
					</div>
					{showSearchInput ? <SearchInput searchAndGoToFacility={searchAndGoToFacility} /> : null}
					{pathname.startsWith('/facility/') ? <div className="tab-underline active" /> : null}
				</div>

				{hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) ? (
					<>
						<div className="navbar-separator" />
						<div>
							<Link
								data-cy="fleet-customers-button"
								to="/fleet-customers"
								onClick={onFleetCustomersClick}
							>
								<div className="group-icon-container">
									<img src={profileIcon} className="group-icon group-icon-1" alt="se-logo" />
									<img src={profileIcon} className="group-icon group-icon-2" alt="se-logo" />
									<img src={profileIcon} className="group-icon group-icon-3" alt="se-logo" />
								</div>
							</Link>
							{pathname === '/fleet-customers' ? (
								<div className="tab-underline active" />
							) : pathname === '/add-customer' ? (
								<div className="tab-underline inactive" />
							) : null}
						</div>
					</>
				) : null}
				{hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) ? (
					<>
						<div className="navbar-separator" />

						<div>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: showSearchInputForCustomer ? 82 : 50,
								}}
								onClick={() => {
									setShowSearchInputForCustomer(!showSearchInputForCustomer);
									setShowSearchInput(false);
								}}
							>
								<div
									className="clipboard-icon-container"
									style={{
										height: showSearchInputForCustomer ? 17 : 38,
									}}
								>
									<CustomerIcon
										className="profileIcon resizable-icon"
										width={showSearchInputForCustomer ? 12 : 20}
										height={showSearchInputForCustomer ? 12 : 20}
									/>
								</div>
							</div>
							{showSearchInputForCustomer ? (
								<SearchInput placeholder="Go to customer" searchAndGoToFacility={searchAndGoToCustomer} />
							) : null}
							{pathname.startsWith('/customer/') ? (
								<div className="tab-underline active" />
							) : null}
						</div>
					</>
				) : null}

				{hasPermission(PERMISSIONS.CAN_ACCESS_ORDERS) ? (
					<>
						<div className="navbar-separator" />
						<div>
							<Link data-cy="orders-button" to="/orders" onClick={onOrderClicked}>
								<div className="clipboard-icon-container">
									<img src={OrdersIcon} width={20} alt="orders" />
								</div>
							</Link>
							{pathname === '/orders' ? <div className="tab-underline active" /> : null}
						</div>
					</>
				) : null}

				<div className="navbar-separator" />
				<div className="user-name-container">
					<button
						className={`username ${username.includes('...') && route === routes.user ? 'long-name' : ''} username-button`}
						ref={ref}
						onClick={onUsernameClick}
					>
						{nameInitialsIcon}
						<span className="username-text">&nbsp;{username}</span>
					</button>
					{pathname === '/user' ? <div className="tab-underline active" /> : null}
				</div>
			</div>

			{route === routes.fleet && showTimeFrames && <TimeFrames timeFrames={FLEET_TIME_FRAMES} route={route} />}
			{route.startsWith('facility/') && <TimeFrames timeFrames={FACILITY_TIME_FRAMES} route={route} />}

			{(!routesWithoutTimezone.includes(route) || route === routes.user) && !route.startsWith('customer/') ? (
				<div className={`right ${route !== routes.user ? 'with-boarder' : ''}`}>
					{!!timezones.length && !routesWithoutTimezone.includes(route) && (
						<div className="select-timezone-wrapper">
							<Select
								styles={customStyles}
								isSearchable={true}
								value={timezone}
								onChange={(value) => handleOnChangeTimezone(value)}
								options={timezones}
							/>
						</div>
					)}
					{route.startsWith('facility/') && facilityContext.timeFrameChanging ? (
						<div style={{ position: 'absolute' }}>
							<FontAwesomeIcon icon={faSpinner} size="2xs" spin />
						</div>
					) : null}
					{route === routes.user ? (
						<button
							className={`username ${
								username.includes('...') && route === routes.user ? 'long-name' : ''
							} logout-button`}
							ref={ref}
							onClick={onHandleLogout}
						>
							<FontAwesomeIcon icon={faSignOutAlt} />
							&nbsp; <span className="logout-text">Logout</span>
						</button>
					) : null}
				</div>
			) : null}
		</nav>
	);
};

export default NavBar;
