/* Copyright Levelise Ltd 2019 - 2025 */
import { format, toZonedTime, fromZonedTime } from 'date-fns-tz';
import { getYear, getMonth, getDate, subMonths, getUnixTime, addDays, endOfDay } from 'date-fns';
import UserService from '../services/user-service';
import { PERMISSIONS, TIME_FRAMES, resolutions } from './constants';

export const isMonday = (ts, tz) => {
	return format(toZonedTime(ts * 1000, tz), 'EEEE') === 'Monday';
};

export const formatTimestamp = (ts, tz, fmt) => {
	const time = toZonedTime(ts * 1000, tz);
	return format(time, fmt);
};

export const createReqBody = (data) => {
	return Object.keys(data).reduce((acc, cur) => {
		const current = `${cur}=${data[cur]}`;
		return (acc = acc.length ? `${acc}&${current}` : current);
	}, '');
};

export const createAuthString = (data) => {
	return Object.keys(data).reduce((acc, cur) => {
		const str = `${cur}=${data[cur]}`;
		return (acc = acc.length ? `${acc}%${str}` : str);
	}, '');
};

export const options = (positions) => {
	return {
		position: positions.BOTTOM_CENTER,
		timeout: 5000,
	};
};

export const hasPermission = (allowedRoles) => {
	if (UserService.hasUser()) {
		const userRoles = UserService.getUser()?.role;
		if (userRoles && userRoles?.length > 0 && allowedRoles && allowedRoles?.length > 0) {
			for (let i = 0; i < userRoles.length; i++) {
				if (allowedRoles.includes(userRoles[i])) {
					return true;
				}
			}
		}
	}

	return false;
};

export const isUpdateData = (currData, prevData, timeFrame, timestamp) => {
	const isUpdate = currData.timeFrame === timeFrame && prevData.timeFrame === currData.timeFrame;
	if (isUpdate && currData.updated) {
		const isBySecondRecords = currData.timeFrame === TIME_FRAMES.fifteen_minutes;
		const currRecords = isBySecondRecords ? currData.bySecondReports : currData.minutelyReports;
		const prevRecords = isBySecondRecords ? prevData.bySecondReports : prevData.minutelyReports;
		if (!!prevRecords && !!prevRecords.length && !!currRecords.length) {
			const lastIdx = prevRecords.length - 1;
			const prevTimestamp = prevRecords[lastIdx][timestamp];
			const currTimestamp = currRecords[0][timestamp];
			return prevTimestamp < currTimestamp;
		}
	}
};

export const isUpdateMinuteData = (currData, prevData, timeFrame, timestamp) => {
	const isUpdate = currData.timeFrame === timeFrame && prevData.timeFrame === currData.timeFrame;
	if (isUpdate && currData.updated) {
		const currRecords = currData.minutelyReports;
		const prevRecords = prevData.minutelyReports;
		if (!!prevRecords && !!prevRecords.length && !!currRecords.length) {
			const lastIdx = prevRecords.length - 1;
			const prevTimestamp = prevRecords[lastIdx][timestamp];
			const currTimestamp = currRecords[0][timestamp];
			return prevTimestamp < currTimestamp;
		}
	}
};

export const isFleetUpdate = (currData, prevData, timeFrame, timestamp) => {
	const isUpdate = currData.timeFrame === timeFrame && prevData.timeFrame === currData.timeFrame;
	if (isUpdate && currData.updated) {
		const isBySecondRecords = currData.timeFrame === TIME_FRAMES.fifteen_minutes;
		const currRecords = isBySecondRecords ? currData.bySecondReports : currData.minutelyReports;
		const prevRecords = isBySecondRecords ? prevData.bySecondReports : prevData.minutelyReports;
		if (!!prevRecords && !!prevRecords.length && !!currRecords.length) {
			const prevTimestamp = prevRecords[0][timestamp];
			const currTimestamp = currRecords[currRecords.length - 1][timestamp];
			return prevTimestamp < currTimestamp;
		}
	}
};

export const filterRecordsByTimeframe = (records, timeFrame, timestamp) => {
	if (!!records && records.length) {
		const before_timestamp = records[records.length - 1][timestamp] - timeFrame;
		return records.filter((record) => record[timestamp] > before_timestamp);
	}
	return records;
};

export const filterDataByTimeframe = (records, timeFrame, timestamp) => {
	if (!!records && records.length) {
		const before_timestamp = records[0][timestamp] - timeFrame;
		return records.filter((record) => record[timestamp] > before_timestamp);
	}
	return [];
};

export const combineStatus = (druStatus) => {
	let allDruIds = [];
	if (druStatus && Object.hasOwn(druStatus, 'unoptimised') && Object.hasOwn(druStatus, 'oaas')) {
		const oaas = druStatus['oaas'];
		const unoptimised = druStatus['unoptimised'];
		const combined = {
			disconnected: [...unoptimised.disconnected, ...oaas.disconnected],
			disconnectedCount: unoptimised.disconnectedCount + oaas.disconnectedCount,
			druCount: unoptimised.druCount + oaas.druCount,
			faultCode: combineFaulCodeDrus(unoptimised.faultCode, oaas.faultCode),
			faultCodeCount: unoptimised.faultCodeCount + oaas.faultCodeCount,
			severestFault: {
				unsafe: [...oaas.severestFault.unsafe, ...unoptimised.severestFault.unsafe],
				unoptimisable: [...oaas.severestFault.unoptimisable, ...unoptimised.severestFault.unoptimisable],
				diminished: [...oaas.severestFault.diminished, ...unoptimised.severestFault.diminished],
				faulty: [...oaas.severestFault.faulty, ...unoptimised.severestFault.faulty],
				glitchy: [...oaas.severestFault.glitchy, ...unoptimised.severestFault.glitchy],
				noted: [...oaas.severestFault.noted, ...unoptimised.severestFault.noted],
			},
			severestFaultCount: {
				unsafe: oaas.severestFaultCount.unsafe + unoptimised.severestFaultCount.unsafe,
				unoptimisable: oaas.severestFaultCount.unoptimisable + unoptimised.severestFaultCount.unoptimisable,
				diminished: oaas.severestFaultCount.diminished + unoptimised.severestFaultCount.diminished,
				faulty: oaas.severestFaultCount.faulty + unoptimised.severestFaultCount.faulty,
				glitchy: oaas.severestFaultCount.glitchy + unoptimised.severestFaultCount.glitchy,
				noted: oaas.severestFaultCount.noted + unoptimised.severestFaultCount.noted,
			},
			noFacility: [...unoptimised.noFacility, ...oaas.noFacility],
			noFacilityCount: unoptimised.noFacilityCount + oaas.noFacilityCount,
			ok: [...unoptimised.ok, ...oaas.ok],
			okCount: unoptimised.okCount + oaas.okCount,
			recovering: [...unoptimised.recovering, ...oaas.recovering],
			recoveringCount: unoptimised.recoveringCount + oaas.recoveringCount,
			retired: [...unoptimised.retired, ...oaas.retired],
			retiredCount: unoptimised.retiredCount + oaas.retiredCount,
			unregistered: [...unoptimised.unregistered, ...oaas.unregistered],
			unregisteredCount: unoptimised.unregisteredCount + oaas.unregisteredCount,
		};

		druStatus['combined'] = combined;
		allDruIds = extractDruIds(combined);
	}

	return [allDruIds, druStatus];
};

const extractDruIds = (status) => {
	let druIds = [];
	for (let key in status) {
		if (!key.includes('Count') && key !== 'severestFault') {
			switch (key) {
				case 'disconnected':
					status[key].forEach((dru) => druIds.push(dru.druId));
					break;
				case 'faultCode':
					Object.keys(status[key]).forEach((faultCode) => druIds.push(...status[key][faultCode]));
					break;
				default:
					druIds.push(...status[key]);
			}
		}
	}
	return druIds;
};

const combineFaulCodeDrus = (unoptimised, oaas) => {
	const _unoptimised = JSON.parse(JSON.stringify(unoptimised));
	const _oaas = JSON.parse(JSON.stringify(oaas));
	if (!Object.keys(_oaas).length) {
		return _unoptimised;
	}

	if (!Object.keys(_unoptimised).length) {
		return _oaas;
	}

	for (let key in _unoptimised) {
		if (!Object.hasOwn(oaas, key)) {
			_oaas[key] = [];
		}
		_oaas[key] = [..._oaas[key], ..._unoptimised[key]];
	}

	return _oaas;
};

export const detectBrowser = () => {
	if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
		return 'opera';
	} else if (navigator.userAgent.indexOf('Chrome') !== -1) {
		return 'chrome';
	} else if (navigator.userAgent.indexOf('Safari') !== -1) {
		return 'safari';
	} else if (navigator.userAgent.indexOf('Firefox') !== -1) {
		return 'Firefox';
	} else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
		return 'ie'; //crap
	} else {
		return 'unknown';
	}
};

export const generateState = () => {
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let state = '';
	for (let i = 0; i < 8; i++) {
		let index = Math.floor(Math.random() * chars.length);
		state += chars[index];
	}
	return state;
};

export const generateCodeVerifier = () => {
	let array = new Uint32Array(56 / 2);
	window.crypto.getRandomValues(array);
	return Array.from(array, (v) => ('0' + v.toString(16)).substr(-2)).join('');
};

export const generateCodeChallenge = async (v) => {
	const encoder = new TextEncoder();
	const data = encoder.encode(v);
	const hashed = await window.crypto.subtle.digest('SHA-256', data);
	const bytes = new Uint8Array(hashed);
	const len = bytes.byteLength;

	let str = '';
	for (let i = 0; i < len; i++) {
		str += String.fromCharCode(bytes[i]);
	}

	return window.btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

export const getSinceAndBefore = (startDate, endDate, tz, minResolution = resolutions.minute) => {
	const [resolution, day] = getResolutionAndAddDay(startDate, endDate, tz);
	const sd = new Date(startDate);
	const ed = new Date(endDate);

	const sdZoned = fromZonedTime(new Date(getYear(sd), getMonth(sd), getDate(sd)), tz);
	const edZoned = fromZonedTime(addDays(new Date(getYear(ed), getMonth(ed), getDate(ed)), day), tz);

	const since = getUnixTime(sdZoned);
	const before = getUnixTime(edZoned);

	if (minResolution === resolutions.half_hour) {
		return [since, before, resolution === resolutions.minute ? resolutions.half_hour : resolution, resolution];
	}
	return [since, before, resolution];
};

export const getResolutionAndAddDay = (startDate, endDate, tz) => {
	const sd = new Date(startDate);
	const ed = new Date(endDate);
	const since = getUnixTime(new Date(Date.UTC(getYear(sd), getMonth(sd), getDate(sd))));
	const before = getUnixTime(new Date(Date.UTC(getYear(ed), getMonth(ed), getDate(ed))));

	if (before - since === 0) {
		const timestamp = getUnixTime(fromZonedTime(subMonths(new Date(), 15), tz));
		return since > timestamp ? [resolutions.minute, 1] : [resolutions.half_hour, 1];
	} else if (before - since <= 86400 * 27) {
		return [resolutions.half_hour, 1];
	} else {
		return [resolutions.day, 1];
	}
};

export const getSinceAndBeforeForCustomerDownload = (startDate, endDate, tz, minResolution = resolutions.minute) => {
	const sd = new Date(startDate);
	const ed = new Date(endDate);

	const sdZoned = fromZonedTime(new Date(getYear(sd), getMonth(sd), getDate(sd)), tz);
	const edZoned = fromZonedTime(endOfDay(new Date(getYear(ed), getMonth(ed), getDate(ed))), tz);

	const since = getUnixTime(sdZoned);
	let before = getUnixTime(edZoned);

	const resolution = getResolutionForCustomerDownload(since, before);

	before = before + 1;

	if (minResolution === resolutions.half_hour) {
		return [since, before, resolution === resolutions.minute ? resolutions.half_hour : resolution, resolution];
	}
	return [since, before, resolution];
};

export const getResolutionForCustomerDownload = (since, before, forErrorCheck = false) => {
	const diff = before - since;

	if (diff <= 86400 * 92) {
		if (!forErrorCheck && diff > 86400 * 30) {
			return resolutions.day;
		}

		return resolutions.half_hour;
	} else if (diff <=  86400 * 5000) {
		return resolutions.day;
	} else {
		return resolutions.week;
	}
};

export const convertArrayToObject = (array, key) => {
	const initialValue = {};
	return array.reduce(
		(obj, item) => ({
			...obj,
			[item[key]]: item,
		}),
		initialValue
	);
};

export const validateEmail = (email) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
	return emailRegex.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
	if (!phoneNumber) {
		return false;
	}

	const phone = phoneNumber.trim();

	if (phone.startsWith('-')) {
		return false;
	}

	const phoneNumberDigitsOnly = phone?.replace(/\D/g, '');
	if (!phoneNumberDigitsOnly) {
		return false;
	}

	if (phoneNumber.startsWith('+') && phone.length - 1 !== phoneNumberDigitsOnly.length) {
		return false;
	}
	if (!phoneNumber.startsWith('+') && phone.length !== phoneNumberDigitsOnly.length) {
		return false;
	}

	if (phoneNumberDigitsOnly?.length < 8 || phoneNumberDigitsOnly.length > 15) {
		return false;
	}

	return true;
};

/**
 * Starts with one or two uppercase letters
 * Followed by one or two digits
 * followed by an optional single uppercase letter
 * optional spaces
 * followed by single digit postal sector
 * ends with exactly two upper case letters
 *
 * @param {*} postcode
 * @returns
 */
export const validatePostcode = (postcode) => {
	if (!postcode || !postcode?.trim()) {
		return false;
	}

	// check for length
	if (postcode.length < 6 || postcode.length > 8) {
		return false;
	}

	const trimedPostCode = postcode?.trim();

	if (!trimedPostCode) {
		return false;
	}

	// postcode doesn't include middle space
	if (!trimedPostCode?.includes(' ')) {
		return false;
	}

	// check it only include middle space
	if (trimedPostCode.indexOf(' ') !== trimedPostCode.lastIndexOf(' ')) {
		return false;
	}

	const outcodeIncodeArr = trimedPostCode.split(' ');
	const incode = outcodeIncodeArr[1];
	const outcode = outcodeIncodeArr[0];

	// check incode length is 3
	if (incode.length !== 3) {
		return false;
	}

	const units = incode.slice(1);

	// units length should be 2
	if (units.length !== 2) {
		return false;
	}

	// units should only consist of letters
	if (!/^[a-zA-Z]+$/.test(units)) {
		return false;
	}

	// check sector number exists
	if (!/^[0-9]$/.test(incode[0])) {
		return false;
	}

	// outcode should have a length in between 2 and 4
	if (outcode?.length < 2 || outcode?.length > 4) {
		return false;
	}

	// outcode should always start with a letter
	if (!/^[a-zA-Z]+$/.test(outcode[0])) {
		return false;
	}

	// out code with length 2 should end with a number
	if (outcode.length === 2 && !/^[0-9]$/.test(outcode[1])) {
		return false;
	}

	if (outcode.length > 2) {
		const outcodeWithoutNumber = outcode.replace(/[0-9]/g, '');
		if (outcodeWithoutNumber.length === outcode.length) {
			return false;
		}
	}

	// check for special characters
	const joinedPostCode = outcodeIncodeArr.join('');
	if (!/^[a-zA-Z0-9]+$/.test(joinedPostCode)) {
		return false;
	}

	return true;
};

export const getShortFormForContracts = (contract) => {
	let shortForm = contract.replace(/\s/g, '');
	shortForm = shortForm.replace(/[a-z]/g, '');

	return shortForm;
};

export const getEvidenceType = (id) => {
	const type = id === 1 ? 'Stripe Billing' : id === 2 ? 'Customer Bill Check' : '';
	return type;
};

export const getChecksLabel = (key) => {
	let label = '';

	switch (key) {
		case 'consentStreet':
			label = 'Consent Street';
			break;
		case 'consentPostcode':
			label = 'Consent Postcode';
			break;
		case 'threeDSSupported':
			label = '3DS Support';
			break;
		case 'cvcPassed':
			label = 'CVC';
			break;
		case 'address1Passed':
			label = 'Address Line 1';
			break;
		case 'postcodePassed':
			label = 'Postcode';
			break;
		case 'consentMpanImport':
			label = 'Consent Import MPAN';
			break;
		case 'consentMpanExport':
			label = ' Consent Export MPAN';
			break;
		default:
			label = key;
	}
	return label;
};

export const isEvidenceAccepted = (consent) => {
	if (consent && Object.hasOwn(consent, 'evidence') && Object.hasOwn(consent.evidence, 'acceptedTimestampSec')) {
		return true;
	}

	return false;
};

export const isReadInventoryAvailable = (consent) => {
	if (
		consent &&
		Object.hasOwn(consent, 'evidence') &&
		Object.hasOwn(consent.evidence, 'readInventory') &&
		Object.hasOwn(consent.evidence.readInventory, 'postcode') &&
		Object.hasOwn(consent.evidence.readInventory, 'address')
	) {
		return true;
	}

	return false;
};

export const getOptions = (systemTypes, sort = false) => {
	if (systemTypes) {
		const none = '-- None --';
		let options = [];
		if (Array.isArray(systemTypes)) {
			options = systemTypes.map((type) => {
				return { value: type !== none ? type : '', label: type };
			});
		} else {
			options = Object.keys(systemTypes).map((type) => {
				if (sort) {
					return {
						value: systemTypes[type] !== none ? type : '',
						label: systemTypes[type] !== none ? `${systemTypes[type]} (${type})` : systemTypes[type],
					};
				}
				return {
					value: systemTypes[type] !== none ? type : '',
					label: systemTypes[type],
				};
			});
		}

		if (sort) return options.sort((a, b) => (a.label === none || a.label > b.label ? 1 : -1));
		return options;
	}
};

export const findIndexFromArray = (arr, comparator, startIndex = 0) => {
	for (let i = startIndex; i < arr.length; i++) {
		if (comparator(arr[i])) {
			return i;
		}
	}

	return -1;
};

export const getTimestampStartIndex = (arr, key, value) => {
	let i = 0;
	let n = arr.length;
	let jump = Math.floor(Math.sqrt(n));

	while (i < n) {
		if (arr[i][key] >= value) {
			break;
		}

		i += jump;
	}

	i -= jump;

	while (i < n) {
		if (arr[i][key] >= value) {
			return i;
		}

		i++;
	}

	return -1;
};

const zeroPad = (input, size) => {
	input = input.toString();
	while (input.length < size) {
		input = '0' + input;
	}
	return input;
};

export const getFacilityCode = (id) => {
	if (!id || id <= 0) {
		return 'None';
	} else {
		let name = [...zeroPad(id, 7)].reverse().join('');
		let hex = zeroPad((10000000 - parseInt(name)).toString(16).toUpperCase(), 6);
		return `A${hex.slice(0, 2)}-${hex.slice(2)}`;
	}
};

export const getInitialPageNumber = (page) => {
	let pageNumber = 1; // 1 = fleet page, 2 = fleet customers, 3 = orders page, 4 = user page
	if (
		hasPermission(PERMISSIONS.CAN_ACCESS_ORDERS) ||
		(hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) && page !== 3)
	) {
		pageNumber = page;
	} else if (hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY)) {
		if (page === 1 || page === 4) {
			pageNumber = page;
		}
	}

	if (pageNumber < 1 || pageNumber > 4) {
		pageNumber = 1;
	}

	return pageNumber;
};
