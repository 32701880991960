/* Copyright Levelise Ltd 2019-2025 */
import { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Input, Button } from '../Form/index';
import OAuthService from '../../services/auth-service';
import UserContext from '../../contexts/UserContext';
import './index.css';
import TabContainer from '../TabContainer';
import { colors } from '../../utils/chart';
import UserService from '../../services/user-service';

const styles = {
	errorMessage: {
		fontSize: 10,
		color: colors.errorRed,
		padding: 0,
		margin: 0,
		textAlign: 'start',
	},
};

const ErrorMessage = ({ message }) => <span style={styles.errorMessage}>{message}</span>;

const InputWithError = ({ error, ...props }) => (
	<div
		style={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			marginTop: 8,
		}}
	>
		<Input {...props} />
		{error ? <ErrorMessage message={error} /> : null}
	</div>
);

const PasswordForm = ({
	newPassword,
	setNewPassword,
	newPasswordError,
	confirmNewPassword,
	setConfirmNewPassword,
	confirmNewPasswordError,
	handlerSetEnableMfa,
	onCancelClick,
	onHandleChangePasswordSubmit,
	error,
	context,
	loading,
}) => {
	const [name, setName] = useState('');

	useEffect(() => {
		const userDetail = UserService.getUserDetail();
		if (!userDetail) {
			return '';
		}

		setName(`${userDetail?.forename || ''} ${userDetail?.surname || ''}`);
	}, [UserService.hasUser()]);

	return (
		<form
			className="change-password-form"
			onSubmit={onHandleChangePasswordSubmit}
			style={{ marginTop: 15, marginLeft: '5%', marginRight: '5%' }}
		>
			<div style={{ display: 'flex', }}>
				<span style={{ fontSize: '.8rem'}}>{name}</span>
			</div>

			<InputWithError
				id="new-password-input"
				type="password"
				name="newPassword"
				placeholder="New password"
				autoComplete="off"
				value={newPassword}
				onChange={(e) => setNewPassword(e.target.value)}
				style={{ margin: 0 }}
				error={newPasswordError}
			/>
			<InputWithError
				id="confirm-password-input"
				type="password"
				name="confirmNewPassword"
				placeholder="Confirm new password"
				autoComplete="off"
				value={confirmNewPassword}
				onChange={(e) => setConfirmNewPassword(e.target.value)}
				style={{ margin: 0 }}
				error={confirmNewPasswordError}
			/>

			<div role="alert">
				{error && <p className="password-alert">{error}</p>}
				{context.error && (
					<p className="password-alert">
						{context.error.message || context.error.error_description}. Try again!
					</p>
				)}
			</div>

			<div className="change-password-btn-wrapper">
				<div id="setup-mfa">
					<span onClick={() => handlerSetEnableMfa()}>Setup MFA?</span>
				</div>
				<Button className="configure-btn reset" type="button" onClick={onCancelClick} disabled={!newPassword}>
					Cancel
				</Button>

				<Button className={'configure-btn submit'} type="submit" disabled={!newPassword || loading}>
					Save
				</Button>
			</div>
		</form>
	);
};

const ChangePasswordForm = ({ onChangePasswordSuccess }) => {
	const context = useContext(UserContext);
	const [error, setError] = useState(null);
	const [enableMfa, setEnableMfa] = useState(false);
	const [img, setImg] = useState();
	const [activated, setActivated] = useState(false);

	const [newPassword, setNewPassword] = useState('');
	const [newPasswordError, setNewPasswordError] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');

	const [mfaCode1, setMfaCode1] = useState('');
	const [mfaCode1Error, setMfaCode1Error] = useState('');
	const [mfaCode2, setMfaCode2] = useState('');
	const [mfaCode2Error, setMfaCode2Error] = useState('');

	const [loading, setLoading] = useState(false);

	const fetchQRCode = () => {
		OAuthService.postEnroll()
			.then(async (res) => {
				const imageBlob = await res.blob();
				const imageObjectURL = URL.createObjectURL(imageBlob);
				setImg(imageObjectURL);
			})
			.catch((err) => {
				setImg(null);
				context.setError(err);
			});
	};

	const handlerSetEnableMfa = () => {
		context.setError(null);
		setError(null);
		setImg(null);
		setActivated(false);
		setEnableMfa(true);
	};

	const onHandleChangePasswordSubmit = async (e) => {
		e.preventDefault();

		try {
			const password = newPassword.trim();
			const confirmPassword = confirmNewPassword.trim();

			if (!password) {
				setNewPasswordError('New password is required.');
				return;
			}
			if (password?.length < 12) {
				setNewPasswordError('New password should be 12 or more characters long.');
				return;
			}
			if (!confirmPassword) {
				setConfirmNewPasswordError('Confirm password is required.');
				return;
			}
			if (password !== confirmPassword) {
				setConfirmNewPasswordError('New password and confirm new password do not match.');
				return;
			}

			setLoading(true);
			const res = await OAuthService.postChangePassword(password);
			if (res) {
				setLoading(false);
				onChangePasswordSuccess();
			}
		} catch (err) {
			setLoading(false);
			context.setError(err);
		}
	};

	const onCancelChangePasswordClick = () => {
		setNewPassword('');
		setNewPasswordError('');
		setConfirmNewPassword('');
		setConfirmNewPasswordError('');
	};

	const handleOnSubmitActivateMFA = async (e) => {
		e.preventDefault();
		try {
			const mfa1 = mfaCode1.trim();
			const mfa2 = mfaCode2.trim();

			if (!mfa1) {
				setMfaCode1Error('Code 1 is required.');
				return;
			}

			if (!mfa2) {
				setMfaCode2Error('Code 2 is required.');
				return;
			}

			setLoading(true);
			const res = await OAuthService.postActivate(parseInt(mfaCode1), parseInt(mfaCode2));
			if (res) {
				setLoading(false);
				setActivated(true);
			}
		} catch (error) {
			setLoading(false);
			context.setError(error);
		}
	};

	const handleOnCancelActivateMFA = () => {
		context.setError(null);
		setMfaCode1('');
		setMfaCode2('');
		setError(null);
		setImg(null);
		setActivated(false);
		setEnableMfa(false);
	};

	const handleConfirmPasswordChange = (text) => {
		setConfirmNewPasswordError('');
		setConfirmNewPassword(text);
	};

	const handleNewPasswordChange = (text) => {
		setNewPasswordError('');
		setNewPassword(text);
	};

	const isMfaCodeValid = (code) => {
		const numberDigitsOnly = /^[0-9]*$/.test(code);
		const num = !code ? 0 : parseInt(code);

		if (num >= 0 && num <= 999999 && code.length <= 6 && numberDigitsOnly) {
			return true;
		}

		return false;
	};

	const onCode1Change = (e) => {
		let value = e.target.value;

		const isValid = isMfaCodeValid(value);

		if (isValid) {
			console.log(isValid);
			setMfaCode1Error('');
			setMfaCode1(value);
		}
	};

	const onCode2Change = (e) => {
		let value = e.target.value;

		const isValid = isMfaCodeValid(value);

		if (isValid) {
			setMfaCode2Error('');
			setMfaCode2(value);
		}
	};

	const mfaSteps = () => {
		return (
			<form id="mfa-enrol" onSubmit={handleOnSubmitActivateMFA}>
				<h3 id="mfa-enrol-title">Setup third party authenticator app.</h3>
				<p className="mfa-enrol-step">1. Install google authenticator app on your phone.</p>
				<p className="mfa-enrol-step">2. Use the authenticator app to scan the QR code below.</p>
				{img ? (
					<img src={img} id="mfa-qr" alt="icons" />
				) : (
					<div id="mfa-qr-show">
						<p id="mfa-qr-show-text" onClick={fetchQRCode}>
							Show QR code
						</p>
					</div>
				)}
				<p className="mfa-enrol-step">3. Enter two consecutive six-digit authenticator codes below.</p>
				<div id="mfa-code-wrapper">
					<InputWithError
						id="mfa-code1-input"
						type="number"
						name="mfaCode1"
						placeholder="Code 1"
						autoComplete="off"
						min="0"
						max="999999"
						value={mfaCode1}
						onChange={onCode1Change}
						error={mfaCode1Error}
					/>
					<InputWithError
						id="mfa-code2-input"
						type="number"
						name="mfaCode2"
						placeholder="Code 2"
						autoComplete="off"
						min="0"
						max="999999"
						value={mfaCode2}
						onChange={onCode2Change}
						error={mfaCode2Error}
					/>
				</div>
				<div role="alert">
					{error && <p className="mfa-alert">{error}</p>}
					{context.error && (
						<p className="mfa-alert">
							{context.error.message || context.error.error_description}. Try again!
						</p>
					)}
				</div>
				<div className="mfa-enrol-btn-wrapper">
					<Button className="configure-btn reset" type="button" onClick={handleOnCancelActivateMFA}>
						Cancel
					</Button>
					<Button
						className="configure-btn submit"
						type="submit"
						style={{ width: 100 }}
						disabled={!mfaCode1 || !mfaCode2 || loading}
					>
						Activate MFA
					</Button>
				</div>
			</form>
		);
	};

	const mfaActivated = () => {
		return (
			<div id="mfa-activated">
				<p id="mfa-activation-message">
					<FontAwesomeIcon icon={faCircleCheck} /> Successfully activated MFA
				</p>
				<p id="mfa-activation-info">MFA will be required during sign-in.</p>
				<div className="mfa-enrol-btn-wrapper">
					<Button className="configure-btn reset" onClick={handleOnCancelActivateMFA}>
						Close
					</Button>
				</div>
			</div>
		);
	};

	return (
		<TabContainer title={enableMfa ? 'Setup MFA' : 'Change password'}>
			{!enableMfa ? (
				<PasswordForm
					newPassword={newPassword}
					setNewPassword={handleNewPasswordChange}
					newPasswordError={newPasswordError}
					confirmNewPassword={confirmNewPassword}
					setConfirmNewPassword={handleConfirmPasswordChange}
					confirmNewPasswordError={confirmNewPasswordError}
					handlerSetEnableMfa={handlerSetEnableMfa}
					onCancelClick={onCancelChangePasswordClick}
					onHandleChangePasswordSubmit={onHandleChangePasswordSubmit}
					error={error}
					context={context}
					loading={loading}
				/>
			) : activated ? (
				mfaActivated()
			) : (
				mfaSteps()
			)}
		</TabContainer>
	);
};

export default ChangePasswordForm;
