/* Copyright Levelise Ltd 2023 - 2025 */
import { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/chart';
import { formatTimestamp, hasPermission } from '../../utils/utils';
import './index.css';
import FleetConext from '../../contexts/FleetContext';
import { Button, SpecInput } from '../Form';
import { download, loading, PERMISSIONS } from '../../utils/constants';
import FleetService from '../../services/fleet-service';
import { useAlert } from '../Alert';
import AddBtn from '../AddBtn';
import AddCustomerCreditsPopup from '../AddCustomerCreditsPopup';
import DownloadBtn from '../DownloadBtn';
import CustomerRecords from '../CustomerRecords';

const getDayTimestamp = (dateString) => {
	const date = new Date(dateString);
	date.setUTCHours(0, 0, 0, 0);

	return date.getTime() / 86400000;
};

const getAmount = (amount) => {
	if (amount !== 0 && !amount) {
		return;
	}
	let newAmount = isNaN(amount) ? (0).toFixed(2) : amount?.toFixed(2);

	return `£${newAmount}`;
};

const getCreditUpdateRequestArr = (creditsObj) => {
	let arr = [];

	if (creditsObj) {
		const keys = Object.keys(creditsObj);
		for (let i = 0; i < keys.length; i++) {
			const creditId = keys[i];
			const status = creditsObj[creditId].status;
			let obj = { creditId: Number(creditId), status };

			if (status === 'Failed' && creditsObj[creditId].reason) {
				obj.reason = creditsObj[creditId].reason;
			}

			arr.push(obj);
		}
	}

	return arr;
};

const getNewCreditsReqObj = (newCredits) => {
	const arr = [];
	let resObj = null;

	for (let i = 0; i < newCredits.length; i++) {
		const credit = newCredits[i];
		const obj = {};

		if (
			!Object.hasOwn(credit, 'timestampDay') ||
			!credit.timestampDay ||
			!Object.hasOwn(credit, 'creditAmount') ||
			!credit.creditAmount ||
			!Object.hasOwn(credit, 'reasonCode') ||
			!credit.reasonCode
		) {
			resObj = {
				valid: false,
				message: 'Credit date, credit amount and reason code are required.',
				index: i,
			};
			break;
		}

		obj['timestampDay'] = getDayTimestamp(credit.timestampDay);

		Object.keys(credit).forEach((key) => {
			if (credit[key] && key !== 'timestampDay') {
				obj[key] = credit[key];
			}
		});

		arr.push(obj);
	}

	if (!resObj) {
		resObj = { valid: true, credits: arr };
	}

	return resObj;
};

const getStatusColorAndText = (status) => {
	let color = 'grey';
	let text = status;

	if (status === 'P') {
		color = colors.orangeNew;
		text = 'Pending';
	} else if (status === 'L' || status === 'I') {
		text = 'Excluded';
		color = colors.blackNew;
	} else if (status === 'R') {
		text = 'Requested';
		color = colors.yellow;
	} else if (status === 'F') {
		text = 'Failed';
		color = colors.errorRed;
	} else if (status === 'S') {
		text = 'Succeeded';
		color = colors.successGreen;
	} else if (status === 'H') {
		text = 'Historic';
		color = colors.darkBlue;
	}

	return (
		<td
			className="table-col-2 responsiveText"
			style={{
				minWidth: '65px',
			}}
		>
			<FontAwesomeIcon icon={faCircle} color={color} size="sm" />
			<br />
			{text || '-'}
		</td>
	);
};

const RadioButtonsGroup = ({ items, onChangeValue }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
			}}
		>
			{items.map((ele, index) => (
				<button
					className="credits-radio-button"
					style={{ display: 'flex', alignItems: 'center' }}
					onClick={() => onChangeValue(ele.label)}
					disabled={ele.selected}
					key={index}
				>
					<div
						style={{
							width: 10,
							height: 10,
							borderRadius: 5,
							border: '1px solid #000',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						{ele.selected ? (
							<div
								style={{
									width: 6,
									height: 6,
									borderRadius: 3,
									backgroundColor: '#000',
								}}
							/>
						) : null}
					</div>
					<p style={{ fontSize: 11, margin: 0, marginLeft: 3 }}>{ele.label}</p>
				</button>
			))}
		</div>
	);
};

const CustomerFilesTable = ({ customerFiles, selectedTimezone, downloadState, handleDownloadFile }) => {
	const [selectedFileId, setSelectedFileId] = useState('');

	return (
		<div className="scrollableTable">
			<table border="0" cellPadding="2" cellSpacing="0" width="100%" data-cy="credits_table">
				<thead>
					<tr>
						<th
							className="table-col-7 col-name left-data first-column-padding"
							style={{ minWidth: '65px' }}
						>
							File type and name
						</th>
						<th className="table-col-4 col-name left-data">Upload time</th>
						<th className="table-col-1 col-name" style={{ zIndex: 1 }}></th>
					</tr>
				</thead>
				<tbody>
					{customerFiles && customerFiles.length ? (
						<>
							{customerFiles?.map((file, i) => {
								const date = file?.uploadedTimestampSec
									? formatTimestamp(file?.uploadedTimestampSec, selectedTimezone, 'dd/MM/yy')
									: '-';
								const time = file?.uploadedTimestampSec
									? formatTimestamp(file?.uploadedTimestampSec, selectedTimezone, 'HH:mm:ss')
									: '-';

								return (
									<tr key={i} width="100%">
										<td className="table-col-7 responsiveText left-data first-column-padding">
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<span
													style={{
														fontSize: '0.75rem',
													}}
												>
													{file?.type?.name || ''}
												</span>
												<span
													style={{
														fontSize: '0.6rem',
													}}
												>
													{file.name}
												</span>
											</div>
										</td>
										<td className="table-col-4 responsiveText left-data">
											{date || '-'}
											<br />
											{time || '-'}
										</td>
										<td className="table-col-1 left-data responsiveText">
											{file?.id ? (
												<DownloadBtn
													disabled={downloadState === loading}
													downloadState={
														selectedFileId === file?.id ? downloadState : download
													}
													clickDownload={() => {
														setSelectedFileId(file?.id);
														handleDownloadFile(file?.id);
													}}
													style={{
														position: 'relative',
														marginLeft: 5,
													}}
												/>
											) : null}
										</td>
									</tr>
								);
							})}
						</>
					) : null}
				</tbody>
			</table>
		</div>
	);
};

const CustomerCreditsTable = ({ customerCredit, editing, selectedTimezone, creditsToUpdate, setCreditsToUpdate }) => (
	<div className="scrollableTable">
		<table border="0" cellPadding="2" cellSpacing="0" width="100%" data-cy="credits_table">
			<thead>
				<tr>
					{editing ? <th></th> : null}

					<th className="table-col-2 col-name" style={{ minWidth: '65px' }}>
						Status
					</th>
					<th className="table-col-2 col-name">Time</th>
					<th className="table-col-2 col-name">Credit date</th>
					<th className="table-col-2 col-name">Amount</th>

					<th className="table-col-2 col-name">Reason code</th>
					<th className="table-col-8 col-name">Comment</th>
				</tr>
			</thead>
			<tbody>
				{customerCredit && customerCredit?.credits && customerCredit?.credits?.length ? (
					<>
						{customerCredit.credits?.map((credit, i) => {
							const date = credit?.statusChangeSec
								? formatTimestamp(credit?.statusChangeSec, selectedTimezone, 'dd/MM/yy')
								: '-';
							const time = credit?.statusChangeSec
								? formatTimestamp(credit?.statusChangeSec, selectedTimezone, 'HH:mm:ss')
								: '-';
							const tcreditDayime = credit?.timestampDay
								? formatTimestamp(credit?.timestampDay * 86400, selectedTimezone, 'dd/MM/yy')
								: '-';

							return (
								<tr key={i} width="100%">
									{editing ? (
										<td style={{ textAlign: 'left' }}>
											{credit?.status === 'R' ? (
												<>
													<RadioButtonsGroup
														items={[
															{
																label: 'Successful',
																selected:
																	creditsToUpdate[credit.creditId]?.status ===
																	'Successful',
															},
															{
																label: 'Failed',
																selected:
																	creditsToUpdate[credit.creditId]?.status ===
																	'Failed',
															},
														]}
														onChangeValue={(label) => {
															setCreditsToUpdate((prevCreditsToUpdate) => ({
																...prevCreditsToUpdate,
																[credit.creditId]: {
																	status: label,
																	reason: '',
																},
															}));
														}}
													/>
													{creditsToUpdate[credit.creditId]?.status === 'Failed' ? (
														<SpecInput
															type="text"
															placeholder="Failure reason"
															value={creditsToUpdate[credit.creditId]?.reason || ''}
															onChange={(e) =>
																setCreditsToUpdate((prevCreditsToUpdate) => ({
																	...prevCreditsToUpdate,
																	[credit.creditId]: {
																		...creditsToUpdate[credit.creditId],
																		reason: e.target.value,
																	},
																}))
															}
															style={{
																marginLeft: 5,
																marginTop: 3,
															}}
														/>
													) : null}
												</>
											) : null}
										</td>
									) : null}
									{getStatusColorAndText(credit?.status)}
									<td className="table-col-2 responsiveText">
										{date || '-'}
										<br />
										{time || '-'}
									</td>
									<td className="table-col-2 responsiveText">{tcreditDayime || '-'}</td>
									<td className="table-col-2 responsiveText">{getAmount(credit?.amount) || '-'}</td>
									<td className="table-col-2 responsiveText">{credit?.reasonCode || '-'}</td>
									<td className="table-col-8 left-data responsiveText">{credit?.comment}</td>
								</tr>
							);
						})}
					</>
				) : null}
			</tbody>
		</table>
	</div>
);

const newCreditDefaultValues = {
	timestampDay: '',
	creditAmount: '',
	reasonCode: 'Goodwill',
	comment: '',
};

function CusotmerCredits({ customerId }) {
	const alert = useAlert();

	const fleetContext = useContext(FleetConext);

	const [view, setView] = useState('Credits');

	const [customerCredit, setCustomerCredit] = useState(null);
	const [customerFiles, setCustomerFiles] = useState([]);

	const [editing, setEditing] = useState(false);

	const [creditsToUpdate, setCreditsToUpdate] = useState({});
	const [loading, setLoading] = useState(false);

	const [addModalVisible, setAddModalVisible] = useState(false);
	const [addCreditsErrorMessage, setAddCreditsErrorMessage] = useState('');

	const [newCredits, setNewCredits] = useState([
		{
			...newCreditDefaultValues,
		},
	]);

	const [downloadState, setDownloadState] = useState(download);

	const timezone = fleetContext?.selectedTimezone
		? fleetContext?.selectedTimezone === 'UTC'
			? 'UTC'
			: fleetContext?.selectedTimezone?.split(' | ')[1]
		: '';
	const selectedTimezone = timezone || 'Europe/London';

	const handleInputChange = (index, event) => {
		const { name, value } = event.target;
		const newRows = [...newCredits];
		newRows[index][name] = value;

		setAddCreditsErrorMessage('');

		setNewCredits(newRows);
	};

	const handleViewChange = (tab) => {
		setView(tab);
		if (!(tab === view && view === 'credits')) {
			setEditing(false);
		}
	};

	const handleDeleteRow = (idx) => {
		const newArr = [...newCredits];
		newArr.splice(idx, 1);

		setNewCredits(newArr);
	};

	const handleAddNewRow = () => {
		setNewCredits((prevState) => [
			...prevState,
			{
				...newCreditDefaultValues,
			},
		]);
	};

	const handleCancelPopup = () => {
		setAddModalVisible(false);
		setAddCreditsErrorMessage('');
		setNewCredits([
			{
				...newCreditDefaultValues,
			},
		]);
	};

	const handleDownloadFile = async (fileId) => {
		if (hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && customerId && fileId) {
			setDownloadState('loading');
			let url;
			try {
				const res = await FleetService.getCustomerFile(customerId, fileId);

				if (res && res?.success && Object.hasOwn(res, 'uri')) {
					const response = await fetch(res.uri);

					if (!response.ok) {
						throw new Error('Network response was not OK');
					}

					const reader = response.body.getReader();
					const chunks = [];
					let done = false;

					while (!done) {
						const { value, done: readerDone } = await reader.read();
						done = readerDone;
						if (value) {
							chunks.push(value);
						}
					}

					const blob = new Blob(chunks, { type: response.headers.get('Content-Type') });

					url = URL.createObjectURL(blob);

					window.open(url, '_blank');
				}

				if (url) {
					URL.revokeObjectURL(url);
				}

				setDownloadState(download);
			} catch (err) {
				console.log(err);
				setDownloadState(download);
			}
		}
	};

	const fetchCustomerCredits = useCallback(async (customerId) => {
		if ((!customerId && customerId !== 0) || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER_FACILITIES)) {
			return;
		}
		try {
			const credits = await FleetService.getCustomerCredits(customerId);
			if (credits) {
				setCreditsToUpdate({});
				setCustomerCredit(credits);
			}
		} catch (err) {
			console.log('In fetch credits: ', err);
		}
	}, []);

	const fetchCustomerFiles = useCallback(async (customerId) => {
		if ((!customerId && customerId !== 0) || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER_FACILITIES)) {
			return;
		}
		try {
			const filesRes = await FleetService.getCustomerFiles(customerId);
			if (filesRes && Object.hasOwn(filesRes, 'files')) {
				setCustomerFiles(filesRes.files);
			}
		} catch (err) {
			console.log('In fetch files: ', err);
		}
	}, []);

	const handleUpdateCredits = async () => {
		if (hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && creditsToUpdate) {
			setLoading(true);
			try {
				const values = getCreditUpdateRequestArr(creditsToUpdate);

				if (values.length === 0) {
					setLoading(false);
					alert.show('Something went wrong. Please try again.');
					return;
				}

				const res = await FleetService.completeCustomerCredits(customerId, { credits: values });

				if (res && res.success) {
					await fetchCustomerCredits(customerId);
					setEditing(false);
					alert.show('Credits updated!', 'success');
				}
				setLoading(false);
			} catch (err) {
				console.log(err);
				alert.show('Something went wrong. Please try again.');
				setLoading(false);
			}
		}
	};

	const handleAddNewCredits = async () => {
		setLoading(true);
		try {
			const creditsObj = getNewCreditsReqObj(newCredits);

			if (creditsObj.valid === false) {
				setAddCreditsErrorMessage(creditsObj?.message || 'Something went wrong.');
				setLoading(false);
				return;
			}

			if (creditsObj.valid && creditsObj.credits.length > 0) {
				const res = await FleetService.createCustomerCredits(customerId, { credits: creditsObj.credits });

				if (res && res.success) {
					await fetchCustomerCredits(customerId);
					setEditing(false);
					setAddModalVisible(false);
					setNewCredits([
						{
							...newCreditDefaultValues,
						},
					]);
					setAddCreditsErrorMessage('');
					alert.show('Credits updated!', 'success');
				}
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
			setAddCreditsErrorMessage('Something went wrong.');
			setLoading(false);
		}
	};

	useEffect(() => {
		if (customerId && !fleetContext.tokenUpdating) {
			fetchCustomerCredits(customerId);
			fetchCustomerFiles(customerId);
		}
	}, [customerId, fleetContext.tokenUpdating, fetchCustomerCredits, fetchCustomerFiles]);

	return (
		<div className="tab-container-customer" data-cy="credits_container">
			<div className="customer-credits-container">
				<div className="customer-screen-headers" style={{ paddingBottom: 1 }}>
					<h2>
						<span
							id="fac-status"
							className={view === 'Credits' ? 'fac-view' : ''}
							onClick={() => handleViewChange('Credits')}
						>
							Credits
						</span>
						<span
							id="fac-status"
							className={view === 'Files' ? 'fac-view' : ''}
							onClick={() => handleViewChange('Files')}
						>
							Files
						</span>
						<span
							id="fac-status"
							className={view === 'Records' ? 'fac-view' : ''}
							onClick={() => handleViewChange('Records')}
						>
							Records
						</span>
					</h2>
					{view === 'Credits' ? (
						<span
							className="right-side-component credits-balance-and-buttons-con"
							style={{
								paddingBottom: 1,
							}}
						>
							Balance:{' '}
							{customerCredit && Object.hasOwn(customerCredit, 'balance')
								? getAmount(customerCredit.balance)
								: getAmount(0)}
							<div className="customer-edit-button-container">
								{editing ? (
									<>
										<Button
											className="buttons-customer cancel-buttons-customer"
											onClick={() => {
												setCreditsToUpdate({});
												setEditing(false);
											}}
											data-cy="credits-cancel-button"
											disabled={loading}
										>
											Cancel
										</Button>

										<Button
											className="buttons-customer done-button-customer"
											onClick={handleUpdateCredits}
											data-cy="credits-done-button"
											disabled={Object.keys(creditsToUpdate).length === 0 || loading}
										>
											{loading ? 'Loading...' : 'Done'}
										</Button>
									</>
								) : (
									<>
										<Button
											className="buttons-customer"
											onClick={() => setEditing(true)}
											data-cy="consent-edit-button"
										>
											Edit
										</Button>
										<AddBtn
											onAddClick={() => {
												setAddModalVisible(true);
											}}
											style={{ marginLeft: 5 }}
										/>
									</>
								)}
							</div>
						</span>
					) : null}
				</div>
				{view === 'Credits' ? (
					<CustomerCreditsTable
						creditsToUpdate={creditsToUpdate}
						selectedTimezone={selectedTimezone}
						editing={editing}
						customerCredit={customerCredit}
						setCreditsToUpdate={setCreditsToUpdate}
					/>
				) : view === 'Files' ? (
					<CustomerFilesTable
						customerFiles={customerFiles}
						downloadState={downloadState}
						selectedTimezone={selectedTimezone}
						handleDownloadFile={handleDownloadFile}
					/>
				) : view === 'Records' ? (
					<div>
						<CustomerRecords customerId={customerId} timezone={timezone} />
					</div>
				) : null}
			</div>
			{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && addModalVisible && (
				<AddCustomerCreditsPopup
					newCredits={newCredits}
					loading={loading}
					errorMessage={addCreditsErrorMessage}
					handleAddNewRow={handleAddNewRow}
					handleDeleteRow={handleDeleteRow}
					handleInputChange={handleInputChange}
					handleAddNewCredits={handleAddNewCredits}
					handleCancelPopup={handleCancelPopup}
				/>
			)}
		</div>
	);
}

export default CusotmerCredits;
