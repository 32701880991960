/* Copyright Levelise Ltd 2021-2025 */
import { useState, useEffect, useRef } from 'react';
import { Scatter } from 'react-chartjs-2';
import { getHours } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import {
    day,
    evening,
    night,
    peak,
    contractCurve1,
    contractCurve2,
    contractCurve1_105,
    contractCurve1_95,
    contractCurve2_105,
    contractCurve2_95,
    line1,
    line2,
    line3,
    dataset,
    options,
    fleetChartTitles
} from '../../../utils/chart';
import {
    TIME_FRAMES,
    resolutions,
    timestampSec,
} from '../../../utils/constants';


const x = {
    type: 'linear',
    label: 'Frequency (Hz)',
};
const x1 = null;
const y = {
    stacked: false,
    ticks: { min: 0, max: 100, stepValue: 10, fontSize: 10 },
    label: 'Power (kW)',
    gridLines: { drawOnChartArea: true }
};
const y1 = null;
const y2 = null;
const legendLabels = {
    font: { size: 10 }, 
    color: 'black',
    usePointStyle: true,
    boxWidth: 4,
    boxHeight: 4,
    padding: 8,
    filter: label => {
        const labels = [
            contractCurve2.type,
            contractCurve1_105.type,
            contractCurve2_95.type,
            contractCurve2_105.type,
            line1.type,
            line2.type,
            line3.type
        ]
        if (!labels.includes(label.text))
            return true;
    }
};

const Dispatch = ({ fmData, data, selectedTimezone }) => {
    const dispatchRef = useRef();
    const [chart, setChart] = useState({
        data: {
            labels: [],
            datasets: [
                dataset('scatter', day.type, day.backgroundColor, day.borderColor, 'y', false, 0, 0.5, 0, 1, 3, 1, 1, 3),
                dataset('scatter', peak.type, peak.backgroundColor, peak.borderColor, 'y', false, 0, 0.5, 0, 1, 3, 1, 1, 3),
                dataset('scatter', evening.type, evening.backgroundColor, evening.borderColor, 'y', false, 0, 0.5, 0, 1, 3, 1, 1, 3),
                dataset('scatter', night.type, night.backgroundColor, night.borderColor, 'y', false, 0, 0.5, 0, 1, 3, 1, 1, 3),


                dataset('line', contractCurve1.type, contractCurve1.backgroundColor, contractCurve1.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', contractCurve2.type, contractCurve2.backgroundColor, contractCurve2.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', contractCurve1_105.type, contractCurve1_105.backgroundColor, contractCurve1_105.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', contractCurve2_105.type, contractCurve2_105.backgroundColor, contractCurve2_105.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', contractCurve1_95.type, contractCurve1_95.backgroundColor, contractCurve1_95.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', contractCurve2_95.type, contractCurve2_95.backgroundColor, contractCurve2_95.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', line1.type, line1.backgroundColor, line1.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', line2.type, line2.backgroundColor, line2.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
                dataset('line', line3.type, line3.backgroundColor, line3.borderColor, 'y', false, 0, 0.5, 0.0, 0.4, 0.3, 1),
            ]
        },
        options: options(fleetChartTitles.dispatch[0], legendLabels, x, x1, y, y1, y2, false)
    });

    const handleDataOnChange = (data) => {
        let resolution = data.resolution;
        if (data.timeFrame !== TIME_FRAMES.select) {
            switch (data.timeFrame) {
                case TIME_FRAMES.fifteen_minutes:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_hour:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.six_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.twenty_four_hours:
                    resolution = resolutions.minute;
                    break;
                case TIME_FRAMES.one_week:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.twenty_one_days:
                    resolution = resolutions.half_hour;
                    break;
                case TIME_FRAMES.three_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.twelve_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.thirty_six_months:
                    resolution = resolutions.day;
                    break;
                case TIME_FRAMES.all:
                    resolution = resolutions.day;
                    break;
                default:
                    break;
            }
        }

        switch (resolution) {
            case resolutions.half_hour:
                populateChartWithRecordKwh(data.reports, data.contract, resolution);
                break
            case resolutions.minute:
                populateChartWithRecordW(data.reports, data.contract, resolution);
                break
            case resolutions.second:
                populateChartWithRecordW(data.reports, data.contract, resolution, data.updated);
                break
            default: {
                let datasets = chart.data.datasets.map(d => {
                    d.data = [];
                    return d;
                });
                handleSetChart([], datasets, data.contract, resolution);
                break;
            }
        }
    }


    const populateChartWithRecordKwh = (reports, contract, resolution) => {
        let labels = [];
        let datasets = chart.data.datasets.map(d => { d.data = []; return d })

        for (let i = reports.length - 1; i >= 0; i--) {
            const hour = getHours(toZonedTime(reports[i][timestampSec] * 1000, selectedTimezone))
            const dispatched = (reports[i]['drDispatchedImportKwh'] - reports[i]['drDispatchedExportKwh']) * 2;
            const frequency = reports[i]['medianFrequencyHz'];

            if (day.start <= hour && hour < day.end)
                datasets[0].data.push({ x: frequency, y: dispatched });
            if (peak.start <= hour && hour < peak.end)
                datasets[1].data.push({ x: frequency, y: dispatched });
            if (evening.start <= hour && hour < evening.end)
                datasets[2].data.push({ x: frequency, y: dispatched });
            if (night.start <= hour || hour < night.end)
                datasets[3].data.push({ x: frequency, y: dispatched });
        }

        datasets = calculateContract(datasets, contract);
        handleSetChart(labels, datasets, contract, resolution);
    }

    const populateChartWithRecordW = (reports, contract, resolution, update = false) => {
        let labels = update ? [...chart.data.labels] : [];
        let datasets = update ? [...chart.data.datasets] : chart.data.datasets.map(d => { d.data = []; return d });
        for (let i = reports.length - 1; i >= 0; i--) {
            const hour = getHours(toZonedTime(reports[i][timestampSec] * 1000, selectedTimezone))
            const dispatched = reports[i]['drDispatchedImportW'] - reports[i]['drDispatchedExportW'];
            const frequency = reports[i]['medianFrequencyHz'];
            if (day.start <= hour && hour < day.end) {
                if (update) datasets[0].data.shift();
                datasets[0].data.push({ x: frequency, y: dispatched / 1000 });
            }

            if (peak.start <= hour && hour < peak.end) {
                if (update) datasets[1].data.shift();
                datasets[1].data.push({ x: frequency, y: dispatched / 1000 });
            }

            if (evening.start <= hour && hour < evening.end) {
                if (update) datasets[2].data.shift();
                datasets[2].data.push({ x: frequency, y: dispatched / 1000 });
            }

            if (night.start <= hour || hour < night.end) {
                if (update) datasets[3].data.shift();
                datasets[3].data.push({ x: frequency, y: dispatched / 1000 });
            }
        }

        datasets = calculateContract(datasets, contract);
        handleSetChart(labels, datasets, contract, resolution);
    }

    const calculateContract = (datasets, contract) => {
        const m = (contract - (-contract)) / (50.5 - 49.5)
        const b = -(m * 50)
        const yOne = m * 49.985 + b;
        const yTwo = m * 50.015 + b;

        const contractedData1 = [{ x: 49.5, y: -contract }, { x: 49.985, y: yOne }];
        const contractedData1_95 = [{ x: 49.5, y: -(contract * 0.95) }, { x: 49.985, y: yOne }];
        const contractedData1_105 = [{ x: 49.5, y: -(contract * 1.05) }, { x: 49.985, y: yOne }];
        const contractedData2 = [{ x: 50.015, y: yTwo }, { x: 50.5, y: contract }];
        const contractedData2_95 = [{ x: 50.015, y: yTwo }, { x: 50.5, y: (contract * 0.95) }];
        const contractedData2_105 = [{ x: 50.015, y: yTwo }, { x: 50.5, y: (contract * 1.05) }];

        const lineData1 = [{ x: 49.985, y: yOne }, { x: 49.985, y: 0 }]
        const lineData2 = [{ x: 49.985, y: 0 }, { x: 50.015, y: 0 }]
        const lineData3 = [{ x: 50.015, y: 0 }, { x: 50.015, y: yTwo }]

        return datasets.map(set => {
            if (set.label === contractCurve1.type && contract > 0)
                contractedData1.map(data => set.data.push(data));

            if (set.label === contractCurve2.type && contract > 0)
                contractedData2.map(data => set.data.push(data));

            if (set.label === contractCurve1_105.type && contract > 0)
                contractedData1_105.map(data => set.data.push(data));

            if (set.label === contractCurve1_95.type && contract > 0)
                contractedData1_95.map(data => set.data.push(data));

            if (set.label === contractCurve2_105.type && contract > 0)
                contractedData2_105.map(data => set.data.push(data));

            if (set.label === contractCurve2_95.type && contract > 0)
                contractedData2_95.map(data => set.data.push(data));

            if (set.label === line1.type && contract > 0)
                lineData1.map(data => set.data.push(data));

            if (set.label === line2.type && contract > 0)
                lineData2.map(data => set.data.push(data));

            if (set.label === line3.type && contract > 0)
                lineData3.map(data => set.data.push(data));

            return set;
        })
    }

    const tooltipCallbacks = () => {
        return {
            ...chart.options.plugins.tooltip.callbacks,
            label: function (context) {
                var label = context.dataset.label || '';
                const labels = [
                    contractCurve1.type,
                    contractCurve2.type,
                    contractCurve1_95.type,
                    contractCurve1_105.type,
                    contractCurve2_95.type,
                    contractCurve2_105.type,
                    line1.type,
                    line2.type,
                    line3.type
                ]
                if (!labels.includes(label)) {
                    return `Frequency: ${context.label}, Power: ${context.formattedValue}`;
                }
            }
        }
    }

    const handleSetChart = (labels, datasets, contract, resolution) => {
        const title = `${fleetChartTitles.dispatch[0]} by ${resolution}`;
        const y = {
            ...chart.options.scales.y, ticks: {
                min: -(contract + (contract * 0.15)),
                max: (contract + (contract * 0.15)),
                minor: { fontSize: 11 },
            }
        };

        const update = {
            data: { labels: labels, datasets: datasets },
            options: {
                ...chart.options,
                plugins: {
                    ...chart.options.plugins,
                    title: { display: true, text: title, position: 'top' },
                    tooltip: { ...chart.options.plugins.tooltips, callbacks: tooltipCallbacks() }
                },
                scales: { ...chart.options.scales, y },
            }
        };

        setChart(update);
    }

    useEffect(() => {
        if (fmData.hasFmData && !!fmData.reports.length) {
            populateChartWithRecordW(fmData.reports, fmData.contract, resolutions.second)
        }
    }, [fmData.reports, selectedTimezone])

    useEffect(() => {
        if (!fmData.hasFmData && data.updated) {
            handleDataOnChange(data);
        }
    }, [data.reports])

    useEffect(() => {
        if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
            handleDataOnChange(data);
        }
    }, [data.resolution, data.reports])

    useEffect(() => {
        if (!fmData.hasFmData) {
            handleDataOnChange(data);
        }
    }, [data.timeFrame, data.type, fmData.hasFmData, selectedTimezone])

    return (
        <div className="fleet-dispatched-chart af-records-chart">
            <Scatter
                id={fleetChartTitles.dispatch[1]}
                ref={dispatchRef}
                data={chart.data}
                options={chart.options}
            />
        </div>
    )
}

export default Dispatch
