/* Copyright Levelise Ltd 2019-2025 */
const config = {
    AUTH_URI: process.env.REACT_APP_AUTH_URI,
    REST_URI: process.env.REACT_APP_REST_URI,
    Auth_Token: 'auth_token',
    User: 'user',
    userDetail: 'user_detail',
    user_color: 'user_color',
    Role: 'role',
    Role_Admin: 'ADMIN',
    preferences: 'preferences',
    status: 'showFleetStatus',
    performance: 'showAfPerformance',
    battery: 'showAfBattery',
    energyData: 'showEnergyPanel', 
    logsPanel: 'showLogsPanel', 
    facilityInfo: 'showFacilityInformationPanel',
    facilityData: 'combinedOrDistinctFacility',
    aggFacilityData: 'combinedOrDistinctAf',
    fleetPageTimezone: 'fleetPageTimezoneId',
    facilityPageTimezone: 'facilityPageTimezoneId',
    state: 'auth_state',
    codeVerifier: 'auth_code_verifier',
    initialPage: 'initialPage',
    showCreditsManagement: 'showCreditsManagement',
    showForecasts: 'showForecasts',
}

export default config;