/* Copyright Levelise Ltd 2025 */
import React from 'react';

const FacilityIcon = ({width, height, ...props}) => {
  return (
    <svg width={width || "35"} height={height || "20"} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 20' {...props}>
        <polyline points='7,10.5 18.5,3 29,10.5' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <polyline points='8,10 8,19 28,19 28,10' stroke='#64B665' fill='none' strokeWidth={0.6}/>
        <line x1="18.5" y1="14.5" x2="18.5" y2="19" stroke="#64B665" strokeWidth={0.6}/>
    </svg>
  );
}

export default FacilityIcon;