/* Copyright Levelise Ltd 2025 */
import { useState } from 'react';
import { useAlert } from '../Alert';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import {
	download,
	resolutions,
	RESOLUTIONS_FOR_CUSTOMER_RECORD_DOWNLOAD,
	validPasswordPattern,
} from '../../utils/constants';
import { formatTimestamp, getResolutionForCustomerDownload, getSinceAndBeforeForCustomerDownload } from '../../utils/utils';
import DownloadBtn from '../DownloadBtn';
import RangePicker from '../RangePicker';
import FleetService from '../../services/fleet-service';
import { subDays, endOfDay } from 'date-fns';
import PopupWithInput from '../PopupWithInput';

const customStyles = () => {
	const borderStyle = '1px solid rgb(180, 180, 180)';
	const _width = 100;
	return {
		menu: (provided) => ({
			...provided,
			marginTop: 4,
			marginBottom: 0,
			padding: 0,
			minWidth: _width,
			width: _width,
		}),
		container: (provided) => ({
			...provided,
			minHeight: 1,
			width: _width,
			textAlign: 'left',
			border: 'none',
			fontSize: '0.72rem',
		}),
		control: (provided) => ({
			...provided,
			minHeight: 1,
			height: 22,
			minWidth: _width,
			width: _width,
			border: borderStyle,
			boxShadow: 'none',
			padding: 0,
			'&:hover': { border: '1px solid grey' },
			'&:focus': { border: '1px solid grey' },
		}),
		input: (provided) => ({
			...provided,
			margin: 0,
			paddingBottom: 0,
			paddingTop: 0,
			minHeight: 20,
			height: 20,
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			height: 20,
			minHeight: 1,
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 2,
			paddingRight: 2,
			transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
		}),
		indicatorContainer: (provided) => ({
			...provided,
			height: 20,
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 2,
			paddingRight: 2,
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			minHeight: 1,
			marginBottom: 4,
			marginTop: 4,
			height: 12,
		}),
		valueContainer: (provided) => ({
			...provided,
			minHeight: 1,
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 4,
			paddingRight: 4,
		}),
		singleValue: (provided) => ({
			...provided,
			minHeight: 0,
			paddingBottom: 0,
			fontSize: '0.72rem',
		}),
	};
};

function CustomerRecords({ customerId, timezone }) {
	const Alert = useAlert();

	const [selectedOption, setSelectedOption] = useState('');

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState(endOfDay(subDays(new Date(), 1)));

	const [loading, setLoading] = useState(false);

	const [sinceParam, setSinceParam] = useState('');
	const [beforeParam, setBeforeParam] = useState('');

	const [password, setPassword] = useState('');
	const [passwordValidationError, setPasswordValidationError] = useState('');
	const [passwordModalVisible, setPasswordModalVisible] = useState(false);

	const resetStates = () => {
		setStartDate('');
		setEndDate(endOfDay(subDays(new Date(), 1)));
		setLoading(false);
		setSelectedOption('');
		setPassword('');
		setPasswordModalVisible(false);
		setPasswordValidationError(false);
	};

	const getFileName = (id, resolution, since, before) => {

		let format, start, end, resolutionStr;

		switch (resolution) {
			case resolutions.week:
				format = 'yyyy-MM-dd';
				start = formatTimestamp(since, timezone, format);
				end = formatTimestamp(before - 7 * 86400, timezone, format);
				resolutionStr = 'weekly';
				break;
			case resolutions.day:
				format = 'yyyy-MM-dd';
				start = formatTimestamp(since, timezone, format);
				end = formatTimestamp(before - 86400, timezone, format);
				resolutionStr = 'daily';
				break;
			default:
				format = "yyyy-MM-dd'T'HH:mm";
				start = formatTimestamp(since, timezone, format);
				end = formatTimestamp(before - 1, timezone, format);
				resolutionStr = 'half_hourly';
		}

		return `Customer_${id}_${resolutionStr}_records${since ? '_since_' + start : ''}${
				before ? '_to_' + end : ''
			}.zip`;
	}

	const getResolutionErrorText = (resolution) => {
		if (resolution === resolutions.half_hour) {
			return 'The maximum number of days for Half-hour resolution is 92 days.'
		}

		if (resolution === resolutions.day) {
			return 'The maximum number of days for Day resolution is 5000 days.'
		}

		return '';
	}

	const handleFetchCsv = async (id, pass, resolution, since, before) => {
		if (!id || !pass || !resolution || !since) {
			Alert.show('Please provide all the required information!');
			return;
		}

		const allowedResolutions = [resolutions.half_hour, resolutions.day, resolutions.week];

		const epxectedResolution = getResolutionForCustomerDownload(since, before, true);
		if (allowedResolutions.indexOf(resolution?.value) < allowedResolutions.indexOf(epxectedResolution)) {
			const resolutionText = getResolutionErrorText(resolution?.value);
			Alert.show(`You are downloading too much data for the selected date range. ${resolutionText}`)
		}

		try {
			setLoading(true);
			const response = await FleetService.getCustomerRecordsAsCSV(id, pass, resolution?.value, since, before);
			if (!response.ok) throw new Error(response);

			const filename = getFileName(id, resolution?.value, since, before)
			const blob = await response.blob();

			saveAs(blob, filename);

			resetStates();
		} catch (error) {
			console.log(error);
			Alert.show('Download failed, please try again with a correct timeframe and resolution.', {
				type: 'error',
			});
			resetStates();
		}
	};

	const handleOnChange = async (value) => {
		const timeFrame = value;

		setSelectedOption(timeFrame);
	};

	const handlePasswordChange = (value) => {
		if (validPasswordPattern.test(value)) {
			setPassword(value);
			setPasswordValidationError('');
		} else {
			setPasswordValidationError('Cannot enter some special characters.');
		}
	};

	const handleOnDateChange = async (start, end) => {
		const [since, before, resolution] = getSinceAndBeforeForCustomerDownload(start, end, timezone);

		setSinceParam(since);
		setBeforeParam(before);
		setSelectedOption(RESOLUTIONS_FOR_CUSTOMER_RECORD_DOWNLOAD.filter(({ value }) => value === resolution)[0]);
	};

	const acceptPassword = () => {
		setPasswordModalVisible(true);
	};

	const handleDownloadFile = () => handleFetchCsv(customerId, password, selectedOption, sinceParam, beforeParam);

	return (
		<div style={{ display: 'flex', flexWrap: 1, alignItems: 'center', paddingTop: 2.5 }}>
			<RangePicker
				handleDateRange={(start, end) => handleOnDateChange(start, end)}
				setStartDate={(startDate) => setStartDate(startDate)}
				setEndDate={(endDate) => setEndDate(endDate)}
				startDate={startDate}
				endDate={endDate}
				padRight
				padLeft
			/>

			<div>
				<Select
					styles={customStyles()}
					value={selectedOption}
					onChange={(value) => handleOnChange(value)}
					placeholder="Resolution"
					options={RESOLUTIONS_FOR_CUSTOMER_RECORD_DOWNLOAD}
				/>
			</div>

			{sinceParam && selectedOption ? (
				<DownloadBtn
					style={{ position: 'relative', marginLeft: 5, width: 'fit-content', paddingInline: 5 }}
					downloadState={loading ? 'loading' : download}
					clickDownload={acceptPassword}
				/>
			) : null}
			{passwordModalVisible && (
				<PopupWithInput
					message="Please provide a password for the downloaded file."
					type="password"
					placeholder="Password"
					value={password}
					setValue={handlePasswordChange}
					showError={passwordValidationError}
					errorMessage={passwordValidationError}
					cancelButtonLabel="Cancel"
					onCancelClick={() => {
						setPassword('');
						setPasswordValidationError('');
						setPasswordModalVisible(false);
					}}
					onPrimaryButtonClicked={handleDownloadFile}
					primaryButtonDisabled={password.length === 0 || loading}
					primaryButtonLabel="Download"
				/>
			)}
		</div>
	);
}

export default CustomerRecords;
