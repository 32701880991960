/* Copyright Levelise Ltd 2024 */
import { useState } from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useAlert } from '../Alert';
import {
	formatTimestamp,
	getChecksLabel,
	getEvidenceType,
	hasPermission,
	isEvidenceAccepted,
	isReadInventoryAvailable,
} from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../Form';
import { PERMISSIONS, loading, upload } from '../../utils/constants';
import UploadBtn from '../UploadBtn';
import DownloadBtn from '../DownloadBtn';

const getStyleForButtonInsideListItem = (editing) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-end',
	alignItems: !editing ? 'flex-start' : 'center',
});

const StripeEvidence = ({ consent }) => {
	const objectHaveStripe = consent && Object.hasOwn(consent, 'evidence') && Object.hasOwn(consent.evidence, 'stripe');
	return (
		<>
			<li className="listItemWithSubList">
				<div className="listItemWithSubList-header">
					<span className="strong customer">Stripe checks</span>
					<span className="em customer">
						{objectHaveStripe && Object.hasOwn(consent.evidence.stripe, 'allChecksPassed')
							? consent.evidence.stripe.allChecksPassed
								? 'Passed'
								: ''
							: '—'}
					</span>
				</div>
				{objectHaveStripe &&
				Object.hasOwn(consent.evidence.stripe, 'allChecksPassed') &&
				!consent.evidence.stripe.allChecksPassed &&
				Object.hasOwn(consent.evidence.stripe, 'checks') &&
				Object.keys(consent.evidence.stripe.checks).length > 0 ? (
					<ul className="listItemWithSubList-listContainer">
						{Object.entries(consent.evidence.stripe.checks).map((ele, idx) => {
							if (!ele[1]) {
								return (
									<li key={idx + 'stripe'}>
										<span className="strong customer">{getChecksLabel(ele[0])}</span>
										<span className="em customer">{ele[1] ? 'Passed' : 'Failed'}</span>
									</li>
								);
							}

							return null;
						})}
					</ul>
				) : null}
			</li>
		</>
	);
};

const ReadInventoryEvidence = ({ consent }) => {
	const objectHaveReadInventory =
		consent && Object.hasOwn(consent, 'evidence') && Object.hasOwn(consent.evidence, 'readInventory');

	return (
		<>
			<li className="listItemWithSubList">
				<div className="listItemWithSubList-header">
					<span className="strong customer">Read-inventory</span>
					<span className="em customer"></span>
				</div>
				<ul className="listItemWithSubList-listContainer">
					<li>
						<span className="strong customer">Postcode</span>
						<span className="em customer">
							{objectHaveReadInventory && Object.hasOwn(consent.evidence.readInventory, 'postcode')
								? consent.evidence.readInventory.postcode
								: '—'}
						</span>
					</li>
					<li>
						<span className="strong customer">Address</span>
						<span className="em customer">
							{objectHaveReadInventory && Object.hasOwn(consent.evidence.readInventory, 'address')
								? consent.evidence.readInventory.address
								: '—'}
						</span>
					</li>
					{objectHaveReadInventory && Object.hasOwn(consent.evidence.readInventory, 'allChecksPassed') ? (
						consent.evidence.readInventory.allChecksPassed ? (
							<li>
								<span className="strong customer">All Checks</span>
								<span className="em customer">Passed</span>
							</li>
						) : null
					) : (
						<li>
							<span className="strong customer">All Checks</span>
							<span className="em customer">—</span>
						</li>
					)}
					{objectHaveReadInventory &&
						Object.hasOwn(consent.evidence.readInventory, 'allChecksPassed') &&
						!consent.evidence.readInventory.allChecksPassed &&
						Object.hasOwn(consent.evidence.readInventory, 'checks') &&
						Object.keys(consent.evidence.readInventory.checks).length > 0 &&
						Object.entries(consent.evidence.readInventory.checks).map((ele, i) => {
							if (!ele[1]) {
								return (
									<li key={i}>
										<span className="strong customer">{getChecksLabel(ele[0])}</span>
										<span className="em customer">{ele[1] ? 'Passed' : 'Not accepted'}</span>
									</li>
								);
							}

							return null;
						})}
				</ul>
			</li>
		</>
	);
};

const checkFileSizeLessThanOneGB = (size) => {
	if (!size && size !== 0) {
		return false;
	}

	return size / 1000000 < 1000;
};

const CustomerConsentInformation = ({
	consent,
	timezone,
	editing,
	handleAcceptEvidenceClick,
	handleWithdrawConsentClick,
	handleWithdrawDSRClick,
	uploadState,
	setUploadState,
	downloadState,
	onUploadSubmit,
	onDownloadClick,
	facility,
}) => {
	const Alert = useAlert();

	const [showEvidence, setShowEvidence] = useState(true);

	const onFileChange = async (e) => {
		setUploadState(loading);
		try {
			// check file length
			if (e.target.files?.length !== 1) {
				Alert.show('Upload failed');
				e.target.value = '';
				setUploadState(upload);
				return;
			}

			const selectedFile = e.target.files[0];

			// check file type
			const allowedFileTypes = ['image/jpeg', 'application/pdf'];
			if (!allowedFileTypes.includes(selectedFile.type)) {
				Alert.show('Invalid file type');
				e.target.value = '';
				setUploadState(upload);
				return;
			}

			// check file size (convert to mb and check its < 1000 mb)
			if (!checkFileSizeLessThanOneGB(selectedFile.size)) {
				Alert.show('File is too large, please upload a smaller size file.');
				e.target.value = '';
				setUploadState(upload);
				return;
			}

			await onUploadSubmit(selectedFile);

			e.target.value = '';
			setUploadState(upload);
		} catch (error) {
			Alert.show('Something went wrong, please try again!');
			e.target.value = '';
			setUploadState(upload);
		}
	};

	const showDSRWithdrawButton = () => {
		if (
			(!consent || !Object.hasOwn(consent, 'consentId') || !consent?.gsaAgreed) &&
			facility &&
			Object.hasOwn(facility, 'drAllowed') &&
			facility.drAllowed
		) {
			return true;
		}

		return false;
	};

	return (
		<ul className="customer-info-container" data-cy="consent-info-container">
			<li>
				<span className="strong customer">Consent Change Date</span>
				<span className="em customer">
					{consent && Object.hasOwn(consent, 'changeTimestampSec')
						? formatTimestamp(consent.changeTimestampSec, timezone, 'dd/MM/yy')
						: '—'}
				</span>
			</li>
			<li>
				<span className="strong customer">Consent Expiry Date</span>
				<span className="em customer">
					{consent && Object.hasOwn(consent, 'expiryTimestampSec')
						? formatTimestamp(consent.expiryTimestampSec, timezone, 'dd/MM/yy')
						: '—'}
				</span>
			</li>
			<li>
				<span className="strong customer">Data Collection Allowed</span>
				<span className="em customer">
					{facility && Object.hasOwn(facility, 'dataCollectionAllowed')
						? facility.dataCollectionAllowed
							? 'Yes'
							: 'No'
						: '—'}
				</span>
			</li>
			<li>
				<span className="strong customer">DSR Allowed</span>
				<span className="em customer">
					<>{facility && Object.hasOwn(facility, 'drAllowed') ? (facility.drAllowed ? 'Yes' : 'No') : '—'}</>
					{editing && showDSRWithdrawButton() ? (
						<Button
							className="buttons-customer withdraw-consent-button-customer"
							style={{ width: 120, marginTop: 2.5 }}
							onClick={handleWithdrawDSRClick}
						>
							Withdraw DSR
						</Button>
					) : null}
				</span>
			</li>
			<li>
				<span className="strong customer">Grid Services Agreement</span>
				<span className="em customer">
					<>{consent && Object.hasOwn(consent, 'gsaAgreed') ? (consent?.gsaAgreed ? 'Yes' : 'No') : 'No'}</>
					{consent && consent?.gsaAgreed && Object.hasOwn(consent, 'gsaAgreedVersion')
						? ` (v${consent.gsaAgreedVersion}${
								consent.gsaAgreedVersion === 1
									? ' - 2023'
									: consent.gsaAgreedVersion === 2
									? ' - 06/24'
									: consent.gsaAgreedVersion === 3
									? ' - 09/24'
									: ''
						  })`
						: ''}
					{editing && consent && Object.hasOwn(consent, 'gsaAgreed') && consent?.gsaAgreed ? (
						<Button
							className="buttons-customer withdraw-consent-button-customer"
							style={{ width: 120, marginTop: 2.5 }}
							onClick={handleWithdrawConsentClick}
						>
							Withdraw Consent
						</Button>
					) : null}
				</span>
			</li>

			<li>
				<span className="strong customer">Half-hourly Collection</span>
				<span className="em customer">
					{consent && Object.hasOwn(consent, 'hhCollectionAgreed')
						? consent?.hhCollectionAgreed
							? 'Yes'
							: 'No'
						: 'No'}
				</span>
			</li>
			<li>
				<span className="strong customer">Demand Flexibility Service</span>
				<span className="em customer">
					{consent && Object.hasOwn(consent, 'dfsAgreed') ? (consent?.dfsAgreed ? 'Yes' : 'No') : 'No'}
				</span>
			</li>

			<li>
				<span className="strong customer">Import MPAN</span>
				<span className="em customer">{consent?.mpanImport || '—'}</span>
			</li>
			<li>
				<span className="strong customer">Export MPAN</span>
				<span className="em customer">{consent?.mpanExport || '—'}</span>
			</li>
			<li>
				<span className="strong customer">Street</span>
				<span className="em customer">{consent?.street || '—'}</span>
			</li>
			<li>
				<span className="strong customer">City</span>
				<span className="em customer">{consent?.city || '—'}</span>
			</li>
			<li>
				<span className="strong customer">County</span>
				<span className="em customer">{consent?.county || '—'}</span>
			</li>
			<li>
				<span className="strong customer">Postcode</span>
				<span className="em customer">{consent?.postcode || '—'}</span>
			</li>
			<li style={{ minHeight: 25, alignItems: 'center' }}>
				<span
					className="strong customer"
					onClick={() => setShowEvidence(!showEvidence)}
					style={{ cursor: 'pointer', fontSize: '0.8rem' }}
					data-cy="consent-evidence"
				>
					{showEvidence ? (
						<FontAwesomeIcon
							icon={faChevronDown}
							style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
						/>
					) : (
						<FontAwesomeIcon
							icon={faChevronRight}
							style={{ marginRight: 5, fontSize: 10, marginBottom: 1, color: '#444444' }}
						/>
					)}
					Evidence
				</span>
				<span className="em customer"> </span>
			</li>
			{showEvidence ? (
				<ul className="collapsable-list-evidence">
					<li>
						<span className="strong customer">Evidence Type</span>
						<span className="em customer">
							{consent &&
							Object.hasOwn(consent, 'evidence') &&
							Object.hasOwn(consent.evidence, 'evidenceTypeId') ? (
								<span
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										justifyContent: 'flex-end',
										alignItems: 'center',
									}}
								>
									{getEvidenceType(consent.evidence.evidenceTypeId)}
									{editing &&
									consent.evidence.evidenceTypeId === 2 &&
									!isEvidenceAccepted(consent) ? (
										<DownloadBtn
											downloadState={downloadState}
											clickDownload={onDownloadClick}
											style={{
												position: 'relative',
												marginLeft: 5,
											}}
										/>
									) : null}
								</span>
							) : (
								<span>
									{editing && consent && Object.hasOwn(consent, 'consentId') ? (
										<label>
											<UploadBtn
												uploadState={uploadState}
												onUploadClick={() => {}}
												style={{
													position: 'relative',
												}}
											/>
											<input
												style={{ display: 'none' }}
												type="file"
												onChange={onFileChange}
												accept="image/jpeg, application/pdf"
												multiple={false}
												disabled={uploadState === loading}
											/>
										</label>
									) : (
										'—'
									)}
								</span>
							)}
						</span>
					</li>

					{consent &&
					Object.hasOwn(consent, 'evidence') &&
					Object.hasOwn(consent.evidence, 'evidenceTypeId') &&
					consent.evidence.evidenceTypeId === 1 ? (
						<StripeEvidence consent={consent} />
					) : null}

					{consent &&
					Object.hasOwn(consent, 'evidence') &&
					Object.hasOwn(consent.evidence, 'evidenceTypeId') &&
					consent.evidence.evidenceTypeId === 2 ? (
						<li>
							<span className="strong customer">Customer Bill</span>
							<span className="em customer">
								{Object.hasOwn(consent.evidence, 'uploadedBill') &&
								Object.hasOwn(consent.evidence.uploadedBill, 'allChecksPassed')
									? consent.evidence.uploadedBill.allChecksPassed
										? 'Passed'
										: 'Not checked'
									: '—'}
							</span>
						</li>
					) : null}

					<ReadInventoryEvidence consent={consent} />
				</ul>
			) : null}

			{consent &&
			Object.hasOwn(consent, 'evidence') &&
			Object.hasOwn(consent.evidence, 'acceptedTimestampSec') &&
			Object.hasOwn(consent.evidence, 'evidenceCheckedBy') &&
			Object.hasOwn(consent.evidence.evidenceCheckedBy, 'userId') &&
			Object.hasOwn(consent.evidence.evidenceCheckedBy, 'username') ? (
				<li>
					<span className="strong customer">Evidence Accepted By</span>
					<span className="em customer">
						{`${consent.evidence.evidenceCheckedBy?.username || ''} ${
							Object.hasOwn(consent.evidence.evidenceCheckedBy, 'userId')
								? '(' + consent.evidence.evidenceCheckedBy?.userId + ')'
								: ''
						}
						`}
					</span>
				</li>
			) : null}
			<li>
				<span className="strong customer">Evidence Accepted Date</span>
				<span className="em customer" style={{ flex: 1 }}>
					{isEvidenceAccepted(consent) ? (
						formatTimestamp(consent.evidence.acceptedTimestampSec, timezone, 'dd/MM/yy')
					) : (
						<span style={getStyleForButtonInsideListItem(editing)}>
							{!editing ? 'Not accepted' : null}
							{editing ? (
								<>
									{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
									consent &&
									Object.hasOwn(consent, 'evidence') &&
									Object.hasOwn(consent.evidence, 'evidenceTypeId') &&
									isReadInventoryAvailable(consent) ? (
										<Button
											className="buttons-customer done-button-customer"
											style={{ width: 120, marginTop: 2.5 }}
											onClick={handleAcceptEvidenceClick}
										>
											Accept Evidence
										</Button>
									) : (
										'Not accepted'
									)}
								</>
							) : null}
						</span>
					)}
				</span>
			</li>
		</ul>
	);
};

export default CustomerConsentInformation;
