/* Copyright Levelise Ltd 2020-2025 */
import { useState, useEffect, useContext, useRef } from 'react';
import { getUnixTime, subDays } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { Chart } from 'chart.js';
import { getSinceAndBefore } from '../../utils/utils'
import FleetService from '../../services/fleet-service';
import FleetContext from '../../contexts/FleetContext';

import ConnectedChart from './charts/connected';
import ConnectedByBsChart from './charts/connectedByBs';
import SocChart from './charts/soc';
import SocByBsChart from './charts/socByBs';
import TemperatureChart from './charts/temperature';
import TemperatureByBsChart from './charts/temperatureByBs';

import { CONTRACT_TIMEFRAME, TIME_FRAMES, fleet, timestampSec, timestampDay, resolutions } from '../../utils/constants';
import { filterDataByTimeframe } from '../../utils/utils';
import './index.css';


const FleetBatteryChart = ({ view, battery, showPerformance }) => {
    const fleetContext = useContext(FleetContext);
    const intervalId = useRef();
    const hasFmData = useRef(null);
    const [fmData, setfmData] = useState({ hasFmData: false, soc: [], temperature: [], connected: [] });
    const [currentTimeFrame, setCurrentTimeFrame] = useState('');
    const [currentType, setCurrentType] = useState('');
    const [data, setData] = useState({
        type: '',
        timeFrame: '',
        soc: [],
        temperature: [],
        connected: [],
        resolution: '',
        updated: false
    });

    const days = {
        [TIME_FRAMES.thirty_six_months]: 1096,
        [TIME_FRAMES.twelve_months]: 366,
        [TIME_FRAMES.three_months]: 91,
    }

    const half_hours = {
        [TIME_FRAMES.twenty_one_days]: 1440 * 60 * 21,
        [TIME_FRAMES.one_week]: 1440 * 60 * 7
    }

    const minutes = {
        [TIME_FRAMES.twenty_four_hours]: 1440 * 60,
        [TIME_FRAMES.twelve_hours]: 720 * 60,
        [TIME_FRAMES.six_hours]: 360 * 60,
        [TIME_FRAMES.one_hour]: 60 * 60,
        [TIME_FRAMES.fifteen_minutes]: 15 * 60,
    }

    const handleSetSystemIds = (reports) => {
        const typeIds = [];
        const { selectedTimeFrame, currentDataType } = fleetContext;
        const hasTimeFrameChange = !currentTimeFrame || currentTimeFrame !== selectedTimeFrame;
        if (reports && (hasTimeFrameChange || currentDataType !== currentType)) {
            setCurrentTimeFrame(selectedTimeFrame);
            setCurrentType(currentDataType);
            for (let i = reports.length - 1; i >= 0; i--) {
                Object.keys(reports[i]['byType']).forEach(typeId => {
                    if (!typeIds.includes(typeId)) typeIds.push(typeId);
                })
            }

            if (typeIds.length) {
                const systemSet = new Set([...fleetContext.batterySystemTypes, ...typeIds])
                fleetContext.setBatterySystemTypes([...systemSet]);
            }
        }
    }

    const fetchSoc = async (resolution, since = null, before = null) => {
        if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === "") {
            try {
                const res = await FleetService.getFleetSoc(resolution, since, before);
                return res;
            } catch (err) {
                fleetContext.setError(err);
                return await Promise.reject(err);
            }
        }
        try {
            const res_1 = await FleetService.getAggFacilitySoc(fleetContext.currentDataType, resolution, since, before);
            return res_1;
        } catch (err_1) {
            fleetContext.setError(err_1);
            return await Promise.reject(err_1);
        }
    }

    const fetchTemp = async (resolution, since = null, before = null) => {
        if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === "") {
            try {
                const res = await FleetService.getFleetTemp(resolution, since, before);
                return res;
            } catch (err) {
                fleetContext.setError(err);
                return await Promise.reject(err);
            }
        }
        try {
            const res_1 = await FleetService.getAggFacilityTemp(fleetContext.currentDataType, resolution, since, before);
            return res_1;
        } catch (err_1) {
            fleetContext.setError(err_1);
            return await Promise.reject(err_1);
        }
    }

    const fetchConnected = async (resolution, since = null, before = null) => {
        if (fleetContext.currentDataType === fleet || fleetContext.currentDataType === "") {
            try {
                const res = await FleetService.getFleetConnectedDrus(resolution, since, before);
                return res;
            } catch (err) {
                fleetContext.setError(err);
                return await Promise.reject(err);
            }
        }
        try {
            const res_1 = await FleetService.getAggFacilityConnectedDrus(fleetContext.currentDataType, resolution, since, before);
            return res_1;
        } catch (err_1) {
            fleetContext.setError(err_1);
            return await Promise.reject(err_1);
        }
    }

    const doUpdate = () => {
        return !(hasFmData.current ||
            Object.values(Chart.instances).some(ci => ci.tooltip?._active?.length) ||
            Object.values(Chart.instances).some(ci => ci.getZoomLevel() > 1.0))
    }

    const handleTimeframeChange = () => {
        const type = fleetContext.currentDataType;
        const timeFrame = fleetContext.selectedTimeFrame;

        switch (timeFrame) {
            case TIME_FRAMES.all:
                Promise.all([fetchSoc(resolutions.week), fetchTemp(resolutions.week), fetchConnected(resolutions.week)])
                    .then(d => {
                        if (d) {
                            handleSetData(type, timeFrame, d[0], d[1], d[2], resolutions.week);
                        }
                    })
                break;
            case TIME_FRAMES.thirty_six_months:
            case TIME_FRAMES.twelve_months:
            case TIME_FRAMES.three_months:
                Promise.all([fetchSoc(resolutions.day), fetchTemp(resolutions.day), fetchConnected(resolutions.day)])
                    .then(d => {
                        if (d) {
                            const filteredSoc = filterDataByTimeframe(d[0], days[timeFrame], timestampDay);
                            const filteredTemp = filterDataByTimeframe(d[1], days[timeFrame], timestampDay);
                            const filteredConnected = filterDataByTimeframe(d[2], days[timeFrame], timestampDay);
                            handleSetData(type, timeFrame, filteredSoc, filteredTemp, filteredConnected, resolutions.day);
                        }
                    })
                break;
            case TIME_FRAMES.twenty_one_days:
            case TIME_FRAMES.one_week:
                Promise.all([fetchSoc(resolutions.half_hour), fetchTemp(resolutions.half_hour), fetchConnected(resolutions.half_hour)])
                    .then(d => {
                        if (d) {
                            const filteredSoc = filterDataByTimeframe(d[0], half_hours[timeFrame], timestampSec);
                            const filteredTemp = filterDataByTimeframe(d[1], half_hours[timeFrame], timestampSec);
                            const filteredConnected = filterDataByTimeframe(d[2], half_hours[timeFrame], timestampSec);
                            handleSetData(type, timeFrame, filteredSoc, filteredTemp, filteredConnected);
                        }
                    })
                break;
            case TIME_FRAMES.twenty_four_hours:
            case TIME_FRAMES.six_hours:
            case TIME_FRAMES.one_hour:
                Promise.all([fetchSoc(resolutions.minute), fetchTemp(resolutions.minute), fetchConnected(resolutions.minute)])
                    .then(d => {
                        if (d) {
                            if (timeFrame !== TIME_FRAMES.twenty_four_hours) {
                                d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                                d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
                                d[2] = filterDataByTimeframe(d[2], minutes[timeFrame], timestampSec);
                            }
                            handleSetData(type, timeFrame, d[0], d[1], d[2]);
                        }
                    })

                intervalId.current = setInterval(() => {
                    if (doUpdate()) {
                        Promise.all([fetchSoc(resolutions.minute), fetchTemp(resolutions.minute), fetchConnected(resolutions.minute)])
                            .then(d => {
                                if (d) {
                                    if (timeFrame !== TIME_FRAMES.twenty_four_hours) {
                                        d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                                        d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
                                        d[2] = filterDataByTimeframe(d[2], minutes[timeFrame], timestampSec);
                                    }
                                    handleSetData(type, timeFrame, d[0], d[1], d[2], '', true);
                                }
                            })
                    }
                }, 5 * 60000);
                break;
            case TIME_FRAMES.fifteen_minutes:
                Promise.all([fetchSoc(resolutions.minute), fetchTemp(resolutions.minute), fetchConnected(resolutions.second)])
                    .then(d => {
                        if (d) {
                            d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                            d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
                            handleSetData(type, timeFrame, d[0], d[1], d[2] || []);
                        }
                    })

                intervalId.current = setInterval(() => {
                    if (doUpdate()) {
                        Promise.all([fetchSoc(resolutions.minute), fetchTemp(resolutions.minute), fetchConnected(resolutions.second)])
                            .then(d => {
                                if (d) {
                                    d[0] = filterDataByTimeframe(d[0], minutes[timeFrame], timestampSec);
                                    d[1] = filterDataByTimeframe(d[1], minutes[timeFrame], timestampSec);
                                    handleSetData(type, timeFrame, d[0], d[1], d[2] || [], '', true);
                                }
                            });
                    }
                }, 6000);
                break;
            case TIME_FRAMES.select: {
                const { startDate, endDate, timezone } = fleetContext;
                if (startDate && endDate) {
                    const [since, before, resolution] = getSinceAndBefore(startDate, endDate, timezone);
                    if (since < before) {
                        Promise.all([fetchSoc(resolution, since, before), fetchTemp(resolution, since, before), fetchConnected(resolution, since, before)])
                            .then(d => {
                                if (d) {
                                    handleSetData(type, timeFrame, d[0] || [], d[1] || [], d[2] || [], resolution, false);
                                }
                            })
                    }
                }
                break;
            }
        }
    }

    const handleFmData = (since) => {
        const allowedTimeFrames = [...CONTRACT_TIMEFRAME, TIME_FRAMES.select];
        if (allowedTimeFrames.indexOf(fleetContext.selectedTimeFrame) >= 0) {
            if (fleetContext.selectedTimeFrame === TIME_FRAMES.select) {
                const timestamp = getUnixTime(fromZonedTime(subDays(new Date(), 15), fleetContext.timezone));
                if (data.resolution !== resolutions.minute || since < timestamp)
                    return;
            }

            const soc = fetchSoc(resolutions.minute, since, since + 900);
            const temp = fetchTemp(resolutions.minute, since, since + 900);
            const connected = fetchConnected(resolutions.second, since);
            Promise.all([soc, temp, connected]).then(d => {
                if (d) {
                    hasFmData.current = true;
                    setfmData({ hasFmData: true, soc: d[0], temperature: d[1], connected: d[2] });
                }
            })
        }
    }

    const handleSetData = (type, timeFrame, soc, temperature, connected, resolution = '', updated = false) => {
        handleSetSystemIds([...soc, ...temperature, ...connected]);
        setData({ type, timeFrame, soc, temperature, connected, resolution, updated });
        if (fmData.hasFmData) {
            hasFmData.current = false;
            setfmData({ hasFmData: false, soc: [], temperature: [], connected: [] });
        }
    }

    const extractSelectedTimezone = () => {
        if (fleetContext.selectedTimezone.length) {
            const list = fleetContext.selectedTimezone.split(' | ');
            return list[list.length - 1].trim();
        }

        return "";
    }

    useEffect(() => {
        if (fmData.hasFmData) {
            hasFmData.current = false;
            setfmData({ hasFmData: false, soc: [], temperature: [], connected: [] })
        }
    
    }, [fleetContext.resetZoom])

    useEffect(() => {
        const { currentDataType, timezone } = fleetContext;
        if (view === battery && currentDataType && timezone) {
            clearInterval(intervalId.current);
            handleTimeframeChange();
        }
    
    }, [
        fleetContext.currentDataType,
        fleetContext.timezone,
        fleetContext.selectedTimeFrame,
        fleetContext.startDate,
        fleetContext.endDate
    ])

    useEffect(() => {
        return () => clearInterval(intervalId.current);
    }, [])

    return (
        <>
            <div className="left-graphs-wrapper">
                <ConnectedChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    aggregation={fleetContext.currentDataType}
                    showPerformance={showPerformance}
                />
                <SocChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <TemperatureChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
            </div>
            <div className="right-graphs-wrapper">
                <ConnectedByBsChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <SocByBsChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
                <TemperatureByBsChart
                    fmData={fmData}
                    handleFmData={(timestamp) => handleFmData(timestamp)}
                    data={data}
                    timezone={fleetContext.timezone}
                    selectedTimezone={extractSelectedTimezone()}
                    showPerformance={showPerformance}
                />
            </div>
        </>
    )
}

export default FleetBatteryChart;