/* Copyright Levelise Ltd 2019 - 2025 */
import { useContext, useEffect, useState } from 'react';
import { SpecInput, SpecLabel } from '../Form/index';
import Select from 'react-select';
import FleetContext from '../../contexts/FleetContext';
import { PERMISSIONS, PV_NET_CAPACITY_SHOULD_BE_IN_WATTS } from '../../utils/constants';
import { hasPermission, getOptions, getFacilityCode } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { customStylesForEdit } from '../SpecificationForm/customStyles';
import SwitchComponent from '../reusableInputComponents/SwitchComponent';
import InputWithErrorMessageAndUnit from '../reusableInputComponents/InputWithErrorMessageAndUnit';
import ExpandableListItem from '../ExpandableLIstItem';
import FleetService from '../../services/fleet-service';

const CHANGE_DRU_ID = 'change_dru_id';
const CHANGE_BATTERY_SYSTEM = 'change_battery_system';
const CHANGE_PV_NET_CAPACITY = 'change_pv_net_capacity';
const CHANGE_RS485 = 'change_RS485';
const CHANGE_HAS_3_PHASE_METERING = 'change_has_3_phase_metering';
const CHANGE_HAS_GRID_METERING = 'change_has_grid_metering';
const CHANGE_INVERT_PV = 'change_invert_pv';
const CHANGE_INVERT_GRID = 'change_invert_grid';
const CHANGE_INVERT_DRU_INVERTER = 'change_invert_dru_inverter';
const CHANGE_INVERT_DRU_GRID = 'change_invert_dru_grid';
const CHANGE_INVERT_DRU_HW = 'change_invert_dru_hot_water';
const CHANGE_BS_UNIT = 'change_bs_unit';

const getPorts = () => {
	return { 1: '1', 2: '2' };
};

const extractManufacturerAndBS = (bs) => {
	const inverterBattery = bs.split(' - '); // [inverter, battery]
	const systemArr = bs.split(' ');
	let manufacturer = systemArr[0];
	let bn = '1';
	let inverter = inverterBattery[0] || '';
	let battery = bs;

	if (systemArr[0].toLocaleLowerCase().includes('sim')) {
		manufacturer = `${systemArr[0]} ${systemArr[1]}`;
	}

	if (systemArr[0].toLocaleLowerCase().includes('goodwe')) {
		manufacturer = `${systemArr[0]} / Pand`;
	}

	if (systemArr[0].toLocaleLowerCase().includes('fox') && !systemArr[0].toLocaleLowerCase().includes('ess')) {
		manufacturer = `${systemArr[0]} ESS`;
	}

	if (inverterBattery.length > 1) {
		const batteryWithCount = inverterBattery.slice(1).join(' - ').split(' x '); // [number of battery, battery]
		if (batteryWithCount.length >= 2) {
			bn = batteryWithCount[0];
			battery = batteryWithCount[1];
		} else {
			battery = batteryWithCount[0];
		}
		inverter = inverterBattery[0];
	}

	return { manufacturerRes: manufacturer, inverter, batteryCount: bn, battery };
};

/**
 * @param batterySystemsArray
 * @returns {
 *  {
 *
 * 	meteringTypeId: {
 * 		name: '',
 *    	manufacturer: {
 * 			inverter: {
 * 				count: {
 *          		'1': 'manufacturer inverter 1 - 1 x battery'
 *          	}
 * 			}
 * 		}
 * 	}
 *
 *  }
 * }
 *
 *  takes battery system array and returns an object in the above structure
 *  */
const getBatterySystemsObjectStructure = (batterySystemsArray) => {
	const batterySystemsObject = {};

	if (!batterySystemsArray || !batterySystemsArray?.length) {
		return batterySystemsObject;
	}

	batterySystemsArray.forEach((batterySys) => {
		if (batterySys?.name) {
			const { manufacturerRes, inverter, batteryCount, battery } = extractManufacturerAndBS(batterySys?.name);

			const countObj = {};
			countObj[batterySys.id] = battery;

			const inverterObj = {};
			inverterObj[batteryCount] = countObj;

			const manufacturerObj = {};
			manufacturerObj[inverter] = inverterObj;

			const meteringTypeObj = {};
			meteringTypeObj[manufacturerRes] = manufacturerObj;
			meteringTypeObj['name'] = batterySys?.meteringType?.name;

			if (Object.hasOwn(batterySystemsObject, batterySys?.meteringType?.id)) {
				if (Object.hasOwn(batterySystemsObject[batterySys?.meteringType?.id], manufacturerRes)) {
					if (Object.hasOwn(batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes], inverter)) {
						if (
							Object.hasOwn(
								batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter],
								batteryCount
							)
						) {
							// add battery system
							batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter][batteryCount][
								batterySys.id
							] = battery;
						} else {
							// add count
							batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter][
								batteryCount
							] = countObj;
						}
					} else {
						// add inverter
						batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes][inverter] = inverterObj;
					}
				} else {
					// add manufacturer
					batterySystemsObject[batterySys?.meteringType?.id][manufacturerRes] = manufacturerObj;
				}
			} else {
				// add metering type
				batterySystemsObject[batterySys?.meteringType?.id] = meteringTypeObj;
			}
		}
	});

	return batterySystemsObject;
};

const FacilityInfoSpecifications = ({
	editing,
	facilityContext,
	customer,
	facility,
	state,
	dispatch,
	pvNetCapacityError,
	setPvNetCapacityError,
	druIdError,
	setDruIdError,
}) => {
	const navigate = useNavigate();

	const fleetContext = useContext(FleetContext);

	const [hasCustomer, setHasCustomer] = useState(false);
	const [reversedMetering, setReversedMetering] = useState([]);

	const [meteringType, setMeteringType] = useState(null);
	const [manufacturer, setManufacturer] = useState(null);
	const [inverter, setInverter] = useState(null);
	const [batteryCount, setBatteryCount] = useState(null);

	const [meteringTypes, setMeteringTypes] = useState([]);
	const [manufacturers, setManufacturers] = useState([]);
	const [inverters, setInverters] = useState([]);
	const [batteryCounts, setBatteryCounts] = useState([]);
	const [batterySystems, setBatterySystems] = useState([]);
	const [batterySystemObject, setBatterySystemObject] = useState(null);

	const [aggregatedFacilities, setAggregatedFacilities] = useState([]);

	const onSeeMorePressed = () => {
		if (customer?.customerId) {
			navigate(`/customer/${customer.customerId}`);
		}
	};

	const setBatterySystem = (val) => {
		dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: val });
	};

	const handleMeteringTypeChange = (val) => {
		setMeteringType(val);
	};

	const handleSetManufacturer = (val) => {
		setManufacturer(val);
	};

	const handleInverterChange = (val) => {
		setInverter(val);
	};

	const handleBatteryCountChange = (val) => {
		setBatteryCount(val);
	};

	const onPVNetCapacityChange = (e) => {
		if (!isNaN(e.target.value)) {
			if (e.target.value < 100 && e.target.value !== '0') {
				setPvNetCapacityError(PV_NET_CAPACITY_SHOULD_BE_IN_WATTS);
			} else {
				setPvNetCapacityError('');
			}
		}

		if (!e.target.value && !facilityContext.facility?.pvNetCapacityW) {
			setPvNetCapacityError('');
		}

		dispatch({ type: CHANGE_PV_NET_CAPACITY, payload: parseInt(e.target.value) });
	};

	const handleDruChange = (e) => {
		setDruIdError('');
		dispatch({ type: CHANGE_DRU_ID, payload: e.target.value });
	};

	const setAggregatedFacility = (payload) => {
		dispatch({ type: CHANGE_BS_UNIT, payload });
	};

	useEffect(() => {
		if (editing && hasPermission(PERMISSIONS.CAN_EDIT_AF)) {
			async function getBSUnits() {
				try {
					let aggFacilities = fleetContext.aggFacilities;
					let bsUnits = [];

					if (!aggFacilities || aggFacilities.length === 0) {
						const res = await FleetService.getAfsList();
						if (res && res?.afs) {
							aggFacilities = res.afs;
						}
					}

					for (let i = 0; i < aggFacilities.length; i++) {
						if (Object.hasOwn(aggFacilities[i], 'name')) {
							bsUnits.push(aggFacilities[i].name);
						}
						if (
							Object.hasOwn(aggFacilities[i], 'constituents') &&
							aggFacilities[i].constituents.length > 0
						) {
							aggFacilities[i].constituents.forEach((ele) => {
								if (Object.hasOwn(ele, 'name')) {
									bsUnits.push(ele.name);
								}
							});
						}
					}

					setAggregatedFacilities(bsUnits);
				} catch (err) {
					console.log(err);
				}
			}

			getBSUnits();
		}
	}, [editing, fleetContext.aggFacilities]);

	useEffect(() => {
		if (facilityContext?.facility?.batterySystem?.name) {
			const batteryValues = extractManufacturerAndBS(facilityContext.facility.batterySystem.name);

			setMeteringType({
				value: facilityContext.facility.batterySystem.meteringType.id,
				label: facilityContext.facility.batterySystem.meteringType.name,
			});
			setManufacturer({ value: batteryValues.manufacturerRes, label: batteryValues.manufacturerRes });
			setInverter({ value: batteryValues.inverter, label: batteryValues.inverter });
			setBatteryCount({ value: batteryValues.batteryCount, label: batteryValues.batteryCount });
			setBatterySystem({
				value: facilityContext.facility.batterySystem.id,
				label: `${batteryValues.battery} ${
					editing ? ' (' + facilityContext.facility.batterySystem.id + ')' : ''
				}`,
			});
		}
	}, [facilityContext?.facility, editing]);

	useEffect(() => {
		setHasCustomer(customer && !!Object.keys(customer).length);
	}, [customer]);

	useEffect(() => {
		if (facilityContext?.facility) {
			dispatch({ type: CHANGE_DRU_ID, payload: facilityContext.facility.druId });

			const reversedMeteringArr = [
				[facilityContext.facility?.invertPv, 'PV'],
				[facilityContext.facility?.invertGrid, 'Grid'],
				[facilityContext.facility?.invertDruInverter, 'DRU Inverter'],
				[facilityContext.facility?.invertDruGrid, 'DRU Grid'],
				[facilityContext.facility?.invertDruHotWater, 'DRU Hot Water'],
			].filter((m) => m[0]);

			setReversedMetering(reversedMeteringArr);

			dispatch({ type: CHANGE_INVERT_PV, payload: facilityContext.facility?.invertPv });
			dispatch({ type: CHANGE_INVERT_GRID, payload: facilityContext.facility?.invertGrid });
			dispatch({
				type: CHANGE_INVERT_DRU_INVERTER,
				payload: facilityContext.facility?.invertDruInverter,
			});
			dispatch({ type: CHANGE_INVERT_DRU_GRID, payload: facilityContext.facility?.invertDruGrid });
			dispatch({ type: CHANGE_INVERT_DRU_HW, payload: facilityContext.facility?.invertDruHotWater });

			dispatch({ type: CHANGE_PV_NET_CAPACITY, payload: facilityContext.facility?.pvNetCapacityW });
			dispatch({
				type: CHANGE_RS485,
				payload: {
					value: facilityContext.facility?.rs485,
					label: facilityContext.facility?.rs485,
				},
			});
			dispatch({ type: CHANGE_HAS_3_PHASE_METERING, payload: facilityContext.facility?.has3PhaseMetering });
			dispatch({ type: CHANGE_HAS_GRID_METERING, payload: facilityContext.facility?.hasGridMeter });

			let bsUnit = '';
			if (facilityContext?.facility?.aggregatedFacility) {
				bsUnit = facilityContext?.facility?.aggregatedFacility?.name;
			}
			const payload = {
				value: bsUnit,
				label: bsUnit,
			};
			setAggregatedFacility(payload);

			setPvNetCapacityError('');
		}
	}, [facilityContext?.facility, editing]);

	useEffect(() => {
		if (editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY)) {
			function getManufacturers() {
				const { batterySystemSpecList } = fleetContext;

				if (batterySystemSpecList) {
					const batterySystemsObject = getBatterySystemsObjectStructure(batterySystemSpecList);

					setBatterySystemObject(batterySystemsObject);
				}
			}

			getManufacturers();
		}
	}, [editing, fleetContext]);

	// set metering types
	useEffect(() => {
		if (batterySystemObject) {
			const meteringTypeIds = Object.keys(batterySystemObject);
			const options = meteringTypeIds.map((id) => ({ value: id, label: batterySystemObject[id]?.name || '' }));

			if (meteringType && !meteringTypeIds.includes(`${meteringType.value}`)) {
				setManufacturer(null);
				setInverter(null);
				setBatteryCount(null);
				dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

				setInverters([]);
				setBatteryCounts([]);
				setBatterySystems([]);
				setManufacturers([]);
			}

			setMeteringTypes(options);
		}
	}, [batterySystemObject, editing]);

	// set manufacturer list
	useEffect(() => {
		if (meteringType && batterySystemObject) {
			if (batterySystemObject[meteringType.value]) {
				const manuList = Object.keys(batterySystemObject[meteringType.value]).filter((key) => key !== 'name');
				manuList.sort((a, b) => a.localeCompare(b));

				const options = getOptions(manuList);

				if (manufacturer && !manuList.includes(manufacturer.value)) {
					setManufacturer(null);
					setInverter(null);
					setBatteryCount(null);
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

					setInverters([]);
					setBatteryCounts([]);
					setBatterySystems([]);
				}

				if (options.length === 1) {
					setManufacturer(options[0]);
				}

				setManufacturers(options);
			}
		}
	}, [meteringType, batterySystemObject, editing]);

	// set inverter list
	useEffect(() => {
		if (manufacturer && meteringType && batterySystemObject) {
			if (
				batterySystemObject[meteringType.value] &&
				batterySystemObject[meteringType.value][manufacturer.value]
			) {
				const inverterKeys = Object.keys(batterySystemObject[meteringType.value][manufacturer.value]);
				inverterKeys.sort((a, b) => a.localeCompare(b));

				const options = getOptions(inverterKeys);

				if (inverter && !inverterKeys.includes(inverter.value)) {
					setInverter(null);
					setBatteryCount(null);
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

					setBatteryCounts([]);
					setBatterySystems([]);
				}

				if (options.length === 1) {
					setInverter(options[0]);
				}

				setInverters(options);
			}
		}
	}, [manufacturer, meteringType, batterySystemObject, editing]);

	// set battery count list
	useEffect(() => {
		if (inverter && manufacturer && meteringType && batterySystemObject) {
			if (
				batterySystemObject[meteringType.value] &&
				batterySystemObject[meteringType.value][manufacturer.value] &&
				batterySystemObject[meteringType.value][manufacturer.value][inverter.value]
			) {
				const countKeys = Object.keys(
					batterySystemObject[meteringType.value][manufacturer.value][inverter.value]
				);
				countKeys.sort((a, b) => a.localeCompare(b));

				const options = getOptions(countKeys);

				if (batteryCount && !countKeys.includes(batteryCount.value)) {
					setBatteryCount(null);

					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });

					setBatterySystems([]);
				}

				if (options.length === 1) {
					setBatteryCount(options[0]);
				}

				setBatteryCounts(options);
			}
		}
	}, [inverter, manufacturer, meteringType, batterySystemObject, editing]);

	useEffect(() => {
		if (batteryCount && inverter && manufacturer && meteringType && batterySystemObject) {
			if (
				batterySystemObject[meteringType.value] &&
				batterySystemObject[meteringType.value][manufacturer.value] &&
				batterySystemObject[meteringType.value][manufacturer.value][inverter.value] &&
				batterySystemObject[meteringType.value][manufacturer.value][inverter.value][batteryCount.value]
			) {
				const options = getOptions(
					batterySystemObject[meteringType.value][manufacturer.value][inverter.value][batteryCount.value],
					true
				);

				if (
					options &&
					state.batterySystem &&
					!options.find((ele) => Number(ele.value) === Number(state.batterySystem?.value))
				) {
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: '' });
				}

				if (options.length === 1) {
					dispatch({ type: CHANGE_BATTERY_SYSTEM, payload: options[0] });
				}

				setBatterySystems(options);
			}
		}
	}, [batteryCount, inverter, manufacturer, meteringType, batterySystemObject, editing]);

	if (!Object.keys(facilityContext?.facility).length) {
		return;
	}

	return (
		<>
			<li>
				<span className="strong specification">Facility ID (Code) </span>
				<span className="em specification">
					{facility.id ? `${facility.id} (${getFacilityCode(facility.id) || '—'})` : '—'}
				</span>
			</li>
			<li>
				<span className="strong specification">Current DRU ID </span>
				<span className="em specification">
					{editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY) ? (
						<InputWithErrorMessageAndUnit
							id="druId"
							type="number"
							name="druId"
							className="form-right"
							value={state.druId}
							onChange={handleDruChange}
							placeholder="DRU ID"
							style={{ height: 23, maxWidth: 150 }}
							errorMessage={druIdError}
						/>
					) : (
						facilityContext.facility.druId
					)}
				</span>
			</li>
			{hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) ? (
				<li>
					<span className="strong specification">Customer </span>
					<span className="em specification">
						{hasCustomer && customer?.name ? customer.name : ''}{' '}
						{hasCustomer && customer?.surname ? customer.surname : ''}{' '}
						{hasCustomer ? (
							<span
								style={{
									color: 'blue',
									textDecoration: 'underline',
									cursor: 'pointer',
									fontSize: 10,
									whiteSpace: 'nowrap',
								}}
								onClick={onSeeMorePressed}
							>
								See more
							</span>
						) : (
							'—'
						)}
					</span>
				</li>
			) : null}
			<li>
				<span className="strong specification">PV Net Capacity </span>
				<span className="em specification">
					{editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY) ? (
						<InputWithErrorMessageAndUnit
							id="pvNetCapacityW"
							type="number"
							name="pvNetCapacityW"
							className="form-right"
							value={state.pvNetCapacityW}
							onChange={onPVNetCapacityChange}
							placeholder="PV Net Capacity in W"
							style={{ height: 23, maxWidth: 150 }}
							errorMessage={pvNetCapacityError}
							unit="W"
						/>
					) : facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'pvNetCapacityW') ? (
						`${facilityContext.facility.pvNetCapacityW} W`
					) : (
						'—'
					)}
				</span>
			</li>
			{facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'batterySystem') ? (
				<>
					{editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY) ? (
						<li>
							<span className="strong specification">Battery System</span>
							<span className="em specification">
								<div>
									<Select
										id="meteringTypes"
										name="meteringTypes"
										className="form-right"
										styles={customStylesForEdit()}
										value={meteringType}
										onChange={handleMeteringTypeChange}
										placeholder="Select metering type"
										options={meteringTypes}
									/>
								</div>
								<div
									style={{
										marginTop: 6,
									}}
								>
									<Select
										id="manufacturers"
										name="manufacturers"
										className="form-right"
										styles={customStylesForEdit()}
										value={manufacturer}
										onChange={handleSetManufacturer}
										placeholder="Select manufacturer"
										options={manufacturers}
									/>
								</div>
								<div
									style={{
										marginTop: 6,
									}}
								>
									<Select
										id="inverters"
										name="inverters"
										className="form-right"
										styles={customStylesForEdit()}
										value={inverter}
										onChange={handleInverterChange}
										placeholder="Select inverter"
										options={inverters}
									/>
								</div>
								<div
									style={{
										marginTop: 6,
									}}
								>
									<Select
										id="batteryCount"
										name="batteryCount"
										className="form-right"
										styles={customStylesForEdit()}
										value={batteryCount}
										onChange={handleBatteryCountChange}
										placeholder="Select battery count"
										options={batteryCounts}
									/>
								</div>
								<div
									style={{
										marginTop: 6,
									}}
								>
									<Select
										id="batterySystem"
										name="batterySystem"
										className="form-right"
										styles={customStylesForEdit()}
										value={state.batterySystem}
										onChange={setBatterySystem}
										placeholder="Select Battery System"
										options={batterySystems}
									/>
								</div>
							</span>
						</li>
					) : (
						<ExpandableListItem
							title="Battery System"
							collapsedValueDisplay={facilityContext.facility.batterySystem.id}
						>
							<>
								<li>
									<span className="strong specification">Metering Type</span>
									<span className="em specification">{meteringType?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Manufacturer</span>
									<span className="em specification">{manufacturer?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Inverter</span>
									<span className="em specification">{inverter?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Battery Count</span>
									<span className="em specification">{batteryCount?.label || ''}</span>
								</li>
								<li>
									<span className="strong specification">Battery</span>
									<span className="em specification">
										{state?.batterySystem?.label || facilityContext.facility.batterySystem.name}
									</span>
								</li>
							</>
						</ExpandableListItem>
					)}
				</>
			) : null}
			{facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'hotWaterTank') ? (
				<li>
					<span className="strong specification">HW Tank </span>
					<span className="em specification">{facilityContext.facility.hotWaterTank.name}</span>
				</li>
			) : null}
			{facilityContext.facility && Object.hasOwn(facilityContext.facility, 'evChargePointId') ? (
				<li>
					<span className="strong specification">EV Charge Point ID </span>
					<span className="em specification">{facilityContext.facility.evChargePointId}</span>
				</li>
			) : null}
			{facilityContext.facility && Object.hasOwn(facilityContext.facility, 'druNetwork') ? (
				<li>
					<span className="strong specification">DRU Setup </span>
					<span className="em specification">
						{`${facilityContext.facility.druNetwork.name}${
							Object.hasOwn(facilityContext.facility, 'batteryLocation')
								? ' (' + facilityContext.facility.batteryLocation.name + ')'
								: ''
						}`}
					</span>
				</li>
			) : null}
			<li>
				<span className="strong specification">RS-485 Ports</span>
				<span className="em specification">
					{editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY) ? (
						<Select
							id="ports"
							name="ports"
							className="form-right"
							styles={customStylesForEdit()}
							value={state.rs485}
							onChange={(val) => {
								dispatch({ type: CHANGE_RS485, payload: val });
							}}
							placeholder="-- Select RS-485 port(s) --"
							options={getOptions(getPorts())}
						/>
					) : facilityContext.facility && Object.hasOwn(facilityContext.facility, 'rs485') ? (
						facilityContext.facility.rs485
					) : (
						'—'
					)}
				</span>
			</li>
			<li>
				<span className="strong specification">Has 3-Phase Metering </span>
				<span className="em specification">
					{editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY) ? (
						<SwitchComponent
							onChange={(value) => dispatch({ type: CHANGE_HAS_3_PHASE_METERING, payload: value })}
							controlState={state.has3PhaseMetering}
							values={[
								{ value: true, label: 'Yes' },
								{ value: false, label: 'No' },
							]}
						/>
					) : facilityContext.facility?.has3PhaseMetering ? (
						' Yes'
					) : (
						' No'
					)}
				</span>
			</li>
			<li>
				<span className="strong specification">Has Grid Metering </span>
				<span className="em specification">
					{editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY) ? (
						<SwitchComponent
							onChange={(value) => dispatch({ type: CHANGE_HAS_GRID_METERING, payload: value })}
							controlState={state.hasGridMeter}
							values={[
								{ value: true, label: 'Yes' },
								{ value: false, label: 'No' },
							]}
						/>
					) : facilityContext.facility?.hasGridMeter ? (
						'Yes'
					) : (
						'No'
					)}
				</span>
			</li>
			<li>
				<span className="strong specification">Data Collection Allowed (DSR Allowed) </span>
				<span className="em specification">
					{facilityContext.facility.dataCollectionAllowed ? 'Yes' : 'No'}
					{facilityContext.facility.drAllowed ? ' (Yes)' : ' (No)'}
				</span>
			</li>
			<li>
				<span className="strong specification">Reversed Metering </span>
				<span className="em specification">
					{editing && hasPermission(PERMISSIONS.CAN_EDIT_FACILITY) ? (
						<div className="form-right metering-types invert-selects-grid-container">
							<div className="reversed-metering-col1">
								<div className="invert-pv-wrapper">
									<SpecInput
										type="checkbox"
										id="invertPv"
										name="invertPv"
										className="invert-pv"
										onChange={() => dispatch({ type: CHANGE_INVERT_PV, payload: !state.invertPv })}
										checked={state.invertPv}
									/>
									<SpecLabel className="invert-pv-label" htmlFor="invertPv">
										Reversed PV
									</SpecLabel>
								</div>
								<div className="invert-grid-wrapper">
									<SpecInput
										type="checkbox"
										id="invertGrid"
										name="invertGrid"
										className="invert-grid"
										onChange={() =>
											dispatch({ type: CHANGE_INVERT_GRID, payload: !state.invertGrid })
										}
										checked={state.invertGrid}
									/>
									<SpecLabel className="invert-grid-label" htmlFor="invertGrid">
										Reversed grid
									</SpecLabel>
								</div>
							</div>
							<div className="reversed-metering-col2">
								<div className="invert-dru-inverter-wrapper">
									<SpecInput
										type="checkbox"
										id="invertDruInverter"
										name="invertDruInverter"
										className="invert-dru-inverter"
										onChange={() =>
											dispatch({
												type: CHANGE_INVERT_DRU_INVERTER,
												payload: !state.invertDruInverter,
											})
										}
										checked={state.invertDruInverter}
									/>
									<SpecLabel className="invert-dru-inverter-label" htmlFor="invertDruInverter">
										Reversed DRU inverter
									</SpecLabel>
								</div>
								<div className="invert-dru-grid-wrapper">
									<SpecInput
										type="checkbox"
										id="invertDruGrid"
										name="invertDruGrid"
										className="invert-dru-grid"
										onChange={() =>
											dispatch({ type: CHANGE_INVERT_DRU_GRID, payload: !state.invertDruGrid })
										}
										checked={state.invertDruGrid}
									/>
									<SpecLabel className="invert-dru-grid-label" htmlFor="invertDruGrid">
										Reversed DRU grid
									</SpecLabel>
								</div>
								<div className="invert-dru-hw-wrapper">
									<SpecInput
										type="checkbox"
										id="invertDruHw"
										name="invertDruHw"
										className="invert-dru-hw"
										onChange={() =>
											dispatch({ type: CHANGE_INVERT_DRU_HW, payload: !state.invertDruHotWater })
										}
										checked={state.invertDruHotWater}
									/>
									<SpecLabel className="invert-dru-hw-label" htmlFor="invertDruHw">
										Reversed DRU hot water
									</SpecLabel>
								</div>
							</div>
						</div>
					) : reversedMetering.length === 0 ? (
						'—'
					) : (
						reversedMetering.map((m) => m[1]).join(', ')
					)}
				</span>
			</li>
			{hasPermission(PERMISSIONS.CAN_ACCESS_AF) ? (
				<>
					<li>
						<span className="strong specification">Balancing Services Unit </span>
						<span className="em specification">
							{editing && hasPermission(PERMISSIONS.CAN_EDIT_AF) ? (
								<Select
									id="aggFacility"
									name="aggFacility"
									className="form-right"
									styles={customStylesForEdit()}
									value={state.aggregatedFacility}
									onChange={setAggregatedFacility}
									placeholder="-- Select BS Unit --"
									options={getOptions(['-- None --', ...aggregatedFacilities])}
									menuPlacement="top"
								/>
							) : facilityContext.facility?.aggregatedFacility ? (
								facilityContext.facility.aggregatedFacility.name
							) : (
								'—'
							)}
						</span>
					</li>
					<li>
						<span className="strong specification">Dispatch Contract </span>
						<span className="em specification">
							{facilityContext.facility?.aggregatedFacility
								? `${facilityContext.facility.aggregatedFacility.dispatch} ${facilityContext.facility.aggregatedFacility.unit}`
								: '—'}
						</span>
					</li>
				</>
			) : null}
		</>
	);
};

export default FacilityInfoSpecifications;
