/* Copyright Levelise Ltd 2025 */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import TabContainer from '../TabContainer';
import './index.css';
import FleetCustomersContext from '../../contexts/FleetCustomersContext';
import DownloadBtn from '../DownloadBtn';
import { download, loading } from '../../utils/constants';

const getAllCounts = (captureMethods, noTariffs) => {
	let count = 0;
	if (noTariffs) {
		count = noTariffs.customerIds.length;
	}

	captureMethods.forEach((ele) => (count += ele.customerIds.length));

	return count;
};

const CustomersTariffFilter = ({
	directionsAndCount,
	selectedDirectionTab,
	setSelectedDirectionTab,
	captureMethods,
	selectedCaptureMethod,
	setSelectedCaptureMethod,
	providers,
	selectedProvider,
	setSelectedProvider,
	noTariffCustomers,
}) => {
	return (
		<div className="customers-tariff-filter-container">
			<div className="customers-tariff-filter">
				<div className="tariff-directions-container sticky-header">
					{directionsAndCount && directionsAndCount.length ? (
						<>
							{directionsAndCount.map((ele, idx) => (
								<div
									key={idx}
									className={`directions-tab ${selectedDirectionTab === ele ? 'selected' : ''}`}
									onClick={() => {
										setSelectedDirectionTab(ele);
										setSelectedCaptureMethod('');
										setSelectedProvider('');
									}}
								>
									<span>{ele}</span>
								</div>
							))}
						</>
					) : null}
				</div>

				<ul className="capture-types-list">
					{captureMethods &&
						captureMethods.map((ele, idx) => {
							if (
								selectedCaptureMethod &&
								selectedCaptureMethod !== 'no-tariff' &&
								selectedCaptureMethod !== ele.key
							) {
								return null;
							}
							return (
								<div className="capture-method-list-con" key={idx}>
									<li
										key={idx}
										className={`capture-method-list-item ${
											selectedCaptureMethod === ele.key && !selectedProvider ? 'selected' : ''
										}`}
										onClick={() => {
											let newKey = ele.key;
											if (newKey === selectedCaptureMethod) {
												newKey = '';
											}
											setSelectedCaptureMethod(newKey);
											setSelectedProvider('');
										}}
									>
										<div className="capture-type-title-count">
											<span className="capture-type-name">
												{selectedCaptureMethod === ele.key ? (
													<FontAwesomeIcon icon={faChevronLeft} size="sm" />
												) : (
													<FontAwesomeIcon icon={faChevronRight} size="sm" />
												)}
												&nbsp;{`${ele.key === 'flatpeak' ? 'FlatPeak' : ele.key}`}
											</span>
											<span className="customers-capture-type-count">
												{ele.customerIds.length || 0}
											</span>
										</div>
									</li>
									{selectedCaptureMethod === ele.key && providers && providers.length > 0 ? (
										<ul className="providers-list">
											{providers.map((provider, idx) => (
												<li
													key={idx}
													className={`providers-list-item ${
														selectedProvider === provider.key ? 'selected' : ''
													}`}
													onClick={() => {
														setSelectedProvider(provider.key);
													}}
												>
													<span>{provider.key}</span>
													<span className="customers-tariff-count">
														{provider?.customerIds?.length || 0}
													</span>
												</li>
											))}
											<li key="last-provider" className="providers-list-item list-end-indicator">
												<span className="list-end-indicator"></span>
											</li>
										</ul>
									) : null}
								</div>
							);
						})}

					{noTariffCustomers && (selectedCaptureMethod === '' || selectedCaptureMethod === 'no-tariff') ? (
						<li
							key="no-tariff-customers"
							className={`capture-method-list-item capture-type-title-count ${
								selectedCaptureMethod === 'no-tariff' ? 'selected' : ''
							}`}
							onClick={() => {
								setSelectedCaptureMethod('no-tariff');
							}}
						>
							<span className="capture-type-name-medium no-text-transform">No tariff</span>
							<span className="customers-tariff-count">{noTariffCustomers?.customerIds.length}</span>
						</li>
					) : null}
					{captureMethods && (selectedCaptureMethod === '' || selectedCaptureMethod === 'no-tariff') ? (
						<li
							key="last-capture-method"
							className={`capture-method-list-item capture-type-title-count ${
								!selectedCaptureMethod ? 'selected' : ''
							}`}
							onClick={() => {
								setSelectedCaptureMethod('');
							}}
						>
							<span className="capture-type-name">All</span>
							<span className="customers-capture-type-count">
								{getAllCounts(captureMethods, noTariffCustomers)}
							</span>
						</li>
					) : null}
				</ul>
			</div>
		</div>
	);
};

const sortCustomerIds = (ids) => {
	if (!ids || !ids.length) {
		return [];
	}

	return ids.sort((a, b) => a - b);
};

const getKeys = (obj) => Object.keys(obj).filter((key) => key !== 'count' && key !== 'customerIds');

const filterArrayBySet = (arr, set, exclude = false) => {
	if (exclude) {
		return arr.filter((value) => !set.has(value));
	}

	return arr.filter((value) => set.has(value));
};

function CustomersTariffList({ customersTariffObject, onTariffsDownloadClick, downloadAction, downloading }) {
	const fleetCustomersContext = useContext(FleetCustomersContext);
	const [directionsAndCount, setDirectionsAndCount] = useState(['import', 'export']);

	const [captureMethods, setCaptureMethods] = useState([]);
	const [providers, setProviders] = useState([]);

	const [noTariffCustomers, setNoTariffCustomers] = useState(null);

	const filterCustomerIds = useCallback(
		(customersTariff, selectedDirection, selectedCaptureMethod, selectedProvider, filteredCustomerIdsByConsent) => {
			let customerIds = [];
			let noTariffs = null;
			let providersArr = [];
			let captureMethodsArr = [];

			let selectedDirectionObj;
			if (Object.hasOwn(customersTariff, selectedDirection)) {
				selectedDirectionObj = customersTariff[selectedDirection];

				customerIds = filterArrayBySet(selectedDirectionObj.customerIds, filteredCustomerIdsByConsent);

				const set = new Set(selectedDirectionObj.customerIds);
				noTariffs = { customerIds: filterArrayBySet([...filteredCustomerIdsByConsent], set, true) };
			}

			if (selectedDirectionObj) {
				const captureMethodKeys = getKeys(selectedDirectionObj);
				captureMethodsArr = captureMethodKeys.map((key) => ({
					key,
					count: selectedDirectionObj[key].count,
					customerIds: filterArrayBySet(selectedDirectionObj[key].customerIds, filteredCustomerIdsByConsent),
				}));

				if (selectedCaptureMethod) {
					if (selectedCaptureMethod === 'no-tariff' && noTariffs) {
						customerIds = noTariffs.customerIds;
					}
					if (selectedCaptureMethod !== 'no-tariff') {
						const selectedCaptureMethodObj = selectedDirectionObj[selectedCaptureMethod];
						if (selectedCaptureMethodObj) {
							customerIds = filterArrayBySet(
								selectedCaptureMethodObj?.customerIds,
								filteredCustomerIdsByConsent
							);
						}

						const providerKeys = getKeys(selectedCaptureMethodObj);
						providersArr = providerKeys.map((key) => ({
							key,
							count: selectedCaptureMethodObj[key].count,
							customerIds: filterArrayBySet(
								selectedCaptureMethodObj[key].customerIds,
								filteredCustomerIdsByConsent
							),
						}));

						if (selectedProvider) {
							const selectedProviderObj = selectedCaptureMethodObj[selectedProvider];
							if (selectedProviderObj) {
								customerIds = filterArrayBySet(
									selectedProviderObj?.customerIds,
									filteredCustomerIdsByConsent
								);
							}
						}
					}
				}

				if (!selectedCaptureMethod && noTariffs) {
					customerIds = [...customerIds, ...noTariffs.customerIds];
				}
			}

			fleetCustomersContext.setFilteredCustomerIds(sortCustomerIds(customerIds));
			setNoTariffCustomers(noTariffs);
			setCaptureMethods(captureMethodsArr);
			setProviders(providersArr);
		},
		[]
	);

	useEffect(() => {
		if (customersTariffObject) {
			const keys = Object.keys(customersTariffObject);

			setDirectionsAndCount(keys);
		}
	}, [customersTariffObject]);

	useEffect(() => {
		if (customersTariffObject) {
			filterCustomerIds(
				customersTariffObject,
				fleetCustomersContext.selectedDirectionTab,
				fleetCustomersContext.selectedCaptureMethod,
				fleetCustomersContext.selectedProvider,
				fleetCustomersContext.filteredCustomerIdsByConsent
			);
		} else {
			fleetCustomersContext.setFilteredCustomerIds([...fleetCustomersContext.filteredCustomerIdsByConsent]);
		}
	}, [
		customersTariffObject,
		fleetCustomersContext.selectedDirectionTab,
		fleetCustomersContext.selectedCaptureMethod,
		fleetCustomersContext.selectedProvider,
		fleetCustomersContext.filteredCustomerIdsByConsent,
		filterCustomerIds,
	]);

	return (
		<TabContainer
			filterIcon
			title="Tariffs"
			containerStyle={{
				borderWidth: 1,
				borderColor: 'rgba(187, 187, 187, 0.33)',
				borderStyle: 'solid',
				borderRadius: 3,
			}}
			rightCornerComponent={
				<DownloadBtn
					downloadState={downloading && downloadAction === 'customers-tariff-status' ? loading : download}
					clickDownload={onTariffsDownloadClick}
					style={{
						position: 'unset',
						marginLeft: 5,
						minWidth: 20,
					}}
				/>
			}
		>
			<div className="customers-tariffs-filter-list">
				<CustomersTariffFilter
					directionsAndCount={directionsAndCount}
					selectedDirectionTab={fleetCustomersContext.selectedDirectionTab}
					setSelectedDirectionTab={fleetCustomersContext.setSelectedDirectionTab}
					captureMethods={captureMethods}
					selectedCaptureMethod={fleetCustomersContext.selectedCaptureMethod}
					setSelectedCaptureMethod={fleetCustomersContext.setSelectedCaptureMethod}
					selectedProvider={fleetCustomersContext.selectedProvider}
					setSelectedProvider={fleetCustomersContext.setSelectedProvider}
					providers={providers}
					noTariffCustomers={noTariffCustomers}
				/>
			</div>
		</TabContainer>
	);
}

export default CustomersTariffList;
