/* Copyright Levelise Ltd 2022 - 2025 */
import { useState, useEffect, useContext } from 'react';
import FacilityContext from '../../contexts/FacilityContext';
import SummaryChart from './chart';
import SummaryTable from './table';
import {
    TIME_FRAMES
} from '../../utils/constants';
import './index.css';

const Summary = ({facilityName}) => {
    const chart = 'Summary', energy = 'Energy';
    const facilityContext = useContext(FacilityContext);
    const [view, setView] = useState(energy);
    const [scale, setScale] = useState('');
    const [summaryData, setSummaryData] = useState({
        facility: facilityContext.facility,
        records: facilityContext.records,
        timeFrame: facilityContext.timeFrame,
        dates: facilityContext.dates,
    })

    useEffect(() => {
        const { timeFrame, dates } = facilityContext;
        const timeScale = timeFrame !== TIME_FRAMES.select
            ? timeFrame : dates.start === dates.end
                ? dates.start : `${dates.start} - ${dates.end}`;
        if (timeScale !== scale) {
            setScale(timeScale);
        }
    }, [facilityContext.timeFrame, facilityContext.dates, facilityName])

    useEffect(() => {
        setSummaryData({
            facility: facilityContext.facility,
            records: facilityContext.records,
            timeFrame: facilityContext.timeFrame,
            dates: facilityContext.dates,
        })
    }, [facilityContext.facility, facilityContext.records, facilityContext.timeFrame, facilityContext.dates, facilityName])

    return (
        <div className='summary'>
            <h2 className='summary-header'>
                <span id='summary-data' className={view === energy ? 'summary-view' : ''} onClick={() => setView(energy)}>
                    {energy}
                </span>
                <span id='summary-chart' className={view === chart ? 'summary-view' : ''} onClick={() => setView(chart)}>
                    {chart}
                </span>
                <span id='summary-scale' style={{ right: 10, fontSize: 11 }}>
                    {scale}
                </span>
            </h2>
            {view === energy &&
                <SummaryTable
                    facility={summaryData.facility}
                    records={summaryData.records}
                    timeFrame={summaryData.timeFrame}
                    dates={summaryData.dates}
                />
            }
            {view === chart &&
                <SummaryChart
                    facility={summaryData.facility}
                    records={summaryData.records}
                    timeFrame={summaryData.timeFrame}
                    dates={summaryData.dates}
                />
            }
        </div>
    )
}

export default Summary