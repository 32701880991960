/* Copyright Levelise Ltd 2019 - 2025 */
import { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
	POWER_FLOW,
	POWER_FLOW_HALF_HOUR,
	POWER_FLOW_MINUTE,
	POWER_FLOW_SECOND,
	TIME_FRAMES,
	timestampDay,
	timestampSec,
	timestampMsec,
	resolutions,
} from '../../../utils/constants';
import {
	CONTRACT_ANNOTATION,
	getValue,
	getDiffValues,
	grid,
	battery,
	toLoad,
	fromPv,
	toBattery,
	fromBattery,
	fromGrid,
	toGrid,
	heating,
	socEnergy,
	getTimeFormat,
	dataset,
	options,
	xAxis,
	facilityChartTitles,
	tooltipTitleCallbacks,
	ev,
	toEv,
	fromEv,
	dcPv,
	inverter,
	toInverter,
	fromInverter,
} from '../../../utils/chart';
import { getUnixTime } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

const x = { type: 'time' };
const x1 = null;
const y = {
	stacked: false,
	ticks: {},
	label: 'Energy (kWh)',
	gridLines: { drawOnChartArea: true, z: 1 },
};
const y1 = {
	stacked: false,
	ticks: { min: 0, max: 100 },
	label: 'SoC (%)',
	gridLines: { drawOnChartArea: false },
};
const legendLabels = {
	font: { size: 10 },
	color: 'black',
	usePointStyle: true,
	boxWidth: 4,
	boxHeight: 4,
	padding: 8,
	filter: function (item, chart) {
		const idx = item.datasetIndex;
		const dataset = chart.datasets[idx];
		return !dataset.hidden;
	},
};

const actualToForecastDatasetIndexMap = {
	0: 17,
	1: 16,
	2: 25,
	6: 18,
	7: 19,
	8: 20,
	12: 21,
	13: 22,
	14: 23,
	26: 24
}

const forecastToActualDatasetIndexMap = {
	17: 0,
	16: 1,
	18: 6,
	19: 7,
	20: 8,
	21: 12,
	22: 13,
	23: 14,
	25: 2,
	24: 26
}

const EnergyChart = ({
	fmData,
	handleFmData,
	data,
	showDistinct,
	showHotWater,
	showBattery,
	timezone,
	selectedTimezone,
	showForecast,
	isTypeDC,
}) => {
	const facilityEnergyRef = useRef();
	const dataSetLabels = {
		toLoad: toLoad.type,
		fromPv: fromPv.type,
		battery: battery.type,
		toBattery: toBattery.type,
		fromBattery: fromBattery.type,
		grid: grid.type,
		toGrid: toGrid.type,
		fromGrid: fromGrid.type,
		heating: heating.type,
		socEnergy: socEnergy.type,
		ev: ev.type,
		toEv: toEv.type,
		fromEv: fromEv.type,
		fromDcPv: dcPv.type,
		inverter: inverter.type,
		toInverter: toInverter.type,
		fromInverter: fromInverter.type,
		fromPvForecast: fromPv.forecastType,
		toLoadForecast: toLoad.forecastType,
		toBatteryForecast: toBattery.forecastType,
		fromBatteryForecast: fromBattery.forecastType,
		toGridForecast: toGrid.forecastType,
		fromGridForecast: fromGrid.forecastType,
		fromDcPvForecast: dcPv.forecastType,
		socForecast: socEnergy.forecastType,
	};

	const [chart, setChart] = useState({
		data: {
			labels: [],
			datasets: [
				dataset('line', toLoad.type, toLoad.backgroundColor, toLoad.borderColor, 'y'),
				dataset('line', fromPv.type, fromPv.backgroundColor, fromPv.borderColor, 'y'),
				dataset('line', dcPv.type, dcPv.backgroundColor, dcPv.borderColor, 'y'),
				dataset('line', ev.type, ev.backgroundColor, ev.borderColor, 'y'),
				dataset('line', toEv.type, toEv.backgroundColor, toEv.borderColor, 'y'),
				dataset('line', fromEv.type, fromEv.backgroundColor, fromEv.borderColor, 'y'),
				dataset('line', battery.type, battery.backgroundColor, battery.borderColor, 'y'),
				dataset('line', toBattery.type, toBattery.backgroundColor, toBattery.borderColor, 'y'),
				dataset('line', fromBattery.type, fromBattery.backgroundColor, fromBattery.borderColor, 'y'),
				dataset('line', inverter.type, inverter.backgroundColor, inverter.borderColor, 'y'),
				dataset('line', toInverter.type, toInverter.backgroundColor, toInverter.borderColor, 'y'),
				dataset('line', fromInverter.type, fromInverter.backgroundColor, fromInverter.borderColor, 'y'),
				dataset('line', grid.type, grid.backgroundColor, grid.borderColor, 'y'),
				dataset('line', toGrid.type, toGrid.backgroundColor, toGrid.borderColor, 'y'),
				dataset('line', fromGrid.type, fromGrid.backgroundColor, fromGrid.borderColor, 'y'),
				dataset('line', heating.type, heating.backgroundColor, heating.borderColor, 'y'),

				dataset('line', fromPv.forecastType, fromPv.forecastColor, fromPv.forecastColor, 'y'),
				dataset('line', toLoad.forecastType, toLoad.forecastColor, toLoad.forecastColor, 'y'),
				dataset('line', battery.forecastType, battery.forecastColor, battery.forecastColor, 'y'),
				dataset('line', toBattery.forecastType, toBattery.forecastColor, toBattery.forecastColor, 'y'),
				dataset('line', fromBattery.forecastType, fromBattery.forecastColor, fromBattery.forecastColor, 'y'),
				dataset('line', grid.forecastType, grid.forecastColor, grid.forecastColor, 'y'),
				dataset('line', toGrid.forecastType, toGrid.forecastColor, toGrid.forecastColor, 'y'),
				dataset('line', fromGrid.forecastType, fromGrid.forecastColor, fromGrid.forecastColor, 'y'),
				dataset('line', socEnergy.forecastType, socEnergy.forecastColor, socEnergy.forecastColor, 'y1'),
				dataset('line', dcPv.forecastType, dcPv.forecastColor, dcPv.forecastColor, 'y'),
				dataset('line', socEnergy.type, socEnergy.backgroundColor, socEnergy.borderColor, 'y1', true),
			],
		},
		options: options('', legendLabels, x, x1, y, y1),
	});

	const handleDataOnChange = (data) => {
		let resolution = data.resolution;

		if (data.timeFrame !== TIME_FRAMES.select) {
			switch (data.timeFrame) {
				case TIME_FRAMES.fifteen_minutes:
					resolution = resolutions.second;
					break;
				case TIME_FRAMES.one_hour:
					resolution = resolutions.minute;
					break;
				case TIME_FRAMES.six_hours:
					resolution = resolutions.minute;
					break;
				case TIME_FRAMES.twenty_four_hours:
					resolution = resolutions.minute;
					break;
				case TIME_FRAMES.one_week:
					resolution = resolutions.half_hour;
					break;
				case TIME_FRAMES.twenty_one_days:
					resolution = resolutions.half_hour;
					break;
				case TIME_FRAMES.three_months:
					resolution = resolutions.day;
					break;
				case TIME_FRAMES.twelve_months:
					resolution = resolutions.day;
					break;
				case TIME_FRAMES.thirty_six_months:
					resolution = resolutions.day;
					break;
				case TIME_FRAMES.all:
					resolution = resolutions.week;
					break;
				default:
					break;
			}
		}

		switch (resolution) {
			case resolutions.week:
			case resolutions.day:
				populateChartByDay(data.dailyReports, resolution);
				break;
			case resolutions.half_hour:
				populateChartByHalfHour(data.halfHourlyReports, resolution);
				break;
			case resolutions.minute:
				populateChartByMinute(data.minutelyReports, resolution, data.updated, data.forecasts);
				break;
			case resolutions.second:
				populateChartBySecond(data.bySecondReports, resolution, data.updated);
				break;
			default:
				break;
		}
	};

	const populateChartByDay = (reports, resolution) => {
		const {
			toBattery,
			fromBattery,
			toGrid,
			fromGrid,
			heating,
			socEnergy,
			toLoad,
			fromPv,
			toEv,
			fromEv,
			fromDcPv,
			toInverter,
			fromInverter,
		} = dataSetLabels;
		let labels = [];
		let datasets = chart.data.datasets.map((d) => {
			d.data = [];
			return d;
		});

		if (!reports.length) return;
		const start = reports[0][timestampDay];
		const end = reports[reports.length - 1][timestampDay];
		const increment = resolution === resolutions.week ? 7 : 1;
		let i = 0;
		for (let timestamp = start; timestamp <= end; timestamp += increment) {
			if (timestamp === parseInt(reports[i][timestampDay])) {
				const ev = getDiffValues(reports[i][POWER_FLOW[toEv]], reports[i][POWER_FLOW[fromEv]]);
				const battery = getDiffValues(reports[i][POWER_FLOW[toBattery]], reports[i][POWER_FLOW[fromBattery]]);
				const grid = getDiffValues(reports[i][POWER_FLOW[fromGrid]], reports[i][POWER_FLOW[toGrid]]);

				const inverter = getDiffValues(
					reports[i][POWER_FLOW[toInverter]],
					reports[i][POWER_FLOW[fromInverter]]
				);

				datasets[0].data.push(getValue(reports[i][POWER_FLOW[toLoad]])); // to load
				datasets[1].data.push(getValue(reports[i][POWER_FLOW[fromPv]])); // from pv
				datasets[2].data.push(getValue(reports[i][POWER_FLOW[fromDcPv]])); // from dc pv
				datasets[3].data.push(ev); // to - from ev
				datasets[4].data.push(getValue(reports[i][POWER_FLOW[toEv]])); // to ev
				datasets[5].data.push(getValue(reports[i][POWER_FLOW[fromEv]], true)); // from ev
				datasets[6].data.push(battery); // to - from battery
				datasets[7].data.push(getValue(reports[i][POWER_FLOW[toBattery]])); // to battery
				datasets[8].data.push(getValue(reports[i][POWER_FLOW[fromBattery]], true)); // from battery

				datasets[9].data.push(inverter); // to - from inverter
				datasets[10].data.push(getValue(reports[i][POWER_FLOW[toInverter]])); // to inverter
				datasets[11].data.push(getValue(reports[i][POWER_FLOW[fromInverter]], true)); // from inverter

				datasets[12].data.push(grid); // from - to grid
				datasets[13].data.push(getValue(reports[i][POWER_FLOW[toGrid]], true)); // to grid
				datasets[14].data.push(getValue(reports[i][POWER_FLOW[fromGrid]])); // from grid
				datasets[15].data.push(getValue(reports[i][POWER_FLOW[heating]])); // to HW
				datasets[26].data.push(parseInt(getValue(reports[i][POWER_FLOW[socEnergy]]))); // SoC

				i++;
			} else {
				datasets[0].data.push(null);
				datasets[1].data.push(null);
				datasets[2].data.push(null);
				datasets[3].data.push(null);
				datasets[4].data.push(null);
				datasets[5].data.push(null);
				datasets[6].data.push(null);
				datasets[7].data.push(null);
				datasets[8].data.push(null);
				datasets[9].data.push(null);
				datasets[10].data.push(null);
				datasets[11].data.push(null);
				datasets[12].data.push(null);
				datasets[13].data.push(null);
				datasets[14].data.push(null);
				datasets[15].data.push(null);
				datasets[26].data.push(null);
			}

			labels.push(timestamp * 86400 * 1000);
		}

		// dc pv check
		if (datasets[2].data.every((v) => v === null || v === undefined)) {
			datasets[2].hidden = true;
		} else {
			datasets[2].hidden = false;
		}

		// ev value check
		if (datasets[3].data.every((v) => v === null || v === undefined)) {
			datasets[3].hidden = true;
			datasets[4].hidden = true;
			datasets[5].hidden = true;
		} else {
			datasets[3].hidden = showDistinct;
			datasets[4].hidden = !showDistinct;
			datasets[5].hidden = !showDistinct;
		}
		datasets[6].hidden = showBattery ? showDistinct : true; // to - from battery
		datasets[7].hidden = showBattery ? !showDistinct : true; // to battery
		datasets[8].hidden = showBattery ? !showDistinct : true; // from battery

		if (
			datasets[10].data.every((v) => v === null || v === undefined) &&
			datasets[11].data.every((v) => v === null || v === undefined)
		) {
			datasets[9].hidden = true; // to - from inverter
			datasets[10].hidden = true; // to inverter
			datasets[11].hidden = true; // from inverter
		} else {
			datasets[9].hidden = showDistinct;
			datasets[10].hidden = !showDistinct;
			datasets[11].hidden = !showDistinct;
		}

		datasets[12].hidden = showDistinct; // from - to grid
		datasets[13].hidden = !showDistinct; // to grid
		datasets[14].hidden = !showDistinct; // from grid
		datasets[15].hidden = !showHotWater; // to HW
		datasets[26].hidden = true; // Soc

		datasets[16].hidden = true;
		datasets[17].hidden = true;
		datasets[18].hidden = true;
		datasets[19].hidden = true;
		datasets[20].hidden = true;
		datasets[21].hidden = true;
		datasets[22].hidden = true;
		datasets[23].hidden = true;
		datasets[24].hidden = true;
		datasets[25].hidden = true;

		if (isTypeDC) {
			const batteryLabels = [datasets[6].label, datasets[7].label, datasets[8].label];
			if (!batteryLabels.some((value) => value.includes('DC'))) {
				datasets[6].label = `DC ${datasets[6].label}`;
				datasets[7].label = `DC ${datasets[7].label}`;
				datasets[8].label = `DC ${datasets[8].label}`;
			}
		}

		const label = `Energy (kWh)`;
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, [], false);
	};

	const populateChartByHalfHour = (reports, resolution) => {
		const {
			toBattery,
			fromBattery,
			toGrid,
			fromGrid,
			heating,
			socEnergy,
			toLoad,
			fromPv,
			toEv,
			fromEv,
			fromDcPv,
			fromInverter,
			toInverter,
		} = dataSetLabels;
		let labels = [];
		let datasets = chart.data.datasets.map((d) => {
			d.data = [];
			return d;
		});

		if (!reports.length) return;
		const start = reports[0][timestampSec];
		const end = reports[reports.length - 1][timestampSec];
		let i = 0;
		for (let timestamp = start; timestamp <= end; timestamp += 1800) {
			if (timestamp === parseInt(reports[i][timestampSec])) {
				const ev = getDiffValues(
					reports[i][POWER_FLOW_HALF_HOUR[toEv]],
					reports[i][POWER_FLOW_HALF_HOUR[fromEv]]
				);
				const battery = getDiffValues(
					reports[i][POWER_FLOW_HALF_HOUR[toBattery]],
					reports[i][POWER_FLOW_HALF_HOUR[fromBattery]]
				);
				const grid = getDiffValues(
					reports[i][POWER_FLOW_HALF_HOUR[fromGrid]],
					reports[i][POWER_FLOW_HALF_HOUR[toGrid]]
				);

				const inverter = getDiffValues(
					reports[i][POWER_FLOW_HALF_HOUR[toInverter]],
					reports[i][POWER_FLOW_HALF_HOUR[fromInverter]]
				);

				datasets[0].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[toLoad]])); // to load
				datasets[1].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[fromPv]])); // from pv
				datasets[2].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[fromDcPv]])); // from dc pv
				datasets[3].data.push(ev); // to - from ev
				datasets[4].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[toEv]])); // to ev
				datasets[5].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[fromEv]], true)); // from ev

				datasets[6].data.push(battery); // to - from battery
				datasets[7].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[toBattery]])); // to battery
				datasets[8].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[fromBattery]], true)); // from battery

				datasets[9].data.push(inverter); // to - from inverter
				datasets[10].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[toInverter]])); // to inverter
				datasets[11].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[fromInverter]], true)); // from inverter

				datasets[12].data.push(grid); // from - to grid
				datasets[13].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[toGrid]], true)); // to grid
				datasets[14].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[fromGrid]])); // from grid
				datasets[15].data.push(getValue(reports[i][POWER_FLOW_HALF_HOUR[heating]])); // to HW
				datasets[26].data.push(parseInt(getValue(reports[i][POWER_FLOW_HALF_HOUR[socEnergy]]))); // SoC

				i++;
			} else {
				datasets[0].data.push(null);
				datasets[1].data.push(null);
				datasets[2].data.push(null);
				datasets[3].data.push(null);
				datasets[4].data.push(null);
				datasets[5].data.push(null);
				datasets[6].data.push(null);
				datasets[7].data.push(null);
				datasets[8].data.push(null);
				datasets[9].data.push(null);
				datasets[10].data.push(null);
				datasets[11].data.push(null);
				datasets[12].data.push(null);
				datasets[13].data.push(null);
				datasets[14].data.push(null);
				datasets[15].data.push(null);
				datasets[26].data.push(null);
			}

			labels.push(timestamp * 1000);
		}

		// dc pv check
		if (datasets[2].data.every((v) => v === null || v === undefined)) {
			datasets[2].hidden = true;
		} else {
			datasets[2].hidden = false;
		}

		// ev check
		if (datasets[3].data.every((v) => v === null || v === undefined)) {
			datasets[3].hidden = true;
			datasets[4].hidden = true;
			datasets[5].hidden = true;
		} else {
			datasets[3].hidden = showDistinct;
			datasets[4].hidden = !showDistinct;
			datasets[5].hidden = !showDistinct;
		}

		datasets[6].hidden = showBattery ? showDistinct : true; // to - from battery
		datasets[7].hidden = showBattery ? !showDistinct : true; // to battery
		datasets[8].hidden = showBattery ? !showDistinct : true; // from battery

		if (
			datasets[10].data.every((v) => v === null || v === undefined) &&
			datasets[11].data.every((v) => v === null || v === undefined)
		) {
			datasets[9].hidden = true; // to - from inverter
			datasets[10].hidden = true; // to inverter
			datasets[11].hidden = true; // from inverter
		} else {
			datasets[9].hidden = showDistinct;
			datasets[10].hidden = !showDistinct;
			datasets[11].hidden = !showDistinct;
		}

		datasets[12].hidden = showDistinct; // from - to grid
		datasets[13].hidden = !showDistinct; // to grid
		datasets[14].hidden = !showDistinct; // from grid
		datasets[15].hidden = !showHotWater; // to HW
		datasets[26].hidden = !showBattery ? true : false; // Soc

		datasets[16].hidden = true;
		datasets[17].hidden = true;
		datasets[18].hidden = true;
		datasets[19].hidden = true;
		datasets[20].hidden = true;
		datasets[21].hidden = true;
		datasets[22].hidden = true;
		datasets[23].hidden = true;
		datasets[24].hidden = true;
		datasets[25].hidden = true;

		if (isTypeDC) {
			const batteryLabels = [datasets[6].label, datasets[7].label, datasets[8].label];
			if (!batteryLabels.some((value) => value.includes('DC'))) {
				datasets[6].label = `DC ${datasets[6].label}`;
				datasets[7].label = `DC ${datasets[7].label}`;
				datasets[8].label = `DC ${datasets[8].label}`;
			}
		}

		const label = `Energy (kWh)`;
		const annotation = end - start <= 90000 ? CONTRACT_ANNOTATION(end, timezone) : [];
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, annotation, showBattery);
	};

	const populateChartByMinute = (reports, resolution, update = false, forecasts = null) => {
		let labels = update ? [...chart.data.labels] : [];
		let datasets = update
			? [...chart.data.datasets]
			: chart.data.datasets.map((d) => {
					d.data = [];
					return d;
			  });
		const {
			toBattery,
			fromBattery,
			toGrid,
			fromGrid,
			heating,
			socEnergy,
			toLoad,
			fromPv,
			toEv,
			fromEv,
			fromDcPv,
			fromInverter,
			toInverter,
		} = dataSetLabels;

		if (
			!reports?.length &&
			(!forecasts || !forecasts?.length || data.timeFrame !== TIME_FRAMES.twenty_four_hours)
		) {
			return;
		}

		let end = 0;
		if (reports && reports.length) {
			const start = reports[0][timestampSec];
			end = reports[reports.length - 1][timestampSec];

			let i = 0;
			for (let timestamp = start; timestamp <= end; timestamp += 60) {
				if (update) {
					labels.shift();
					datasets[0].data.shift();
					datasets[1].data.shift();
					datasets[2].data.shift();
					datasets[3].data.shift();
					datasets[4].data.shift();
					datasets[5].data.shift();
					datasets[6].data.shift();
					datasets[7].data.shift();
					datasets[8].data.shift();
					datasets[9].data.shift();
					datasets[10].data.shift();
					datasets[11].data.shift();
					datasets[12].data.shift();
					datasets[13].data.shift();
					datasets[14].data.shift();
					datasets[15].data.shift();
					datasets[26].data.shift();
				}

				if (timestamp === parseInt(reports[i][timestampSec])) {
					const ev = getDiffValues(
						reports[i][POWER_FLOW_MINUTE[toEv]],
						reports[i][POWER_FLOW_MINUTE[fromEv]]
					);
					const battery = getDiffValues(
						reports[i][POWER_FLOW_MINUTE[toBattery]],
						reports[i][POWER_FLOW_MINUTE[fromBattery]]
					);
					const grid = getDiffValues(
						reports[i][POWER_FLOW_MINUTE[fromGrid]],
						reports[i][POWER_FLOW_MINUTE[toGrid]]
					);
					const inverter = getDiffValues(
						reports[i][POWER_FLOW_MINUTE[toInverter]],
						reports[i][POWER_FLOW_MINUTE[fromInverter]]
					);

					datasets[0].data.push(getValue(reports[i][POWER_FLOW_MINUTE[toLoad]])); // to load
					datasets[1].data.push(getValue(reports[i][POWER_FLOW_MINUTE[fromPv]])); // from pv
					datasets[2].data.push(getValue(reports[i][POWER_FLOW_MINUTE[fromDcPv]])); // from dc pv

					datasets[3].data.push(ev); // to - from ev
					datasets[4].data.push(getValue(reports[i][POWER_FLOW_MINUTE[toEv]])); // to ev
					datasets[5].data.push(getValue(reports[i][POWER_FLOW_MINUTE[fromEv]], true)); // from ev

					datasets[6].data.push(battery); // to - from battery
					datasets[7].data.push(getValue(reports[i][POWER_FLOW_MINUTE[toBattery]])); // to battery
					datasets[8].data.push(getValue(reports[i][POWER_FLOW_MINUTE[fromBattery]], true)); // from battery

					datasets[9].data.push(inverter); // to - from inverter
					datasets[10].data.push(getValue(reports[i][POWER_FLOW_MINUTE[toInverter]])); // to inverter
					datasets[11].data.push(getValue(reports[i][POWER_FLOW_MINUTE[fromInverter]], true)); // from inverter

					datasets[12].data.push(grid); // from - to grid
					datasets[13].data.push(getValue(reports[i][POWER_FLOW_MINUTE[toGrid]], true)); // to grid
					datasets[14].data.push(getValue(reports[i][POWER_FLOW_MINUTE[fromGrid]])); // from grid

					datasets[15].data.push(getValue(reports[i][POWER_FLOW_MINUTE[heating]])); // to HW
					datasets[26].data.push(parseInt(getValue(reports[i][POWER_FLOW_MINUTE[socEnergy]]))); // SoC

					i++;
				} else {
					datasets[0].data.push(null);
					datasets[1].data.push(null);
					datasets[2].data.push(null);
					datasets[3].data.push(null);
					datasets[4].data.push(null);
					datasets[5].data.push(null);
					datasets[6].data.push(null);
					datasets[7].data.push(null);
					datasets[8].data.push(null);
					datasets[9].data.push(null);
					datasets[10].data.push(null);
					datasets[11].data.push(null);
					datasets[12].data.push(null);
					datasets[13].data.push(null);
					datasets[14].data.push(null);
					datasets[15].data.push(null);
					datasets[26].data.push(null);
				}

				labels.push(timestamp * 1000);
			}
		}

		if (forecasts && forecasts.length > 0 && data.timeFrame === TIME_FRAMES.twenty_four_hours && showForecast) {
			if (!reports?.length ) {
				end = forecasts[forecasts.length - 1][timestampSec];
			}

			if (reports.length <= forecasts.length) {
				labels = []
			}

			const nowZonedTimestamp = getUnixTime(fromZonedTime(new Date(), timezone));
			const endForForecasts = nowZonedTimestamp + 86401; // next 24 hours timestamp + 1sec

			let j = 0;
			let lastTimestamp = 0;
			while (j < forecasts.length && lastTimestamp < endForForecasts) {
				const forecast = forecasts[j];

				let forecastGridOutgoingW = null
				if (forecast?.gridIncomingW !== null) {
					forecastGridOutgoingW = forecast?.gridIncomingW < 0 ? Math.abs(forecast.gridIncomingW) : 0;
				}

				let forecastGridIncomingW = null
				if (forecast?.gridIncomingW !== null) {
					forecastGridIncomingW = forecast?.gridIncomingW >= 0 ? forecast.gridIncomingW : 0;
				}

				let forecastBatteryChargingW = null
				if (forecast?.batteryChargingW !== null) {
					forecastBatteryChargingW = forecast?.batteryChargingW >= 0 ? forecast?.batteryChargingW : 0;
				}
				
				let forecastBatteryDischargingW = null
				if (forecast?.batteryChargingW !== null) {
					forecastBatteryDischargingW = forecast?.batteryChargingW < 0 ? Math.abs(forecast.batteryChargingW) : 0;
				}

				datasets[16].data.push(forecast?.pvW); // PV Forecast
				datasets[17].data.push(forecast?.loadW); // Load Forecast
				datasets[18].data.push(forecast?.batteryChargingW); // To Batter - From Battery charging Forecast
				datasets[19].data.push(forecastBatteryChargingW); // Batter charging Forecast
				datasets[20].data.push(forecastBatteryDischargingW); // Battery Discharging Forecast
				datasets[21].data.push(forecast?.gridIncomingW); // To Grid - From Grid outgoing Forecast
				datasets[22].data.push(forecastGridOutgoingW); // Grid outgoing Forecast
				datasets[23].data.push(forecastGridIncomingW); // Grid incoming Forecast
				datasets[24].data.push(forecast?.soc); // SoC Forecast
				datasets[25].data.push(forecast?.dcPvW); // SoC Forecast

				j++;
				lastTimestamp = forecast[timestampSec];
				labels.push(forecast[timestampSec] * 1000);
			}			
		} else {
			datasets[16].data = [];
			datasets[17].data = [];
			datasets[18].data = [];
			datasets[19].data = [];
			datasets[20].data = [];
			datasets[21].data = [];
			datasets[22].data = [];
			datasets[23].data = [];
			datasets[24].data = [];
			datasets[25].data = [];
		}

		// dc pv check
		const isNotDc = datasets[2].data.every((v) => v === null || v === undefined)
		if (isNotDc) {
			datasets[2].hidden = true;
		} else {
			datasets[2].hidden = false;
		}

		// ev check
		if (datasets[3].data.every((v) => v === null || v === undefined)) {
			datasets[3].hidden = true;
			datasets[4].hidden = true;
			datasets[5].hidden = true;
		} else {
			datasets[3].hidden = showDistinct;
			datasets[4].hidden = !showDistinct;
			datasets[5].hidden = !showDistinct;
		}
		datasets[6].hidden = showBattery ? showDistinct : true; // to - from battery
		datasets[7].hidden = showBattery ? !showDistinct : true; // to battery
		datasets[8].hidden = showBattery ? !showDistinct : true; // from battery

		if (
			datasets[10].data.every((v) => v === null || v === undefined) &&
			datasets[11].data.every((v) => v === null || v === undefined)
		) {
			datasets[9].hidden = true; // to - from inverter
			datasets[10].hidden = true; // to inverter
			datasets[11].hidden = true; // from inverter
		} else {
			datasets[9].hidden = showDistinct;
			datasets[10].hidden = !showDistinct;
			datasets[11].hidden = !showDistinct;
		}

		datasets[12].hidden = showDistinct; // from - to grid
		datasets[13].hidden = !showDistinct; // to grid
		datasets[14].hidden = !showDistinct; // from grid

		datasets[15].hidden = !showHotWater; // to HW
		datasets[26].hidden = !showBattery ? true : false; // Soc

		datasets[16].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast;
		datasets[17].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast;
		datasets[18].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast ? true : showDistinct;
		datasets[19].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast ? true : !showDistinct;
		datasets[20].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast ? true : !showDistinct;
		datasets[21].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast ? true : showDistinct;
		datasets[22].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast ? true : !showDistinct;
		datasets[23].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast ? true : !showDistinct;

		datasets[24].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast ? true : !showBattery; // show SoC forecast
		datasets[25].hidden = data.timeFrame !== TIME_FRAMES.twenty_four_hours || !showForecast || isNotDc;

		if (isTypeDC) {
			const batteryLabels = [datasets[6].label, datasets[7].label, datasets[8].label];
			if (!batteryLabels.some((value) => value.includes('DC'))) {
				datasets[6].label = `DC ${datasets[6].label}`;
				datasets[7].label = `DC ${datasets[7].label}`;
				datasets[8].label = `DC ${datasets[8].label}`;
			}
		}

		const label = `Power (W)`;
		const annotation = CONTRACT_ANNOTATION(end, timezone);
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, annotation, showBattery);
	};

	const populateChartBySecond = (reports, resolution, update = false) => {
		let labels = update ? [...chart.data.labels] : [];
		let datasets = update
			? [...chart.data.datasets]
			: chart.data.datasets.map((d) => {
					d.data = [];
					return d;
			  });
		const {
			toBattery,
			fromBattery,
			toGrid,
			fromGrid,
			heating,
			socEnergy,
			toLoad,
			fromPv,
			fromDcPv,
			toInverter,
			fromInverter,
		} = dataSetLabels;

		if (!reports.length) return;

		const start = reports[0][timestampMsec];
		const end = reports[reports.length - 1][timestampMsec];
		let i = 0;
		for (let timestamp = start; timestamp <= end; timestamp += 1000) {
			if (parseInt(timestamp / 1000) === parseInt(reports[i][timestampMsec] / 1000)) {
				const battery = getDiffValues(
					reports[i][POWER_FLOW_SECOND[toBattery]],
					reports[i][POWER_FLOW_SECOND[fromBattery]]
				);
				const grid = getDiffValues(
					reports[i][POWER_FLOW_SECOND[fromGrid]],
					reports[i][POWER_FLOW_SECOND[toGrid]]
				);
				const inverter = getDiffValues(
					reports[i][POWER_FLOW_SECOND[toInverter]],
					reports[i][POWER_FLOW_SECOND[fromInverter]]
				);

				datasets[0].data.push(getValue(reports[i][POWER_FLOW_SECOND[toLoad]])); // to load
				datasets[1].data.push(getValue(reports[i][POWER_FLOW_SECOND[fromPv]])); // from pv
				datasets[2].data.push(getValue(reports[i][POWER_FLOW_SECOND[fromDcPv]])); // from dc pv

				datasets[6].data.push(battery); // to - from battery
				datasets[7].data.push(getValue(reports[i][POWER_FLOW_SECOND[toBattery]])); // to battery
				datasets[8].data.push(getValue(reports[i][POWER_FLOW_SECOND[fromBattery]], true)); // from battery

				datasets[9].data.push(inverter); // to - from inverter
				datasets[10].data.push(getValue(reports[i][POWER_FLOW_SECOND[toInverter]])); // to inverter
				datasets[11].data.push(getValue(reports[i][POWER_FLOW_SECOND[fromInverter]], true)); // from inverter

				datasets[12].data.push(grid); // from - to grid
				datasets[13].data.push(getValue(reports[i][POWER_FLOW_SECOND[toGrid]], true)); // to grid
				datasets[14].data.push(getValue(reports[i][POWER_FLOW_SECOND[fromGrid]])); // from grid
				datasets[15].data.push(getValue(reports[i][POWER_FLOW_SECOND[heating]])); // to HW
				datasets[26].data.push(parseInt(getValue(reports[i][POWER_FLOW_SECOND[socEnergy]]))); // SoC

				i++;
			} else if (parseInt((timestamp - 1000) / 1000) === parseInt(reports[i][timestampMsec] / 1000)) {
				const battery =
					reports[i][POWER_FLOW_SECOND[toBattery]] || 0 - reports[i][POWER_FLOW_SECOND[fromBattery]] || 0;
				const grid = reports[i][POWER_FLOW_SECOND[fromGrid]] || 0 - reports[i][POWER_FLOW_SECOND[toGrid]] || 0;
				const inverter =
					(reports[i][POWER_FLOW_SECOND[toInverter]] || 0) -
					(reports[i][POWER_FLOW_SECOND[fromInverter]] || 0);

				datasets[0].data[datasets[0].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[toLoad]]); // to load
				datasets[1].data[datasets[1].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[fromPv]]); // from pv
				datasets[2].data[datasets[2].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[fromDcPv]]); // from dc pv

				datasets[6].data[datasets[6].data.length - 1] = battery; // to - from battery
				datasets[7].data[datasets[7].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[toBattery]]); // to battery
				datasets[8].data[datasets[8].data.length - 1] = getValue(
					reports[i][POWER_FLOW_SECOND[fromBattery]],
					true
				); // from battery

				datasets[9].data[datasets[9].data.length - 1] = inverter; // to - from inverter
				datasets[10].data[datasets[10].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[toInverter]]); // to inverter
				datasets[11].data[datasets[11].data.length - 1] = getValue(
					reports[i][POWER_FLOW_SECOND[fromInverter]],
					true
				); // from inverter

				datasets[12].data[datasets[12].data.length - 1] = grid; // from - to grid
				datasets[13].data[datasets[13].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[toGrid]], true); // to grid
				datasets[14].data[datasets[14].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[fromGrid]]); // from grid

				datasets[15].data[datasets[15].data.length - 1] = getValue(reports[i][POWER_FLOW_SECOND[heating]]); // to HW
				datasets[26].data[datasets[26].data.length - 1] = parseInt(
					getValue(reports[i][POWER_FLOW_SECOND[socEnergy]])
				); // SoC

				timestamp -= 1000;
				i++;
				continue;
			} else {
				datasets[0].data.push(null);
				datasets[1].data.push(null);
				datasets[2].data.push(null);
				// datasets[3].data.push(null);
				// datasets[4].data.push(null);
				// datasets[5].data.push(null);
				datasets[6].data.push(null);
				datasets[7].data.push(null);
				datasets[8].data.push(null);

				datasets[9].data.push(null);
				datasets[10].data.push(null);
				datasets[11].data.push(null);

				datasets[12].data.push(null);
				datasets[13].data.push(null);
				datasets[14].data.push(null);

				datasets[15].data.push(null);
				datasets[26].data.push(null);
			}

			if (update) {
				labels.shift();
				datasets[0].data.shift();
				datasets[1].data.shift();
				datasets[2].data.shift();
				// datasets[3].data.shift()
				// datasets[4].data.shift()
				// datasets[5].data.shift()
				datasets[6].data.shift();
				datasets[7].data.shift();
				datasets[8].data.shift();
				datasets[9].data.shift();
				datasets[10].data.shift();
				datasets[11].data.shift();
				datasets[12].data.shift();
				datasets[13].data.shift();
				datasets[14].data.shift();
				datasets[15].data.shift();
				datasets[26].data.shift();
			}

			labels.push(timestamp);
		}

		// dc pv check
		if (datasets[2].data.every((v) => v === null || v === undefined)) {
			datasets[2].hidden = true;
		} else {
			datasets[2].hidden = false;
		}

		datasets[3].hidden = true;
		datasets[4].hidden = true;
		datasets[5].hidden = true;
		datasets[6].hidden = showBattery ? showDistinct : true; // to - from battery
		datasets[7].hidden = showBattery ? !showDistinct : true; // to battery
		datasets[8].hidden = showBattery ? !showDistinct : true; // from battery

		if (
			datasets[10].data.every((v) => v === null || v === undefined) &&
			datasets[11].data.every((v) => v === null || v === undefined)
		) {
			datasets[9].hidden = true; // to - from inverter
			datasets[10].hidden = true; // to inverter
			datasets[11].hidden = true; // from inverter
		} else {
			datasets[9].hidden = showDistinct;
			datasets[10].hidden = !showDistinct;
			datasets[11].hidden = !showDistinct;
		}

		datasets[12].hidden = showDistinct; // from - to grid
		datasets[13].hidden = !showDistinct; // to grid
		datasets[14].hidden = !showDistinct; // from grid
		datasets[15].hidden = !showHotWater; // to HW
		datasets[26].hidden = !showBattery ? true : false; // Soc

		datasets[16].hidden = true;
		datasets[17].hidden = true;
		datasets[18].hidden = true;
		datasets[19].hidden = true;
		datasets[20].hidden = true;
		datasets[21].hidden = true;
		datasets[22].hidden = true;
		datasets[23].hidden = true;
		datasets[24].hidden = true;
		datasets[25].hidden = true;

		if (isTypeDC) {
			const batteryLabels = [datasets[6].label, datasets[7].label, datasets[8].label];
			if (!batteryLabels.some((value) => value.includes('DC'))) {
				datasets[6].label = `DC ${datasets[6].label}`;
				datasets[7].label = `DC ${datasets[7].label}`;
				datasets[8].label = `DC ${datasets[8].label}`;
			}
		}

		const label = `Power (W)`;
		const annotation = CONTRACT_ANNOTATION(parseInt(end / 1000), timezone);
		const timeFormat = getTimeFormat(resolution);
		handleSetChart(labels, datasets, label, timeFormat, annotation, showBattery);
	};

	const getTimeFrame = () => {
		let timeFrame = data.timeFrame;
		if (timeFrame === TIME_FRAMES.select) {
			const { dailyReports, halfHourlyReports } = data;
			const { thirty_six_months, twelve_months, two_months, fifteen_days, one_week, twenty_four_hours } =
				TIME_FRAMES;

			switch (data.resolution) {
				case resolutions.day:
					{ let dailyReportLen = dailyReports.length;
					timeFrame =
						dailyReportLen <= 60 ? two_months : dailyReportLen <= 366 ? twelve_months : thirty_six_months;
					break; }
				case resolutions.half_hour:
					{ let halfHourlyReportLen = halfHourlyReports.length;
					timeFrame =
						halfHourlyReportLen <= 50
							? twenty_four_hours
							: halfHourlyReportLen <= 336
							? one_week
							: fifteen_days;
					break; }
				case resolutions.minute:
					timeFrame = twenty_four_hours;
					break;
				default:
					break;
			}
		}

		if (fmData.hasFmData) {
			return TIME_FRAMES.fifteen_minutes;
		}

		return timeFrame;
	};

	const getTooltipLabelAndValue = (tooltipItem) => {
		const { datasetIndex, dataIndex, chart} = tooltipItem;
		const datasets = chart.data.datasets;
		
		// for forecast datasets, return empty string if actual value exists else return label with value
		if (datasetIndex >= 16 && datasetIndex <= 25) {
			if (datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== null &&
				datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== undefined
			) {
				return ''
			}

			if (datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] === null ||
				datasets[forecastToActualDatasetIndexMap[datasetIndex]]?.data[dataIndex] === undefined
			) {
				return `${datasets[datasetIndex].label}: ${datasets[datasetIndex].data[dataIndex]}`
			}
		}

		// for actual data check if forecast should be displayed and forecast data exists and display it next to the actual data
		if (showForecast &&
			datasets[actualToForecastDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== null &&
			datasets[actualToForecastDatasetIndexMap[datasetIndex]]?.data[dataIndex] !== undefined &&
			!datasets[actualToForecastDatasetIndexMap[datasetIndex]]?.hidden) {
			return `${datasets[datasetIndex].label}: ${datasets[datasetIndex].data[dataIndex]} (Forecast: ${
				datasets[actualToForecastDatasetIndexMap[datasetIndex]].data[dataIndex]})`
		}

		return `${datasets[datasetIndex].label}: ${datasets[datasetIndex].data[dataIndex]}`
	}

	const handleSetChart = (labels, datasets, label, time, annotation, showSoc) => {
		const timeFrame = getTimeFrame();
		const update = {
			data: { labels: labels, datasets: datasets },
			options: {
				...chart.options,
				layout: { padding: { right: showSoc ? 0 : 12, top: -8 } },
				plugins: {
					...chart.options.plugins,
					annotation: { annotations: annotation },
					tooltip: { ...chart.options.plugins.tooltip, callbacks: {...tooltipTitleCallbacks(selectedTimezone), 
						label: getTooltipLabelAndValue
					} },
				},
				scales: {
					...chart.options.scales,
					y: {
						...chart.options.scales.y,
						title: { ...chart.options.scales.y.title, display: true, text: label },
					},
					y1: { ...chart.options.scales.y1, display: showSoc, min: 0, max: 100 },
					x: {
						...xAxis(timeFrame, time, selectedTimezone),
						grid: {
							display: true,
							drawOnChartArea: true,
							drawTicks: true,
							z: 1,
						},
					},
				},
				onClick: (event, items, chart) => {
					if (items?.length) {
						handleFmData(parseInt(chart.data.labels[items[0].index] / 1000));
					}
				},
			},
		};

		update.options.plugins.zoom.zoom.drag.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes;
		update.options.plugins.zoom.zoom.pinch.enabled = timeFrame !== TIME_FRAMES.fifteen_minutes;
		setChart(update);
	};

	useEffect(() => {
		if (fmData.hasFmData && !!fmData.bySecondReports.length) {
			populateChartBySecond(fmData.bySecondReports, resolutions.second);
		}
	}, [fmData.bySecondReports, selectedTimezone]);

	useEffect(() => {
		if (!fmData.hasFmData && data.updated) {
			handleDataOnChange(data);
		}
	}, [data.bySecondReports, data.forecasts, data.minutelyReports]);

	useEffect(() => {
		if (!!data.resolution && data.timeFrame === TIME_FRAMES.select) {
			handleDataOnChange(data);
		}
	}, [data.resolution, data.minutelyReports, data.halfHourlyReports, data.dailyReports]);

	useEffect(() => {
		if (!fmData.hasFmData && !data.updated) {
			handleDataOnChange(data);
		}
	}, [data.timeFrame, data.forecasts, showForecast, fmData.hasFmData, selectedTimezone]);

	return (
		<div className="power-chart" style={{ height: `100%` }}>
			<Line
				id={facilityChartTitles.energy[1]}
				ref={facilityEnergyRef}
				data={chart.data}
				options={chart.options}
			/>
		</div>
	);
};

export default EnergyChart;
